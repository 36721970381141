<template>
  <span class="pro-info">Diese Funktion ist Teil von Foxtag Pro
    <help-article article-id="pro_overview" :no-style="true"><i class="fas fa-question-circle" /></help-article>
  </span>
</template>

<script>
export default {
  name: 'ProFeatureHint',
};
</script>
<style>
span.pro-info {
  font-size: .825rem;
  color: #666;
  font-family: system-ui, sans-serif;
  font-weight: normal;
}

span.pro-info:before {
  content: 'PRO';
  font-style: normal !important;
  font-weight: 600 !important;
  font-variant: normal !important;
  text-transform: none !important;
  font-size: .8rem;
  padding: .2rem;
  margin-right: .5rem;
  color: white;
  background-color: #bbb;
  border-radius: 4px;
  font-family: system-ui, sans-serif !important
}
</style>
