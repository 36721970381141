<template>
  <div>
    <!-- <QuickViewModal modalName="job-quick-view-modal" /> -->
    <div class="fx-card" style="font-size: 0.8rem;">
      <div v-if="hasCoords" id="mapbox-installation-sidebar" style="width: 100%; min-height: 200px;" />

      <table class="fx-card" style="font-size: 0.8rem;">
        <colgroup>
          <col style="width: 100px;">
          <col>
        </colgroup>
        <tr>
          <th style="max-width: 25px;">
            Objekt
          </th>
          <td id="job-details-location">
            <Location :location="installation.location" :link="true" />
          </td>
        </tr>
        <tr>
          <th>Anlage</th>
          <td id="job-details-installation">
            <Labels :labels="installation.labels" />
            <Installation :installation="installation" :link="true" />
          </td>
        </tr>
        <tr>
          <th>Techniker</th>
          <td id="job-details-assigned_user">
            {{ installation.assigned_user_name }}
            <span v-if="!installation.assigned_user_name" class="secondary">Kein Techniker hinterlegt</span>
          </td>
        </tr>
        <tr>
          <th>&nbsp;</th>
          <td>
            <!-- Fälligkeiten<br> -->
            <NextCheckInfo
              v-if="installation.next_check_at"
              :next-date="installation.next_check_at"
              :next-status="installation.next_check_status"
            />
            <NextCheckInfo
              v-if="installation.next_replacement_at"
              :next-date="installation.next_replacement_at"
              :next-status="installation.next_replacement_status"
              :is-replacement="true"
            />
            <!-- Komponenten -->
            <div><i class="far fa-cube" /> {{ installation.things_count }} Komponenten</div>
            <!-- Störungen<br> -->
            <template v-if="featureIncidentsPro">
              <div>
                <template v-if="installation.open_incidents_count > 0">
                  <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_incidents_count }} offene
                  Störungen gesamt
                </template>
                <template v-else>
                  <span class="secondary" title=""><i class="fas fa-exclamation-triangle" /> 0 offene Störungen
                    gesamt</span>
                </template>
              </div>
              <div>
                <template v-if="installation.open_unassigned_incidents_count > 0">
                  <i class="fas fa-exclamation-triangle color-warn" /> {{ installation.open_unassigned_incidents_count
                  }}
                  offene Störungen ohne Zielauftrag <help-article article-id="incident_target_job" :no-style="true">
                    <i class="fas fa-question-circle" />
                  </help-article>
                </template>
                <template v-else>
                  <span class="secondary" title=""><i class="fas fa-exclamation-triangle" /> 0 offene Störungen ohne
                    Zielauftrag <help-article article-id="incident_target_job" :no-style="true"><i
                      class="fas fa-question-circle"
                    /></help-article>
                  </span>
                </template>
              </div>
            </template>
          </td>
        </tr>
      </table>
    </div>

    <div id="next-job-card" class="fx-card">
      <div class="fx-card-content" style="font-size: 0.8rem;">
        <h3 class="fx light">
          Nächster Auftrag
        </h3>
        <table style="margin-bottom: 0;">
          <tr v-if="!installation.next_job">
            <td><span class="secondary">Kein Auftrag</span></td>
          </tr>
          <tr v-if="installation.next_job">
            <td valign="top" style="padding: 0 .5rem 1rem 0;">
              <strong>{{ formattedPlannedAt(installation.next_job) }}</strong><br>
              <span :class="'fx-status ' + installation.next_job.status">{{ $t('job.status.' +
                installation.next_job.status)
              }}</span>
            </td>
            <td valign="top" style="padding: 0 .5rem 1rem 0; width: 100%;">
              <a id="next-job-link" :href="jobUrl(installation.next_job)">
                {{ installation.next_job.job_type_name }} {{ installation.next_job.number }}</a><br>

              <div v-if="installation.next_job.pooling" class="secondary">
                <i class="fas fa-users" /> Jeder
              </div>
              <div
                v-for="name in installation.next_job.assigned_user_names"
                v-else
                :key="name"
                class="secondary"
              >
                <i class="fas fa-user" /> {{ name }}
              </div>
            </td>
            <td valign="top" style="padding: 0;">
              <a id="next-job-quickview" class="fx-btn-skeleton" @click="openJobQuickView(installation.next_job.id)"><i
                class="fas fa-eye"
              /></a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div id="last-job-card" class="fx-card">
      <div class="fx-card-content" style="font-size: 0.8rem;">
        <h3 class="fx light">
          Letzte Aufträge
        </h3>
        <table style="margin-bottom: 0;">
          <tr v-if="!installation.recent_jobs.length">
            <td><span class="secondary">Keine erledigten Aufträge</span></td>
          </tr>
          <tr v-for="job in installation.recent_jobs" :key="job.id">
            <td valign="top" style="padding: 0 .5rem 1rem 0;">
              <strong>{{ formattedPlannedAt(job) }}</strong><br>
              <span :class="'fx-status ' + job.status">{{ $t('job.status.' + job.status) }}</span>
            </td>
            <td valign="top" style="padding: 0 .5rem 1rem 0; width: 100%;">
              <a id="last-job-link" :href="jobUrl(job)">
                {{ job.job_type_name }} {{ job.number }}</a><br>

              <div v-if="job.pooling" class="secondary">
                <i class="fas fa-users" /> Jeder
              </div>
              <div
                v-for="name in job.assigned_user_names"
                v-else
                :key="name"
                class="secondary"
              >
                <i class="fas fa-user" /> {{ name }}
              </div>
            </td>
            <td valign="top" style="padding: 0;">
              <a id="last-job-quickview" class="fx-btn-skeleton" @click="openJobQuickView(job.id)"><i class="fas fa-eye" /></a>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="fx-card">
      <div class="fx-card-content" style="font-size: 0.8rem;">
        <h3 class="fx light">
          Notizen
        </h3>
        <div style="overflow: auto; max-height: 500px;">
          <div
            v-if="installation.sticky_notes.length"
            style="padding-bottom: .8rem; margin-bottom: .8rem;border-bottom: 1px solid #ccc;"
          >
            <span style="font-size: .825rem; font-weight: bold; color: #666;"><i class="fas fa-thumbtack" />
              Angepinnt</span>
            <div v-for="note in installation.sticky_notes" :key="note.id" style="margin: .2rem 0;">
              <pre>{{ note.note }}</pre>
            </div>
          </div>

          <div v-for="note in installation.recent_notes" :key="note.id" style="margin-bottom: 1rem;">
            <div>
              <img
                :src="note.user_avatar_path"
                style="float: left;"
                width="30px"
                height="30px"
              >
              <div style="margin-left: 40px; vertical-align: middle;">
                <strong>{{ note.user_name }}</strong>
                <span
                  v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                  :title="formattedTimeWithMinute(note.note_taken_at)"
                  style="text-decoration-color: #bbb; text-decoration-line: underline;"
                >{{
                  friendlyTime(note.note_taken_at) }}</span>
                <div>
                  <pre>{{ note.note }}</pre>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!installation.recent_notes.length" class="secondary">
            Keine Notizen an dieser Anlage
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import NextCheckInfo from 'components/partials/next_check_info.vue';
import Labels from 'components/partials/labels.vue';

import { jobQuickView } from 'helpers/quickviews';

import MapboxLanguage from '@mapbox/mapbox-gl-language';
import mapboxgl from '!mapbox-gl/dist/mapbox-gl';

import 'mapbox-gl/dist/mapbox-gl.css';

export default {
  name: 'InstallationSidebar',
  components: {
    Location,
    Installation,
    NextCheckInfo,
    Labels,
  },
  props: {
    installation: {
      type: Object,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      required: false,
    },
  },
  setup() {
    const openJobQuickView = (jobId) => {
      jobQuickView(jobId);
    };

    return {
      openJobQuickView,
    };
  },
  computed: {
    hasCoords() {
      return (this.installation.location.active_address.lat != null
        && this.installation.location.active_address.lng != null);
    },
  },
  watch: {
    installation: {
      handler(newVal) {
        this.initMapBox();
      },
      deep: true,
    },
  },
  mounted() {
    if (this.hasCoords) {
      this.initMapBox();
    }
  },
  methods: {
    friendlyTime(time) {
      moment.locale('de');
      return moment(time).fromNow();
    },
    formattedTimeWithMinute(time) {
      return moment(time).format('DD.MM.YYYY HH:mm');
    },
    formattedPlannedAt(job) {
      return moment(job.planned_at).format('DD.MM.YYYY');
    },
    jobUrl(job) {
      return `/jobs/${job.id}`;
    },
    markerClassForStatus(checkStatus, replacementStatus) {
      if ((checkStatus === 'overdue') || (replacementStatus === 'overdue')) {
        return 'marker-dot-red-shadow';
      }
      if ((checkStatus === 'near') || (replacementStatus === 'near')) {
        return 'marker-dot-orange-shadow';
      }
      if ((checkStatus === 'far') || (replacementStatus === 'far')) {
        return 'marker-dot-green-shadow';
      }

      return 'marker-dot-darkblue-shadow';
    },
    initMapBox() {
      const coords = [this.installation.location.active_address.lng, this.installation.location.active_address.lat];

      mapboxgl.accessToken = 'pk.eyJ1IjoiZm94dGFnIiwiYSI6ImNrMXVneDl2aDB5MjkzY3Q2aDRtNWtwNXUifQ.0aazkwQuRWiwzN8P3zjx3A';
      const map = new mapboxgl.Map({
        container: 'mapbox-installation-sidebar',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coords,
        attributionControl: false,
        logo: false,
        zoom: 12,
      });
      const language = new MapboxLanguage();
      map.addControl(language);

      const el = document.createElement('div');
      el.className = this.markerClassForStatus(this.installation.next_check_status, this.installation.next_replacement_status);
      new mapboxgl.Marker({ element: el, anchor: 'bottom' }).setLngLat(coords).addTo(map);
    },
  },
};
</script>

<style>
h3.light {
  font-weight: 300;
  color: #2C556E;
  font-size: 1.2rem;
}
</style>
