<template>
  <div>
    <div class="fx-form-row">
      <div class="fx-form-label">
        <label :for="inputId" class="inline mandatory">Passwort</label>
      </div>
      <div class="fx-form-content">
        <input
          v-if="showPassword"
          :id="inputId"
          v-model="password"
          type="text"
          :name="inputName"
          style="display: inline-block; width: 90%;"
        >
        <input
          v-if="!showPassword"
          :id="inputId"
          v-model="password"
          type="password"
          :name="inputName"
          style="display: inline-block; width: 90%;"
        >
        <i
          v-if="!showPassword"
          class="far fa-eye"
          style="cursor: pointer; margin-left: 5px;"
          title="Passwort einblenden"
          @click="toggleShowPassword()"
        />
        <i
          v-if="showPassword"
          class="far fa-eye-slash"
          style="cursor: pointer; margin-left: 5px;"
          title="Passwort ausblenden"
          @click="toggleShowPassword()"
        />
        <div class="form-field-hint" :class="{ok: lengthOk}">
          mindestens {{ currentMinLength }} Zeichen
          <i v-if="lengthOk" class="far fa-check" />
        </div>
        <div v-if="complexityNeeded" class="form-field-hint" :class="{ok: upCaseDownCaseOk}">
          mindestens ein Großbuchstabe und ein Kleinbuchstabe
          <i v-if="upCaseDownCaseOk" class="far fa-check" />
        </div>
        <div v-if="complexityNeeded" class="form-field-hint" :class="{ok: numberOk}">
          mindestens eine Zahl oder ein Sonderzeichen
          <i v-if="numberOk || symbolOk" class="far fa-check" />
        </div>
      </div>
    </div>

    <input
      v-if="showPassword"
      id="account_password_confirmation"
      v-model="passwordConfirmation"
      type="hidden"
      name="account[password_confirmation]"
    >
    <div v-if="!showPassword" class="fx-form-row">
      <div class="fx-form-label">
        <label for="account_password_confirmation" class="inline mandatory">Passwort Wiederholung</label>
      </div>
      <div class="fx-form-content">
        <input
          id="account_password_confirmation"
          v-model="passwordConfirmation"
          type="password"
          name="account[password_confirmation]"
        >
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    inputName: {
      type: String,
      default: 'account[password]',
    },
    inputId: {
      type: String,
      default: 'account_password',
    },
    errorMessage: {
      type: String,
      default: null,
    },
    errorMessageConfirmation: {
      type: String,
      default: null,
    },
    minLength: {
      type: Number,
      default: 8,
    },
    noComplexityLength: {
      type: Number,
      default: 14,
    },
  },
  emits: ['changed'],
  data() {
    return {
      password: null,
      passwordConfirmation: null,
      showPassword: false,
    };
  },
  computed: {
    currentMinLength() {
      return this.complexityNeeded ? this.minLength : this.noComplexityLength;
    },
    complexityNeeded() {
      return (this.password == null || this.password.length < this.noComplexityLength);
    },
    lengthOk() {
      return (this.password != null && this.password.length >= this.minLength);
    },
    upCaseDownCaseOk() {
      return (this.password != null && this.password.match(/[a-z]/) && this.password.match(/[A-Z]/));
    },
    numberOk() {
      return (this.password != null && this.password.match(/[0-9]/));
    },
    symbolOk() {
      return (this.password != null && this.password.match(/[^a-zA-Z0-9]/));
    },
    valid() {
      return this.lengthOk && (!this.complexityNeeded || (this.upCaseDownCaseOk && (this.numberOk || this.symbolOk)));
    },
  },
  watch: {
    password() {
      // --- you do not need to input confirmation if password is visible
      if (this.showPassword) {
        this.passwordConfirmation = this.password;
      }

      this.$emit('changed', this.password, this.passwordConfirmation, this.valid);
    },
    passwordConfirmation() {
      this.$emit('changed', this.password, this.passwordConfirmation, this.valid);
    },
  },
  mounted() {
    const showPasswordFromStorage = localStorage.getItem('showPassword');
    if (showPasswordFromStorage) {
      this.showPassword = true;
    }
  },

  methods: {
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        localStorage.setItem('showPassword', '1');
      } else {
        localStorage.removeItem('showPassword');
      }
    },
  },
};
</script>
<style>
div.Password__strength-meter {
  margin-bottom: .2rem !important;
}

div.ok {
  color: green;
}
</style>
