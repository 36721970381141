<template>
  <div id="dashboard-card-upcoming" class="fx-card">
    <div class="float-right" style="margin: .8rem">
      <span v-if="!mapLoaded && mapEnabled"><i class="fas fa-circle-notch fa-spin" /> Kartendaten werden geladen</span>

      <span v-if="mapLoaded && geoData.features.length">
        <div class="fx-btn-group">
          <button class="fx-btn-secondary" @click="toggleLayer('overdue-layer')"><i
                                                                                   class="fas fa-circle color-overdue"
                                                                                 />
            {{ toggleButtonLabel }}</button>

          <button
            v-if="mapLoaded"
            v-tippy="{ placement: 'top', duration: 100, arrow: true }"
            class="fx-btn-secondary"
            title="Zoom auf Elemente anpassen"
            @click="mapFitBounds()"
          ><i class="fas fa-brackets" /></button>
        </div>
      </span>
    </div>
    <div class="fx-card-content">
      <h2 id="card-upcoming-header" class="fx fx-no-m-btm">
        Nächste {{ weeks }} Wochen
      </h2>
    </div>

    <div v-if="mapEnabled" id="mapbox" :size="mapSize" :style="mapStyle" />

    <button id="upcoming-tab-due" :class="$tabClasses('due_installations')" @click="$setActiveTab('due_installations', false)">
      <i class="fas fa-sitemap" /> Fällige Anlage <span v-if="dueInstallationsCount != null" class="fx-tab-count">{{
        dueInstallationsCount }}</span>
    </button>
    <button id="upcoming-tab-overdue" :class="$tabClasses('overdue_installations')" @click="$setActiveTab('overdue_installations', false)">
      <i class="fas fa-sitemap" /> Überfällige Anlagen <span
        v-if="overdueInstallationsCount != null"
        class="fx-tab-count"
      >{{ overdueInstallationsCount }}</span>
    </button>
    <button id="upcoming-tab-planned-jobs" :class="$tabClasses('planned_jobs')" @click="$setActiveTab('planned_jobs', false)">
      <i class="far fa-calendar-check" /> Geplante Aufträge <span
        v-if="plannedJobsCount != null"
        class="fx-tab-count"
      >{{ plannedJobsCount }}</span>
    </button>
    <hr style="margin:0;">

    <div v-if="$isTabInitialized('due_installations')" v-show="$isTabActive('due_installations')" class="scrollable-tab">
      <InstallationList
        query-path="/dashboard/upcoming_due_installations.json"
        :limit="20"
        :show-locate-me="mapEnabled"
        :has-incidents-pro="hasIncidentsPro"
        @locate-me="locateMe"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            Keine fälligen Anlagen
          </div>
        </template>
      </InstallationList>
    </div>

    <div
      v-if="$isTabInitialized('overdue_installations')"
      v-show="$isTabActive('overdue_installations')"
      class="scrollable-tab"
    >
      <InstallationList
        query-path="/dashboard/upcoming_overdue_installations.json"
        :limit="20"
        :show-locate-me="mapEnabled"
        :has-incidents-pro="hasIncidentsPro"
        @locate-me="locateMe"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            Keine überfälligen Anlagen
          </div>
        </template>
      </InstallationList>
    </div>

    <div v-if="$isTabInitialized('planned_jobs')" v-show="$isTabActive('planned_jobs')" class="scrollable-tab">
      <JobList
        :key="jobListKey"
        :query-path="`/dashboard/upcoming_jobs.json?weeks=${weeks}`"
        :limit="20"
        :allow-editing="allowEditing"
        :show-locate-me="mapEnabled"
        @locate-me="locateMe"
      >
        <template #empty-state>
          <div class="fx-list-empty-state">
            Keine geplanten Aufträge im Zeitraum
          </div>
        </template>
      </JobList>
    </div>
  </div>
</template>

<script>
import mapboxgl from '!mapbox-gl/dist/mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import 'mapbox-gl/dist/mapbox-gl.css';

import axios from 'axios';
import JobList from './job_list.vue';
import InstallationList from './installation_list.vue';

export default {
  name: 'UpcomingCard',
  components: {
    JobList,
    InstallationList,
  },
  props: {
    allowEditing: {
      type: Boolean,
      default: false,
    },
    weeks: {
      type: Number,
      default: 2,
    },
    mapSize: {
      type: String,
      default: 'small',
    },
    hasIncidentsPro: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      jobListKey: 0,
      activeTab: null,
      initializedTabs: new Set(),
      dueInstallationsCount: 0,
      overdueInstallationsCount: 0,
      plannedJobsCount: 0,
      geoData: { features: [] },
      geoDataBounds: null,
      map: null,
      mapLoaded: false,
      toggleButtonLabel: 'Überfällige zeigen',
    };
  },
  computed: {
    mapStyle() {
      if (this.mapSize === 'big') {
        return 'width: 100%; height: 650px; background-color: #ddd;';
      }

      return 'width: 100%; height: 350px; background-color: #ddd;';
    },
    mapEnabled() {
      return (this.mapSize !== 'hidden');
    },
  },
  watch: {
    activeTab: {
      handler(newValue) {
        if (newValue === 'overdue_installations') {
          this.map.setLayoutProperty('overdue-layer', 'visibility', 'visible');
          this.toggleButtonLabel = 'Überfällige ausblenden';
        }
      },
    },
  },
  mounted() {
    this.initMapBox();
    this.$setActiveTab('due_installations', false);
  },
  methods: {
    loadMapData() {
      const that = this;
      axios.get('/dashboard/geo.json', {
        params: {
        },
      }).then((response) => {
        if (response.data.features.length) {
          that.geoData = response.data;
          that.populateMap();
          that.mapFitBounds();
        }
        that.mapLoaded = true;
      }).catch((err) => {
        console.warn(err);
      });
    },
    populateMap() {
      if (!this.mapEnabled) { return; }

      const that = this;
      this.map.addSource('stuff', {
        type: 'geojson',
        data: this.geoData,
      });

      this.map.addLayer({
        id: 'symbols',
        type: 'symbol',
        source: 'stuff',
        layout: {
          'icon-image': 'marker-{icon}',
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom',
          visibility: 'visible',
        },
        filter: ['!=', 'icon', 'overdue'],
      });

      this.map.addLayer({
        visibility: 'visbible',
        id: 'overdue-layer',
        type: 'symbol',
        source: 'stuff',
        layout: {
          'icon-image': 'marker-{icon}',
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'icon-ignore-placement': true,
          'icon-anchor': 'bottom',
          visibility: 'none',
        },
        filter: ['==', 'icon', 'overdue'],
      });

      this.map.on('click', 'symbols', (e) => {
        that.map.flyTo({
          center: e.features[0].geometry.coordinates,
        });
        const popup = new mapboxgl.Popup({ anchor: 'left', offset: [10, -16], maxWidth: '300px' })
          .setText('loading ...').setLngLat(e.features[0].geometry.coordinates).addTo(that.map);
        axios.get(`/installations/${e.features[0].properties.installation_id}/map_popup_content.html`)
          .then((response) => {
            popup.setHTML(response.data);
          }).catch();
      });

      this.map.on('click', 'overdue-layer', (e) => {
        that.map.flyTo({
          center: e.features[0].geometry.coordinates,
        });
        const popup = new mapboxgl.Popup({ anchor: 'left', offset: [10, -16], maxWidth: '300px' })
          .setText('loading ...').setLngLat(e.features[0].geometry.coordinates).addTo(that.map);
        axios.get(`/installations/${e.features[0].properties.installation_id}/map_popup_content.html`)
          .then((response) => {
            popup.setHTML(response.data);
          }).catch();
      });

      // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
      this.map.on('mouseenter', 'symbols', () => {
        that.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'symbols', () => {
        that.map.getCanvas().style.cursor = '';
      });

      // Change the cursor to a pointer when the it enters a feature in the 'symbols' layer.
      this.map.on('mouseenter', 'overdue-layer', () => {
        that.map.getCanvas().style.cursor = 'pointer';
      });

      // Change it back to a pointer when it leaves.
      this.map.on('mouseleave', 'overdue-layer', () => {
        that.map.getCanvas().style.cursor = '';
      });
    },
    locateMe(data) {
      if (!this.mapEnabled) { return; }

      this.map.flyTo({
        center: data,
        zoom: 13,
      });
    },
    mapFitBounds() {
      if (!this.mapEnabled) { return; }
      if (!this.geoDataBounds) {
        this.geoDataBounds = this.getBoundingBox(this.geoData);
      }

      const boundingBox = this.geoDataBounds;
      this.map.fitBounds([[boundingBox.xMin, boundingBox.yMin], [boundingBox.xMax, boundingBox.yMax]], { padding: 100, maxZoom: 14 });
    },
    getBoundingBox(data) {
      const bounds = {};
      let coords;
      let latitude;
      let longitude;

      this.plannedJobsCount = 0;
      this.dueInstallationsCount = 0;
      this.overdueInstallationsCount = 0;

      for (let i = 0; i < data.features.length; i += 1) {
        if (data.features[i].properties.icon === 'job') {
          this.plannedJobsCount += 1;
        }
        if (data.features[i].properties.icon === 'far' || data.features[i].properties.icon === 'near') {
          this.dueInstallationsCount += 1;
        }
        if (data.features[i].properties.icon === 'overdue') {
          this.overdueInstallationsCount += 1;
        }

        coords = data.features[i].geometry.coordinates;

        for (let j = 0; j < coords.length; j += 1) {
          longitude = coords[0];
          latitude = coords[1];

          bounds.xMin = bounds.xMin < longitude ? bounds.xMin : longitude;
          bounds.xMax = bounds.xMax > longitude ? bounds.xMax : longitude;
          bounds.yMin = bounds.yMin < latitude ? bounds.yMin : latitude;
          bounds.yMax = bounds.yMax > latitude ? bounds.yMax : latitude;
        }
      }

      return bounds;
    },
    toggleLayer(layerId) {
      const visibility = this.map.getLayoutProperty(layerId, 'visibility');

      // toggle layer visibility by changing the layout object's visibility property
      if (visibility === 'visible') {
        this.map.setLayoutProperty(layerId, 'visibility', 'none');
        this.toggleButtonLabel = 'Überfällige zeigen';
      } else {
        this.map.setLayoutProperty(layerId, 'visibility', 'visible');
        this.toggleButtonLabel = 'Überfällige ausblenden';
      }
    },
    initMapBox() {
      if (!this.mapEnabled) { return; }

      const coords = [10.018042, 53.586379]; // Hamburg Coords

      mapboxgl.accessToken = 'pk.eyJ1IjoiZm94dGFnIiwiYSI6ImNrMXVneDl2aDB5MjkzY3Q2aDRtNWtwNXUifQ.0aazkwQuRWiwzN8P3zjx3A';
      this.map = new mapboxgl.Map({
        container: 'mapbox',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coords,
        attributionControl: false,
        logo: false,
        zoom: 12,
      });
      this.map.scrollZoom.disable();
      this.map.addControl(new mapboxgl.NavigationControl(), 'top-left');

      this.map.addControl(new MapboxLanguage());

      const that = this;
      this.map.on('load', () => {
        that.map.loadImage('/mapmarkers/marker-dot-green-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-far', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-orange-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-near', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-dot-red-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-overdue', image, { pixelRatio: 2 });
        });
        that.map.loadImage('/mapmarkers/marker-cal-blue-shadow.png', (error, image) => {
          if (error) throw error;
          that.map.addImage('marker-job', image, { pixelRatio: 2 });
        });

        /* What?! Wait till all images has been loaded ... */
        setTimeout(() => {
          that.loadMapData();
        }, 500);
      });
    },
  },
};
</script>

<style>
.pointer {
  cursor: pointer;
}

.scrollable-tab {
  max-height: 600px;
  overflow-y: scroll;
  padding-right: 10px;
  overflow-x: hidden;
}
</style>
