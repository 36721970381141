<template>
  <div>
    <h1 class="fx">
      {{ selectedImportType.label }} importieren
    </h1>
    <p>
      {{ selectedImportType.introduction }}
      <help-article v-if="selectedImportType.help" :article-id="selectedImportType.help">
        mehr erfahren
      </help-article>
    </p>
    <template v-if="showProWarning">
      <ProFeatureCallout>
        Dieser Import steht Ihnen zur Verfügung, wenn Sie auf <strong>Foxtag Pro</strong> wechseln.
      </ProFeatureCallout>
    </template>

    <template v-if="showProWarning && isSupport && !showUniqueNumbersWarning">
      <div class="callout callout--danger" style="margin-top: 1rem;">
        <strong style="color: red;">ACHTUNG, BITTE LESEN:</strong><br>
        <strong>Der Kunde kann diesen Import nicht verwenden</strong>, er sieht nur den PRO-Hinweis über dieser Meldung!
        <br><strong>Als Foxtag-Support-Nutzer</strong> kannst du diesen Import für Support-Tätigkeiten in Ausnahmefällen
        verwenden.
      </div>
    </template>

    <template v-if="showUniqueNumbersWarning">
      <div class="callout callout--warning">
        <strong>Dieser Import setzt eindeutige Objekt-/Anlagennummern
          voraus.</strong><br>
        Ihre Foxtag-Account erlaubt momentan das mehrfache Vergeben der gleichen Objekt-/Anlagennummer, dieser Import
        setzt jedoch
        eindeutige Nummern voraus, um die Datensätze zuverlässig zuzuordnen.<br>
        Bitte melden Sie sich beim Kundenservice, wir helfen Ihnen Ihren Account auf eindeutige Nummern umzustellen.
      </div>
    </template>
    <template v-if="allowImport">
      <div class="fx-grid" style="max-width: unset;">
        <div class="fx-col" style="padding: 0;">
          <div class="fx-card">
            <div class="fx-card-content">
              <ol class="fx-steps">
                <li v-if="needsInstallation" class="fx-steps">
                  <div class="select-label">
                    Anlage auswählen
                  </div>
                  <DBBasedSelect
                    v-model="installationId"
                    icon-id="installation"
                    :default-label="$t('comp.select.no-selection')"
                    i18n-base-key="installations"
                    load-options-url="/select/installations"
                    @changed="onInstallationSelected"
                  />

                  <span v-if="installationId" class="small" style="margin-left: .2rem;"><a
                    :href="installationPath(installationId)"
                  >Zur Anlage wechseln</a></span>
                  <span v-if="!installationId" class="secondary small">Tipp: Sie können den Import auch auf der Seite
                    der Anlage im Tab <i>Komponenten</i> starten!</span>
                </li>
                <li v-if="needsInstallationType" class="fx-steps">
                  <div class="select-label">
                    Wartungsanwendung auswählen
                  </div>
                  <DBBasedSelect
                    v-model="installationTypeId"
                    icon-id="installation_type"
                    :default-label="$t('comp.select.no-selection')"
                    i18n-base-key="installation_types"
                    load-options-url="/installation_types/for_select"
                    @changed="onInstallationTypeSelected"
                  />
                  <span v-if="installationTypeId" class="small" style="margin-left: .2rem;"><a :href="installationTypePath(installationTypeId)">
                    Zur Wartungsanwendung wechseln</a></span>
                  <span v-if="!installationTypeId" class="secondary small">Tipp: Sie können den Import auch auf der Seite der
                    Wartungsanwendung im Tab <i>Typen</i> starten!</span>
                </li>
                <li v-if="needsJob" class="fx-steps">
                  <div class="select-label">
                    Auftrag auswählen
                  </div>
                  <DBBasedSelect
                    v-model="jobId"
                    icon-id="job"
                    :default-label="$t('comp.select.no-selection')"
                    i18n-base-key="jobs"
                    load-options-url="/select/jobs"
                    @changed="onJobSelected"
                  />
                  <span v-if="jobId" class="small" style="margin-left: .2rem;"><a :href="jobPath(jobId)">Zum Auftrag
                    wechseln</a></span>
                  <span v-if="!jobId" class="secondary small">Tipp: Sie können den Import auch auf der Seite des
                    Auftrags im Tab <i>Artikelpositionen</i> starten!</span>
                </li>
                <template v-if="readyForUpload">
                  <li class="fx-steps">
                    <div class="highlight-box">
                      <template v-if="selectedImportType.type === 'things'">
                        <button :class="$tabClasses('mapping')" @click="$setActiveTab('mapping', false)">
                          Import mit Feldzuordnung
                        </button>
                        <button :class="$tabClasses('direct')" @click="$setActiveTab('direct', false)">
                          Direktimport
                        </button>
                        <hr style="margin: 0;">
                      </template>

                      <div
                        v-if="$isTabInitialized('mapping')"
                        v-show="$isTabActive('mapping')"
                        class="tab-panel"
                        style="margin: 0 1rem;"
                      >
                        <div class="small" style="padding: .8rem 0;">
                          Bitte laden Sie eine Excel-Datei mit der Endung
                          <b>.xlsx</b> hoch.
                        </div>
                        <form
                          id="form-import-mapping"
                          class="new_import"
                          enctype="multipart/form-data"
                          :action="`/imports/${selectedImportType.type}/create_with_mapping`"
                          accept-charset="UTF-8"
                          method="post"
                        >
                          <input name="utf8" type="hidden" value="✓">
                          <input type="hidden" name="authenticity_token" :value="csrfToken">
                          <input type="hidden" name="installation_type" :value="installationTypeId">
                          <input type="hidden" name="installation" :value="installationId">
                          <input type="hidden" name="job" :value="jobId">

                          <input
                            id="import-file-mapping"
                            class="fx-btn-file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            type="file"
                            name="import[file]"
                            @change="submit('form-import-mapping')"
                          ><label for="import-file-mapping">Datei wählen und hochladen</label>

                          <div v-if="uploading" style="margin: .5rem 0 0 .5rem;">
                            <i class="fx-spinner" />
                            <span style="display: inline-block; vertical-align: top; padding-top: 7px;">Datei-Upload
                              läuft</span>
                          </div>
                          <input
                            id="import_import_type"
                            type="hidden"
                            :value="selectedImportType.type"
                            name="import[import_type]"
                          >
                        </form>
                      </div>

                      <!-- direct uploads are only available for things import -->
                      <div
                        v-if="$isTabInitialized('direct')"
                        v-show="$isTabActive('direct')"
                        class="tab-panel"
                        style="margin: 0 1rem;"
                      >
                        <form
                          id="form-import-direct"
                          class="new_import"
                          enctype="multipart/form-data"
                          action="/imports/things/create_without_mapping"
                          method="post"
                        >
                          <input type="hidden" name="authenticity_token" :value="csrfToken">
                          <input type="hidden" name="installation" :value="installationId">
                          <p class="small">
                            Mit dem Direkt-Import können Sie von uns unterstützte Dateiformate in einem
                            Schritt importieren.
                          </p>
                          <select id="import_import_format" name="import[import_format]">
                            <option value="hertek_bma">
                              Hertek Brandmeldeanlage (.txt)
                            </option>
                          </select>
                          <input
                            id="import-file-direct"
                            class="fx-btn-file"
                            accept="text/plain"
                            type="file"
                            name="import[file]"
                            @change="submit('form-import-direct')"
                          >
                          <label for="import-file-direct">Datei wählen und hochladen</label>
                          <div v-if="uploading" style="margin: .5rem 0 0 .5rem;">
                            <i class="fx-spinner" />
                          </div>
                          <input
                            id="import_import_type"
                            type="hidden"
                            value="things"
                            name="import[import_type]"
                          >
                        </form>
                      </div>
                    </div>
                  </li>
                  <li class="fx-steps">
                    Wir zeigen den Inhalt der Datei an und Sie ordnen den Spalten
                    der Datei die Felder zu, in die importiert werden soll
                  </li>
                  <li class="fx-steps">
                    Nach dem Start des Imports erscheint der laufende Import in der Liste der letzten Importe
                  </li>
                </template>
              </ol>
            </div>
          </div>
        </div>
        <div class="fx-col" style="max-width: 350px;">
          <div style="background-color: rgb(244, 244, 244); padding: 0.8rem 0.8rem 2rem;">
            <h3 class="fx">
              Dateivorlagen
            </h3>
            <ul style="list-style: none;">
              <li v-for="(template, index) in selectedImportType.templates" :key="index">
                <a :href="template.url" style="font-size: .9rem;"><i class="fas fa-file-excel" />
                  {{ template.name }} (.xlsx)
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <ImportStatus v-if="selectedImportType.type" v-model="selectedImportType" />
    </template>
  </div>
</template>

<script>
import { findIndex } from 'lodash';
import FoxtagPaths from 'mixins/foxtag_paths';

import DBBasedSelect from 'components/select/db_based_select.vue';
import ProFeatureCallout from 'components/pro_feature_callout.vue';

import ImportStatus from './status.vue';

export default {
  name: 'ImportsIndex',
  components: {
    ImportStatus,
    DBBasedSelect,
    ProFeatureCallout,
  },
  mixins: [FoxtagPaths],
  props: {
    presetImportType: {
      type: String,
      default: 'articles',
    },
    presetJobId: {
      type: String,
      default: null,
    },
    presetInstallationId: {
      type: String,
      default: null,
    },
    presetInstallationTypeId: {
      type: String,
      default: null,
    },
    isSupport: {
      type: Boolean,
      default: false,
    },
    hasUniqueNumbers: {
      type: Boolean,
      default: false,
    },
    hasJobArticlesPro: {
      type: Boolean,
      default: false,
    },
    hasJobsImport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      installationId: null,
      installationTypeId: null,
      jobId: null,
      uploading: false,
      activeTab: null,
      initializedTabs: new Set(),
      importTypes: [
        {
          type: 'customers',
          label: 'Kunden',
          introduction: 'Zum Hinzufügen oder Aktualisieren von Kundendaten',
          showProWarning: false,
          help: 'import_customers',
          templates: [
            { name: 'Vorlage Kundenimport', url: '/import_templates/Foxtag_Vorlage_Import_Kunden.xlsx' },
          ],
        },
        {
          type: 'locations',
          label: 'Objekte',
          introduction: 'Zum Hinzufügen von Objekten zu bestehenden Kunden',
          showProWarning: false,
          help: 'import_locations',
          templates: [
            { name: 'Vorlage Objektimport', url: '/import_templates/Foxtag_Vorlage_Import_Objekte.xlsx' },
          ],
        },
        {
          type: 'articles',
          label: 'Artikel',
          introduction: 'Zum Hinzufügen und Aktualisieren von Artikeln',
          showProWarning: false,
          help: 'import_articles',
          templates: [
            { name: 'Vorlage Artikelimport', url: '/import_templates/Foxtag_Vorlage_Import_Artikel.xlsx' },
          ],
        },
        {
          type: 'contacts',
          label: 'Kontakte',
          introduction: 'Zum Hinzufügen und Aktualisieren von Kontakten',
          showProWarning: false,
          help: 'import_contacts',
          templates: [
            { name: 'Vorlage Kontakte-Import', url: '/import_templates/Foxtag_Vorlage_Import_Kontakte.xlsx' },
          ],
        },
        {
          type: 'installations',
          label: 'Anlagen',
          showProWarning: false,
          help: 'import_installations',
          introduction: 'Zum Hinzufügen von Anlagen zu bestehenden Objekten',
          templates: [
            { name: 'Vorlage Anlagenimport', url: '/import_templates/Foxtag_Vorlage_Import_Anlagen.xlsx' },
          ],
        },
        {
          type: 'inventory',
          label: 'Inventar',
          showProWarning: false,
          introduction: 'NUR KUNDENSERVICE: Zum Import der Komponenten von mehreren Anlagen in einer Datei',
          templates: [
            { name: 'Vorlage Inventarimport', url: '/import_templates/Foxtag_Vorlage_Import_Inventar.xlsx' },
          ],
        },
        {
          type: 'jobs',
          label: 'Aufträge',
          feature: 'true',
          showProWarning: !this.hasJobsImport,
          help: 'import_jobs',
          introduction: 'Zum Einplanen von Aufträgen für bestehende Anlagen',
          templates: [
            { name: 'Vorlage Auftragsimport', url: '/import_templates/Foxtag_Vorlage_Import_Auftraege.xlsx' },
          ],
        },
        {
          type: 'things',
          label: 'Komponenten',
          help: 'import_things',
          introduction: 'Zum Hinzufügen von Komponenten zu einer bestimmten Anlage',
          showProWarning: false,
          templates: [
            { name: 'Vorlage Brandmeldeanlage', url: '/import_templates/Foxtag_Vorlage_Import_Anlage_BMA.xlsx' },
            { name: 'Vorlage Feuerlöscher', url: '/import_templates/Foxtag_Vorlage_Import_Anlage_Feuerloescher.xlsx' },
            { name: 'Vorlage Rauchwarnmelder', url: '/import_templates/Foxtag_Vorlage_Import_Anlage_RWM.xlsx' },
            { name: 'Vorlage Türen mit FSA', url: '/import_templates/Foxtag_Vorlage_Import_Anlage_Tueren_mit_FSA.xlsx' },
          ],
        },
        {
          type: 'thing_types',
          label: 'Komponenten-Typen',
          help: 'import_thing_types',
          introduction: 'Zum Hinzufügen von Komponenten-Typen für eine bestehende Wartungsanwendung',
          showProWarning: false,
          templates: [
            { name: 'Vorlage Typen', url: '/import_templates/Foxtag_Vorlage_Import_Komponenten-Typen.xlsx' },
          ],
        },
        {
          type: 'job_articles',
          label: 'Artikelpositionen',
          introduction: 'Zum Hinzufügen von Artikelpositionen zu einem bestimmten Auftrag',
          help: 'import_job_articles',
          showProWarning: !this.hasJobArticlesPro,
          templates: [
            { name: 'Vorlage Artikelpositionen', url: '/import_templates/Foxtag_Vorlage_Import_Artikelpositionen_einfach.xlsx' },
            { name: 'Vorlage Artikelpositionen mit Feldern für neue Artikel', url: '/import_templates/Foxtag_Vorlage_Import_Artikelpositionen_neue_Artikel.xlsx' },
          ],
        },
        {
          type: 'total_jobs',
          label: 'TOTAL Aufträge',
          introduction: 'Zum Importieren von Aufträgen aus dem ERP-System von TOTAL mit automatischem Anlegen von fehlenden Kunden, Objekte, Anlagen',
          showProWarning: false,
          templates: [{ name: 'Vorlage Total Auftragsimport', url: '/import_templates/Foxtag_Vorlage_Total_Job_Import.xlsx' }],
        },
      ],
      selectedImportType: { type: null, label: '', templates: [] },
    };
  },
  computed: {
    csrfToken() {
      return document.getElementsByName('csrf-token')[0].getAttribute('content');
    },
    needsInstallationType() {
      return this.selectedImportType.type === 'thing_types';
    },
    needsInstallation() {
      return this.selectedImportType.type === 'things';
    },
    needsJob() {
      return this.selectedImportType.type === 'job_articles';
    },
    readyForUpload() {
      if (this.selectedImportType.type === 'thing_types' && !this.installationTypeId) {
        return false;
      }
      if (this.selectedImportType.type === 'things' && !this.installationId) {
        return false;
      }
      if (this.selectedImportType.type === 'job_articles' && !this.jobId) {
        return false;
      }
      return true;
    },
    showProWarning() {
      return this.selectedImportType.showProWarning;
    },
    showUniqueNumbersWarning() {
      return (!this.hasUniqueNumbers && (this.selectedImportType.type === 'installations' || this.selectedImportType.type === 'jobs'));
    },
    allowImport() {
      return (this.isSupport || !this.showProWarning) && !this.showUniqueNumbersWarning;
    },
  },
  mounted() {
    this.switchImportType(this.presetImportType);

    if (this.presetInstallationTypeId && this.presetInstallationTypeId.length > 0) {
      this.installationTypeId = this.presetInstallationTypeId;
    }
    if (this.presetInstallationId && this.presetInstallationId.length > 0) {
      this.installationId = this.presetInstallationId;
    }
    if (this.presetJobId && this.presetJobId.length > 0) {
      this.jobId = this.presetJobId;
    }
    this.$setActiveTab('mapping');
  },
  methods: {
    onInstallationTypeSelected(installationTypeId) {
      this.installationTypeId = installationTypeId;
    },
    onInstallationSelected(installationId) {
      this.installationId = installationId;
    },
    onJobSelected(jobId) {
      this.jobId = jobId;
    },
    selectedClass(importType) {
      if (this.selectedImportType && this.selectedImportType.type === importType) {
        return 'fx-sidebar--selected';
      }
      return null;
    },
    switchImportType(type) {
      const index = findIndex(this.importTypes, { type });
      this.selectedImportType = this.importTypes[index];
      this.$setActiveTab('mapping');
    },
    submit(formId) {
      console.log('submit formId:', formId);
      this.uploading = true;
      document.getElementById(formId).submit();
    },
  },
};
</script>
<style>
.highlight-box {
  border: 1px solid #c6c6c6;
  /* rgb(37, 111, 197);*/
  border-radius: 4px;
  padding: .2rem 0 1rem 0;
}

.select-label {
  font-weight: bold;
  margin-bottom: .5rem;
}
</style>
