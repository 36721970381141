<template>
  <section class="fx-page-content">
  <div v-if="initialDataLoaded">
    <section class="header">
      <div class="fx-grid">
        <div class="fx-col" style="padding-bottom:0;margin-bottom:0;">
          <div v-if="localInstallation" style="text-align:right; float:right;">
            <template v-if="jobIsUnplanned">
              <div class="fx-btn-group">
                <a id="btn-save-unplanned" :href="jobPath" class="fx-btn-secondary" type="submit">In Planung speichern</a>
                <button id="btn-save-planned" class="fx-btn-primary" type="submit" @click.prevent="setStatusPlannedAndExit()">
                  Planung beenden
                </button>
              </div>
            </template>

            <template v-else>
              <a id="submit-detailed-plan" :href="jobPath" class="fx-btn-primary">Detaillierte Planung beenden</a>
            </template>
          </div>

          <div class="header-title">
            <h1 class="fx">
              <span class="header-title_number">{{ localJob.number }}</span>
              <span class="header-title_name">{{ localJob.job_type_label }} {{ planned_at_date }}</span>
            </h1>
            <h3 class="fx">
              Detaillierte Planung des Auftragumfangs
            </h3>
          </div>

          <button id="btn-thing-tab" :class="$tabClasses('job_things')" @click="$setActiveTab('job_things')">
            <i class="far fa-cube" /> Eingeplante Prüfungen <span class="tab-count">{{ jobThingsCount }}</span>
          </button>
          <button v-if="featureIncidentsPro" id="btn-incident-tab" :class="$tabClasses('job_incidents')" @click="$setActiveTab('job_incidents')">
            <i class="fas fa-exclamation-triangle" /> Eingeplante Störungen
            <span class="tab-count">{{ localAssignedIncidentsCount }}</span>
          </button>
        </div>
      </div>
    </section>
    <section class="list-content">
      <div class="fx-grid">
        <div class="fx-col">
          <div v-if="$isTabInitialized('job_things')" v-show="$isTabActive('job_things')" class="tab-panel">
            <div style="display: flex; align-items: flex-end; margin-top: 1rem;">
              <div style="flex: 1;">
                <input
                  v-model="filter.query"
                  type="text"
                  style="width: 200px; display:inline-block; margin: 0; margin-right: .5rem;"
                  placeholder="Gruppe suchen"
                >
                <a id="reset-search" style="font-size: .8rem;" @click.prevent="resetSearch()">zurücksetzen</a>
              </div>
              <div style="flex: 1; text-align: right;">
                <div style="display: inline-block; text-align: left;">
                  <div class="fx-btn-group">
                    <label style="line-height: 2.6;">Planungs-Modus</label>
                    <input
                      v-if="!localInstallation || !jobTypeHasChecks"
                      type="text"
                      style="width: 370px; margin-bottom: 0; vertical-align: bottom; display: inline-block;"
                      disabled
                      value="Der Auftragstyp sieht keine Prüfungen vor"
                    >
                    <select
                      v-if="localInstallation && jobTypeHasChecks"
                      id="job-job-things-scope"
                      v-model="selectedJobThingsScope"
                      name="job[job_things_scope]"
                      class="fx-focus"
                      style="width: 370px; margin-bottom: 0; vertical-align: bottom; display: inline-block;"
                    >
                      <option value="all_things">
                        Ganze Anlage ({{ localInstallation.things_count }} Komponenten)
                      </option>
                      <option value="due_things">
                        Fällige Komponenten ({{ localInstallation.due_things_count }} Komponenten)
                      </option>
                      <option value="custom_things">
                        Eigene Auswahl
                      </option>
                    </select>
                    <a id="set-things-scope" class="fx-btn-secondary" @click="changeJobThingsScope">ändern</a>
                  </div>
                </div>
              </div>
            </div>
            <SingleGroupPlanning
              v-for="group in localGroups"
              :key="group.id + group.things_count"
              :job-id="jobId"
              :group="group"
              :installation-id="installationId"
              :installation-type-id="installationType.id"
              :group-label="installationType.groups_label"
              :thing-label="installationType.things_label"
              :has-central-unit="installationType.has_central_unit"
              :start-collapsed="false"
              :job-things-scope="localJobThingsScope"
              :show-due-dates="true"
              :group-separator="installationType.group_separator"
              :tag-label="installationType.tags_label"
              @changed-planned-count="onChangedCustomPlannedCount"
            />

            <empty-state v-if="!localGroups.length && filter.query != ''" hint="Keine Gruppen/Komponenten gefunden" />

            <Pagination v-model="pagination" i18n-key="activerecord.models.group" @navigate="loadPage" />
          </div>

          <div
            v-if="featureIncidentsPro && $isTabInitialized('job_incidents')"
            v-show="$isTabActive('job_incidents')"
            class="tab-panel"
            style="margin-top: 1rem;"
          >
            <IncidentsList
              :installation-id="installationId"
              :job-id="jobId"
              @changed="loadCounts()"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
  </section>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import Flash from 'flash/index';
import { ref, watch } from 'vue';
import Pagination from 'components/pagination.vue';
import EmptyState from 'components/empty_state.vue';
import SingleGroupPlanning from './single_group_planning.vue';
import IncidentsList from './incidents_list.vue';

export default {
  name: 'JobPlanning',
  components: {
    Pagination,
    IncidentsList,
    SingleGroupPlanning,
    EmptyState,
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    featureIncidentsPro: {
      type: Boolean,
      required: false,
    },
  },
  setup(props) {
    const localGroups = ref([]);
    const filter = ref({
      group: '',
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get(`/installations/${props.installationId}/groups`, {
        params: {
          query: filter.value.query,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localGroups.value = response.data.groups;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localGroups.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
      };
    };

    const debouncedSearch = _.debounce(() => {
      search();
    }, 250);

    watch(filter, () => {
      debouncedSearch();
    }, { deep: true });

    return {
      localGroups,
      // articleGroups,
      filter,
      pagination,
      load,
      loadPage,
      search,
      resetSearch,
      debouncedSearch,
    };
  },
  data() {
    return {
      selectedInstallationId: null,
      localJob: {},
      localInstallation: {},
      installationType: {},
      showEnddate: false,
      assignedTechnicians: [null],
      announcementEmail: null,
      serverErrors: [],
      activeTab: null,
      initializedTabs: new Set(),
      localAssignedIncidentsCount: 0,

      selectedJobThingsScope: 'no_things',
      localJobThingsScope: 'no_things',
      localCustomJobThingsCount: 1,

      loaded: false,
      initialDataLoaded: false,
    };
  },
  computed: {
    jobThingsCount() {
      if (this.localInstallation == null) {
        return 0;
      }

      if (this.localJobThingsScope === 'all_things') {
        return this.localInstallation.things_count;
      }
      if (this.localJobThingsScope === 'due_things') {
        return this.localInstallation.due_things_count;
      }
      if (this.localJobThingsScope === 'custom_things') {
        return this.localCustomJobThingsCount;
      }
      return 0;
    },
    jobIsUnplanned() {
      if (this.localJob == null) {
        return false;
      }
      return this.localJob.status === 'unplanned';
    },
    jobTypeHasChecks() {
      return this.hasCheckStep(this.localJob.job_type_id);
    },
    installationHasOpenIncidents() {
      return this.localInstallation.open_incidents_count > 0;
    },
    hasNextStep() {
      return (this.localJobThingsScope === 'custom_things' || this.localJobThingsScope === 'custom_incidents');
    },
    planned_at_date() {
      return moment(this.localJob.planned_at).format('DD.MM.YYYY');
    },
    jobPath() {
      return `/jobs/${this.jobId}`;
    },
  },
  mounted() {
    this.loadJob();
    this.loadInstallationType();
    this.loadInstallation();
    this.loadCounts();

    this.$setActiveTabFromURL('job_things');
  },
  methods: {
    changeJobThingsScope() {
      if (this.localJobThingsScope !== this.selectedJobThingsScope) {
        this.localGroups = [];
        this.updateJobThingsScope(this.selectedJobThingsScope);
      }
    },
    hasCheckStep(jobTypeId) {
      if (this.localInstallation && this.localInstallation.active_job_types) {
        const selectedType = this.localInstallation.active_job_types
          .find((element) => element.id === jobTypeId);

        return (selectedType && selectedType.has_checks_step);
      }
      return false;
    },
    loadJob() {
      const that = this;
      axios.get(`/jobs/${this.jobId}/edit`)
        .then((response) => {
          that.localJob = response.data.job;
          that.localJobThingsScope = response.data.job.job_things_scope;
          that.selectedJobThingsScope = that.localJobThingsScope;
          that.initialDataLoaded = true;
        }).catch(() => {
          Flash.error('Auftrag konnte nicht geladen werden');
        });
    },
    loadInstallation() {
      const that = this;
      axios.get(`/installations/${this.installationId}/for_job`)
        .then((response) => {
          that.localInstallation = response.data;
        }).catch(() => {
          Flash.error('Anlagedaten konnten nicht geladen werden');
        });
    },
    loadInstallationType() {
      const that = this;
      axios.get(`/installation_types/${this.installationTypeId}`)
        .then((response) => {
          that.installationType = response.data.installation_type;
        }).catch(() => {
          Flash.error('Wartungsanwendung konnte nicht geladen werden');
        });
    },
    loadCounts() {
      const that = this;
      axios.get(`/jobs/${this.jobId}/counts`)
        .then((response) => {
          that.localAssignedIncidentsCount = response.data.assigned_incidents_count;
          that.localCustomJobThingsCount = response.data.job_things_count;
        }).catch(() => {
          Flash.error('Anlagedaten konnten nicht geladen werden');
        });
    },
    setStatusPlannedAndExit() {
      const that = this;
      axios.post(`/jobs/${this.jobId}/statuses`, { status: 'planned' })
        .then(() => {
          window.location.href = that.jobPath;
        }).catch(() => {
          Flash.error('Auftragstatus konnte nicht aktualisiert werden');
        });
    },
    updateJobThingsScope(newScope) {
      const that = this;
      axios.put(`/jobs/${this.jobId}/update_job_things_scope`, {
        job_things_scope: newScope,
      }).then(() => {
        Flash.info('Planungsmodus aktualisiert');
        that.localJobThingsScope = newScope;
        that.resetSearch();
        if (newScope === 'custom_things') {
          that.loadCounts();
        }
      }).catch(() => {
        Flash.error('Fehler');
      });
    },
    onChangedCustomPlannedCount(count) {
      // group component may return a null to trigger reload of
      if (count == null) {
        this.loadCounts();
      } else {
        this.localCustomJobThingsCount = count;
      }
    },
  },
};
</script>

<style>
input[type=text],
textarea {
  box-shadow: none;
  -webkit-box-shadow: none;
}
</style>
