<template>
  <div id="help-article-modal" class="fx-modal-content-large">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <div v-if="showSearch" style="min-height: 500px;">
      <h1 class="fx" style="margin-bottom: .2rem;">
        Nutzerhilfe durchsuchen
      </h1>
      <div style="background: #eee; padding: 1rem; border-radius: 4px; margin-bottom: 1rem;">
        <div style="position: relative;">
          <input
            ref="searchInput"
            v-model="query"
            type="text"
            placeholder="Nutzerhilfe durchsuchen ..."
            style="display: inline-block; margin-bottom: .3rem; margin-right: 10px;"
            @keyup="debouncedSearch"
          >
          <span style="display: block;position: absolute; top:8px; right: 15px;"><i
            v-if="loading"
            class="fx-spinner"
            style="height: 15px; width: 15px;"
          /></span>
        </div>
        <div>
          <input
            id="search_category_all"
            v-model="searchCategory"
            type="radio"
            value="all"
          >
          <label for="search_category_all">Gesamtes Support-Forum</label>

          <input
            id="search_category_nutzerhilfe"
            v-model="searchCategory"
            type="radio"
            value="nutzerhilfe"
          >
          <label for="search_category_nutzerhilfe">nur Nutzerhilfe</label>
        </div>
      </div>

      <div v-if="loading" style="text-align: center;">
        <i class="fx-spinner" />
      </div>

      <div v-for="(result, index) in searchResults" :key="index">
        <a class="no-color" @click="loadTopic(result.topic_id)">
          <h2 class="fx-help-search">{{ result.title }}</h2>
          <div>
            <span
              v-for="(tag, index2) in result.tags"
              :key="index2"
              class="fx-label"
              style="margin-right: 5px; color: rgb(60, 60, 60); background-color: rgb(230, 230, 230);"
            >{{ tag }}</span>
          </div>
          <p class="fx-help-search">{{ result.blurb }}</p>
        </a>
      </div>
      <div v-if="!searchResults.length && !loading && !searchEmptyState">
        Keine Ergebnisse
      </div>
      <div v-if="searchHasMorePosts">
        <a class="fx-help-highlighted" :href="extendedSearchLink" target="help"><i class="fa fa-external-link" /> Mehr
          Ergebnisse im Support-Forum ansehen</a>
      </div>

      <div v-if="searchEmptyState">
        <div style="line-height: 2;" class="secondary">
          Beliebte Suchbegriffe:
          <span v-for="(suggestion, index) in searchSuggestions" :key="index" style="padding-right: 5px;">
            <a class="fx-help-highlighted" @click="searchFor(suggestion)"><i class="fas fa-search" /> {{ suggestion
            }}</a>
          </span>
        </div>

        <div
          class="fx-grid-group"
          style="flex-wrap: nowrap; flex-basis: 0; margin-top: 2rem; margin-left: -10px; margin-right: -10px;"
        >
          <div class="fx-help-info-box">
            <h3 class="fx">
              Nutzerhilfe und Support-Forum
            </h3>
            Sie können die Nutzerhilfe von Foxtag direkt an dieser
            Stelle durchsuchen ohne Foxtag zu verlassen.
            <p>
              Der Link <a class="fx-help-article">mehr erfahren</a> in der Anwendung macht
              auf verfügbare Hilfetexte aufmerksam.
            </p>

            <p>
              Besuchen Sie das <strong><a href="https://support.foxtagapp.de" target="help"><i
                class="fa fa-external-link"
              /> Support-Forum</a></strong>
              für weiterführende Informationen, oder für Ihre Anregung oder Frage an uns.
            </p>
          </div>

          <div class="fx-help-info-box">
            <h3 class="fx">
              Kundenservice
            </h3>
            Wir bitten Sie zunächst in der Nutzerhilfe nach einer Antwort auf
            Ihre Frage zu suchen.
            <p>
              Sie finden dort keine Antwort oder Sie haben ein Anliegen, dass Sie persönlich besprechen möchten?
              Kein Problem, unser Support-Team ist persönlich für Sie erreichbar:
            </p>
            <a @click.prevent="loadTopic(18)">Kontakt zum Kundenservice</a>
          </div>
        </div>
        <div
          class="fx-grid-group"
          style="flex-wrap: nowrap; flex-basis: 0; margin-top: 5px; margin-left: -10px; margin-right: -10px;"
        >
          <div class="fx-help-info-box">
            <h3 class="fx">
              Schulungsangebote
            </h3>
            <p>
              Sie wünschen sich eine individuelle
              Schulung für sich oder Ihr
              Team? Wir bieten auf Ihre Bedürfnisse angepasste Online-Schulungen an, damit Sie
              das volle Potential von Foxtag in Ihrem Unternehmen ausschöpfen können.
            </p>
            <p>
              Sprechen Sie unser Kundenservice-Team an, wir machen Ihnen ein individuelles Schulungs-Angebot!
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!showSearch" style="min-height: 500px;">
      <div v-if="loading" style="text-align: center;">
        <i class="fx-spinner" />
      </div>
      <div v-if="!loading && !error">
        <div class="fx-btn-group" style="margin-bottom: .6rem;">
          <a v-if="topicHistory.length > 1" class="fx-help-highlighted" @click="back()"><i class="fas fa-caret-left" />
            zurück</a>
          <a :href="topicLink" class="fx-help-highlighted" target="help"><i class="fas fa-external-link" /> Diesen
            Artikel in der Nutzerhilfe lesen</a>
          <a class="fx-help-highlighted" @click="switchToSearch"><i class="fas fa-search" /> Nutzerhilfe durchsuchen</a>
        </div>
        <h1 class="fx" style="margin-bottom: .2rem;">
          {{ title }}
        </h1>
        <hr style="margin-top: .2rem;">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div class="fx-help-body" @click="handleClicks" v-html="body" />
      </div>

      <div v-if="error">
        <h2 class="fx">
          Hilfetext nicht gefunden ...
        </h2>
        <div>
          Es gab ein Problem beim Laden dieses Hilfe-Artikels. Verwenden Sie bitte die Suche in der Nutzerhilfe:
          <br>
          <a href="https://support.foxtagapp.de/docs" target="help"><i class="fa fa-external-link" /> Zur
            Nutzerhilfe</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
  name: 'HelpModal',
  props: {
    initTopicId: {
      type: String,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      title: '',
      body: '',
      topicId: null,
      topicLink: null,
      loading: false,
      error: false,
      showSearch: true,
      query: null,
      searchResults: [],
      searchHasMorePosts: null,
      searchCategory: 'nutzerhilfe',
      topicHistory: [],
      searchEmptyState: true,
      searchSuggestions: [
        'Mitarbeiter einladen', 'Checklisten', 'Firmenlogo',
      ],
    };
  },
  computed: {
    extendedSearchLink() {
      return `https://support.foxtagapp.de/search?expanded=true&q=${this.query}`;
    },
  },
  watch: {
    searchCategory: {
      handler() {
        this.debouncedSearch();
      },
    },
  },
  mounted() {
    this.loadTopic(this.initTopicId);
  },
  methods: {
    switchToSearch() {
      this.showSearch = true;
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    loadTopic(topicId) {
      if (topicId == null) {
        return;
      }

      this.showSearch = false;
      // this.loading = true;
      const that = this;
      axios.get(`/help/${topicId}`).then((response) => {
        that.title = response.data.title;
        that.body = response.data.body;
        that.topicLink = response.data.link;
        that.loading = false;
        that.topicHistory.push(topicId);
      }).catch(() => {
        that.loading = false;
        that.error = true;
      });
    },
    // beware: do not use ES6 style "() => {}" here, it messes the this reference!
    debouncedSearch: _.debounce(function () {
      this.search();
    }, 300),
    search() {
      if (this.query == null || this.query.length < 3) {
        this.searchResults = [];
        this.searchEmptyState = true;
        return;
      }
      this.searchEmptyState = false;

      this.loading = true;
      const that = this;
      axios.get(`/help/search?q=${this.query}&c=${this.searchCategory}`).then((response) => {
        that.searchResults = response.data.results;
        that.searchHasMorePosts = response.data.more_posts;
        this.loading = false;
      }).catch(() => {
        that.error = true;
        this.loading = false;
      });
    },
    searchFor(query) {
      this.query = query;
      this.search();
    },
    handleClicks(event) {
      // ensure we use the link, in case the click has been received by a subelement
      let { target } = event;
      while (target && target.tagName !== 'A') target = target.parentNode;

      const query = target.href.split('?')[1];
      const urlParams = new URLSearchParams(query);

      if (urlParams.has('topic')) {
        event.preventDefault();
        this.loadTopic(urlParams.get('topic'));
      }
    },
    back() {
      this.topicHistory.pop();
      const topicId = this.topicHistory.pop();
      this.loadTopic(topicId);
    },
  },
};
</script>

<style>
.fx-help-body {
  font-size: .9rem;
  line-height: 1.6;
}

.fx-help-body ul {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.fx-help-body ol {
  margin-left: 1rem;
}

.fx-help-body h1 {
  font-size: 1.2rem;
  margin-top: .5rem;
  color: #2C556E;
  font-weight: 500;
}

.fx-help-body blockquote {
  background-color: #eee;
  margin: 0;
  padding: 1rem;
}

.fx-help-body blockquote p {
  margin: 0;
  padding: 0;
}

.fx-help-body p {
  margin: 0 0 1.5rem 0;
}

.fx-help-body h2 {
  font-size: 1rem;
  color: #2C556E;
  font-weight: 500;
}

.fx-help-body div.meta {
  display: none;
}

.fx-fade-in {
  transition: 3s all ease-in-out;
}

.fx-help-body table {
  border-collapse: collapse;
}

.fx-help-body tr {
  border-top: 1px solid #ccc;
}

.fx-help-body td {
  padding: 5px;
  padding-right: 20px;
}

.fx-help-body th {
  padding: 5px;
  padding-right: 20px;
}

h2.fx-help-search {
  font-size: 1rem;
  color: #2C556E;
  font-weight: 500;
  margin: 0;
}

p.fx-help-search {
  margin: 0 0 1rem 0;
  color: #666;
  font-size: .8rem;
}

p.fx-help-search:hover {
  color: #256FC5;
}

a.fx-help-highlighted {
  background-color: #eee;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: .9rem;
  word-break: keep-all;
  white-space: nowrap;
}

div.fx-help-info-box {
  width: 100%;
  font-size: .9rem;
  flex-grow: 1;
  margin: 10px;
  padding: 10px;
  border: 1px solid #aaa;
  border-radius: 3px;
}
</style>
