<template>
  <div class="empty-state-box">
    <img :src="icon" class="empty-state-icon" style="height: 90px; margin-bottom: 1rem;">
    <h3 v-if="title" class="fx empty-state-title">
      {{ title }}
    </h3>
    <p class="empty-state-hint">
      {{ hint }}
    </p>
    <slot />
  </div>
</template>

<script>
import iconSuccess from 'images/success_circle.svg';

export default {
  name: 'SuccessState',
  props: {
    title: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icon: iconSuccess,
    };
  },
};
</script>

<style scoped>
  div.empty-state-box {
    text-align: center;
    background-color: white; border: 1px solid #ddd; padding: 3rem;margin-top: 1rem;
  }
  .empty-state-title {
    margin:0;
  }
  p.empty-state-hint {
    color: #929292;
  }
  img.empty-state-icon {
    margin-bottom: 2rem;
    height: 40px;
  }
</style>
