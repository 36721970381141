<template>
  <div style="min-width: 600px; max-width: 1150px; width: 90vw;">
    <template v-if="!resultingJob">
      <div class="fx-grid">
        <div class="fx-col" style="margin-left: 1rem;">
          <h2 v-if=" selectedInstallationId" class="fx">
            Neuer Auftrag
          </h2>
          <div>
            <div class="mb-4">
              <h2 v-if="!selectedInstallationId" class="fx">
                Wählen Sie eine Anlage für den Auftrag
              </h2>

              <div v-if="installation && installation.has_job_number" class="fx-form-row">
                <div class="fx-form-label">
                  <label for="job_number">Auftragsnummer</label>
                </div>
                <div class="fx-form-content">
                  <input
                    id="job_custom_job_number_false"
                    v-model="job.custom_job_number"
                    type="radio"
                    :value="false"
                    name="job[custom_job_number]"
                  >
                  <label for="job_custom_job_number_false">automatisch ({{ installation.job_number_preview }})</label>
                  <input
                    id="job_custom_job_number_true"
                    v-model="job.custom_job_number"
                    :value="true"
                    type="radio"
                    name="job[custom_job_number]"
                  >
                  <label for="job_custom_job_number_true">eigene Auftragsnummer</label>
                  <input
                    v-if="job.custom_job_number"
                    id="custom_job_number"
                    v-model="job.number"
                    type="text"
                    placeholder="Auftragsnummer"
                    name="job[number]"
                    class="fx-focus"
                  >
                </div>
              </div>

              <div class="fx-form-row" style="margin-bottom: 0;">
                <div class="fx-form-label">
                  <label for="job_number" class="mandatory">Anlage</label>
                </div>
                <div class="fx-form-content">
                  <DBBasedSelect
                    id="select-installation"
                    v-model="selectedInstallationId"
                    :readonly="(installationId !== null || installation !== null)"
                    icon-id="installation"
                    :default-label="$t('comp.select.no-selection')"
                    i18n-base-key="installations"
                    load-options-url="/select/installations"
                    @changed="loadInstallation"
                  />
                </div>
              </div>
            </div>
          </div>

          <ServerErrors v-model="serverErrors" title="Auftrag konnte nicht gespeichert werden" margin-bottom="0" />
          <!-- MAIN FORM -->
          <!-- DATE / TYPE -->
          <div v-if="installation" style="margin-bottom: 32px;">
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label for="job_job_type">Auftragstyp</label>
              </div>
              <div class="fx-form-content">
                <select id="job-job-type-id" v-model="job.job_type_id">
                  <option
                    v-for="jobType in installation.active_job_types"
                    :key="jobType.id"
                    :value="jobType.id"
                  >
                    {{ jobType.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="fx-form-row" style="margin-bottom: 0;">
              <div class="fx-form-label">
                <label for="job_{:class=>&quot;inline mandatory&quot;}">Datum<span
                  style="color: red"
                > *</span> / Uhrzeit
                </label>
              </div>
              <div class="fx-form-content">
                <StartEnddateForm
                  :init-start-date="job.planned_at"
                  :init-end-date="job.enddate"
                  :init-all-day="job.all_day"
                  @changed="jobDurationChanged"
                />
              </div>
            </div>
          </div>

          <!-- TECHNICIANS -->
          <div v-if="installation" class="fx-form-section">
            <div class="ffx-card-content">
              <h3 class="fx" style="margin-bottom: 8px;">
                Zugeordnete Mitarbeiter
              </h3>
              <p style="margin-top:0; color: #666">
                Wenn Sie niemanden zuordnen, können alle Mitarbeiter den Auftrag
                ausführen.
              </p>

              <div id="job-assigned-users">
                <div class="fx-form-row">
                  <div class="fx-form-label">
                    <label>Mitarbeiter</label>
                  </div>
                  <div class="fx-form-content">
                    <div
                      v-for="(assignedTechnician, index) in assignedTechnicians"
                      :key="index"
                      style="margin-bottom: .5rem;"
                    >
                      <div style="display: inline-block; width: 100%; max-width: 340px; margin-right: .8rem;">
                        <select v-model="assignedTechnicians[index]" class="fx-focus">
                          <option value="" />
                          <option
                            v-for="technician in installation.assignable_technicians"
                            :key="technician.id"
                            :value="technician.id"
                          >
                            {{ technician.label }}
                          </option>
                        </select>
                      </div>
                      <span class="secondary">
                        <a
                          v-if="assignedTechnicians.length > 1"
                          class="no-color secondary"
                          @click.prevent="removeAssignedTechnicianRow(index)"
                        ><i class="fas fa-times-circle" /></a>
                      </span>
                    </div>

                    <div>
                      <a id="add-technician-slot" style="font-size: .875rem;" @click.prevent="addAssignedTechnicianRow()">
                        <i class="fas fa-plus" />
                        Mitarbeiter hinzufügen
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- INSTRUCTIONS -->
          <div v-if="installation" class="fx-form-section">
            <h3 class="fx">
              Auftrag und Hinweise
            </h3>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label for="job_{:class=>&quot;inline mandatory&quot;}">Prüfungen</label>
              </div>
              <div class="fx-form-content">
                <select
                  v-if="jobTypeHasChecks"
                  id="job-select-things"
                  v-model="job.job_things_scope"
                  name="job[job_things_scope]"
                  class="fx-focus"
                >
                  <option value="all_things">
                    Ganze Anlage ({{ installation.things_count }} Komponenten)
                  </option>
                  <option value="due_things">
                    Fällige Komponenten ({{ installation.due_things_count }} Komponenten)
                  </option>
                  <option value="custom_things">
                    Eigene Auswahl auf der nächsten Seite treffen
                  </option>
                </select>
                <input
                  v-if="!jobTypeHasChecks"
                  type="text"
                  disabled
                  value="Der gewählte Auftragstyp sieht keine Prüfungen vor"
                >
              </div>
            </div>
            <div v-if="features.incidentsPro" class="fx-form-row">
              <div class="fx-form-label">
                <label for="job_job_incidents_scope">Störungen</label>
              </div>
              <div id="jobs-select-incidents" class="fx-form-content">
                <select
                  v-if="installation.open_unassigned_incidents_count"
                  v-model="job.job_incidents_scope"
                  name="job[job_type_id]"
                  class="fx-focus"
                >
                  <option value="no_incidents">
                    Keine Störungen einplanen
                  </option>
                  <option value="open_unassigned_incidents">
                    Alle offenen Störungen ohne Zielauftrag ({{
                      installation.open_unassigned_incidents_count }} Störungen)
                  </option>
                  <option value="custom_incidents">
                    Eigene Auswahl auf der nächsten Seite treffen
                  </option>
                </select>
                <input
                  v-if="!installation.open_unassigned_incidents_count"
                  type="text"
                  disabled
                  value="Es liegen keine offenen Störungen vor"
                >
              </div>
            </div>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label for="job_instructions">Hinweise</label>
              </div>
              <div class="fx-form-content">
                <textarea
                  id="job_instructions"
                  v-model="job.instructions"
                  style="margin-bottom:0; min-height: 100px;"
                  rows="3"
                  placeholder="Hinweise für die Techniker vor Ort ..."
                  class="fx-focus"
                />
              </div>
            </div>

            <div class="fx-form-row">
              <div class="fx-form-label">
                <label for="job_instructions">Allgemeine Hinweise <help-article
                  article-id="job_instructions"
                  :no-style="true"
                ><i class="fas fa-question-circle" /></help-article></label>
              </div>
              <div class="fx-form-content" style="padding-top: 0.2rem;">
                {{ installation.note }}
                <span v-if="!installation.note" class="secondary">Keine Hinweise</span>
              </div>
            </div>
          </div>

          <!-- announcement -->
          <div v-if="installation && features.announcementEmail && organization.config.announcements_enabled" class="fx-form-section">
            <h3 class="fx">
              Ankündigung per E-Mail
            </h3>
            <div class="fx-form-row">
              <div class="fx-form-label">
                <label>Kontakt</label>
              </div>
              <div class="fx-form-content">
                <DBBasedSelect
                  :key="contactSelectKey"
                  v-model="contactId"
                  icon-id="contact"
                  i18n-base-key="contacts"
                  :default-label="$t('comp.select.no-selection')"
                  load-options-url="/contacts/for_select?with_email=true"
                  @changed="onContactSelected"
                />
                <div class="form-field-hint">
                  Wählen Sie aus Ihren Kontakten, oder fügen Sie einen  <a @click="openAddContactModal()">neuen Kontakt hinzu.</a>
                </div>
                <!-- <input
                  id="announcement_email"
                  v-model="announcementEmail"
                  name="announcement_email"
                  style="display: inline-block; width: 100%; max-width: 320px; margin-right: .8rem;"
                  class="fx-focus"
                  type="text"
                >
                <span class="secondary">
                  <a class="no-color" @click.prevent="announcementEmail = null"><i class="fas fa-times-circle" /></a>
                </span>-->
              </div>
            </div>
          </div>

          <div v-if="installation" class="fx-form-section" style="padding-top: 18px; text-align: right;">
            <span style="margin-right: 1rem;"><a @click="$emit('close')">abbrechen</a></span>
            <div class="fx-btn-group">
              <template v-if="!hasNextStep">
                <TransactionButton
                  id="btn-save-unplanned"
                  v-model="saving"
                  class="fx-btn-secondary"
                  title="In Planung speichern"
                  :no-spinner="true"
                  @click="submit(false)"
                />
                <TransactionButton
                  id="btn-save-planned"
                  v-model="saving"
                  class="fx-btn-primary"
                  title="Planung beenden"
                  :no-spinner="true"
                  @click="submit(true)"
                />
              </template>
              <template v-if="hasNextStep">
                <TransactionButton
                  id="btn-save-redirect-selection"
                  v-model="saving"
                  class="fx-btn-primary"
                  title="Speichern und Auftragsumfang planen"
                  :no-spinner="true"
                  @click="submit(false)"
                />
              </template>
            </div>
          </div>
        </div> <!-- END OF FORM -->

        <!-- CONTEXT SIDEBAR -->
        <div v-if="installation" class="fx-col" style="max-width: 400px; background-color: #f0f0f0; border-radius: 8px;">
          <InstallationSidebar :installation="installation" :feature-incidents-pro="features.incidentsPro" />
        </div>
      </div>
    </template>

    <div v-if="resultingJob" class="fx-grid">
      <div class="fx-col">
        <empty-state :success-icon="true" :title="resultingJobTitle()" :hint="jobCreatedHint">
          <div class="fx-btn-group">
            <a class="fx-btn-ico-secondary" @click="$emit('close')"><i class="fas fa-times" /> Fenster schließen</a>
            <a class="fx-btn-primary" :href="jobPath(resultingJob.id)">Auftrag ansehen</a>
          </div>
        </empty-state>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import Flash from 'flash/index';

import { ref } from 'vue';
import { useCurrentUserStore } from 'stores/current_user';
import { VueFinalModal, useModal, useModalSlot } from 'vue-final-modal';

import FeatureFlipper from 'mixins/feature_flipper';

import DBBasedSelect from 'components/select/db_based_select.vue';
import EmptyState from 'components/empty_state.vue';
import StartEnddateForm from 'components/start_enddate_form.vue';
import ServerErrors from 'components/partials/server_errors.vue';
import TransactionButton from 'components/transaction_button.vue';
import AddCreateContactModal from 'apps/contacts/add_create_modal.vue';

import InstallationSidebar from './installation_sidebar.vue';

export default {
  name: 'NewJob',
  components: {
    InstallationSidebar,
    DBBasedSelect,
    EmptyState,
    StartEnddateForm,
    ServerErrors,
    TransactionButton,
  },
  mixins: [FeatureFlipper],
  props: {
    installationId: {
      type: String,
      default: null,
    },
    syncWindowFutureDays: {
      type: Number,
      default: 90,
    },
  },
  emits: ['close', 'created'],
  setup() {
    const announcementContactId = ref(null);
    const contactSelectKey = ref(0);

    const openAddContactModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: AddCreateContactModal,
            attrs: {
              relationType: 'contact',
              onlyCreate: true,

              onClose() {
                modalInstance.close();
              },
              onAdded(newContact) {
                announcementContactId.value = newContact.id;
                contactSelectKey.value += 1; // this forces re-render of select with a the new contact selected
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      announcementContactId,
      openAddContactModal,
      contactSelectKey,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      selectedInstallationId: null,
      installation: null,
      organization: null,
      job: {},
      showEnddate: false,
      assignedTechnicians: [null],
      serverErrors: [],
      resultingJob: null,
      nextJobInstallationId: null,
      saving: false,
    };
  },
  computed: {
    jobTypeHasChecks() {
      return this.hasCheckStep(this.job.job_type_id);
    },
    hasNextStep() {
      return (this.job.job_things_scope === 'custom_things' || this.job.job_incidents_scope === 'custom_incidents');
    },
    jobCreatedHint() {
      if (this.resultingJob.status === 'planned') {
        return `Geplante Aufträge erscheinen ${this.syncWindowFutureDays} Tage vor ihrem Datum auf dem Smartphone/Tablet.`;
      }
      return 'Dieser Auftrag erscheint erst auf dem Smartphone/Tablet, wenn Sie die Planung beenden.';
    },
  },
  mounted() {
    if (this.installationId && this.installationId.length) {
      this.selectedInstallationId = this.installationId;
      this.loadInstallation(this.installationId);
    }
    if (this.features.announcementEmail) {
      this.loadOrganization();
    }
  },
  methods: {
    onContactSelected(selectedContactId) {
      this.announcementContactId = selectedContactId;
    },

    onSetNextJobInstallation(installationId) {
      this.nextJobInstallationId = installationId;
    },
    resultingJobTitle() {
      if (this.resultingJob.number != null) {
        return `Auftrag ${this.resultingJob.number} wurde gespeichert`;
      }
      return 'Auftrag wurde gespeichert';
    },
    back() {
      window.history.back();
    },
    addAssignedTechnicianRow() {
      this.assignedTechnicians.push('');
    },
    removeAssignedTechnicianRow(index) {
      this.assignedTechnicians.splice(index, 1);
    },
    hasCheckStep(jobTypeId) {
      const selectedType = this.installation.active_job_types
        .find((element) => element.id === jobTypeId);

      return (selectedType && selectedType.has_checks_step);
    },
    jobDurationChanged(duration) {
      this.job.planned_at = duration.startDate;
      this.job.enddate = duration.endDate;
      this.job.all_day = duration.allDay;
    },
    submit(addStatusPlanned) {
      this.saving = true;
      const statusPlannedParam = addStatusPlanned ? true : null;
      const that = this;
      that.serverErrors = [];
      axios.post('/jobs', {
        job: {
          installation_id: this.job.installation_id,
          all_day: this.job.all_day,
          planned_at: this.job.planned_at,
          enddate: this.job.enddate,
          job_type_id: this.job.job_type_id,
          custom_job_number: this.job.custom_job_number,
          number: this.job.number,
          instructions: this.job.instructions,
          job_things_scope: this.job.job_things_scope,
          assigned_user_ids: _.uniq(this.assignedTechnicians),
        },
        job_incidents_scope: this.job.job_incidents_scope,
        add_status_planned: statusPlannedParam,
        announcement_contact_id: this.announcementContactId,
      }).then((response) => {
        that.$emit('created');
        if (that.hasNextStep) {
          window.location.href = `/jobs/${response.data.job.id}/planning`;
        } else {
          that.resultingJob = response.data.job;
          that.nextJobInstallationId = that.resultingJob.installation_id;
          this.saving = false;
        }
      }).catch((error) => {
        this.saving = false;
        if (error.response.status === 400) {
          window.scrollTo(0, 0);
          that.serverErrors = error.response.data.jobs;
          Flash.info('Bitte überprüfen Sie Ihre Eingaben');
        } else {
          Flash.error('Auftrag konnte nicht gespeichert werden');
        }
      });
    },
    jobPath(jobId) {
      return `/jobs/${jobId}`;
    },
    buildNewJob(installation) {
      return {
        planned_at: moment().format('YYYY-MM-DD'),
        enddate: moment().add(1, 'days').format('YYYY-MM-DD'),
        all_day: true,
        custom_job_number: false,
        number: null,
        job_type_id: installation.default_job_type_id,
        job_things_scope: 'all_things',
        job_incidents_scope: 'open_unassigned_incidents',
        installation_id: installation.id,
      };
    },
    loadInstallation(installationId) {
      if (!installationId) {
        return;
      }

      this.selectedInstallationId = installationId;

      const that = this;
      axios.get(`/installations/${installationId}/for_job`)
        .then((response) => {
          that.installation = response.data;
          that.job = that.buildNewJob(that.installation);
          that.announcementEmail = that.installation.announcement_email_suggestion;
          that.assignedTechnicians = [that.installation.assigned_user_id];
        }).catch(() => {
          Flash.error('Anlage konnte nicht geladen werden');
        });
    },
    loadOrganization() {
      const that = this;
      axios.get(`/organizations/${this.currentUser.organization_id}`)
        .then((response) => {
          that.organization = response.data.organization;
        }).catch(() => {
          Flash.error('Organization konnte nicht geladen werden');
        });
    },
  },
};
</script>

<style>
input[type=text],
textarea {
  box-shadow: none;
  webkit-box-shadow: none;
}

.fx-form-section {
  margin-bottom: 32px;
  padding-top: 8px;
  border-top: 1px solid #ddd;
}
</style>
