<template>
  <div>
    <div class="repco_preview">
      <template v-if="showForm">
        <div style="background-color: #eee; min-height: 16rem; border-radius: 8px; padding: 1rem;">
          <h3 class="fx">
            {{ name }}
          </h3>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory" for="customer_name">Überschrift</label>
            </div>
            <div class="fx-form-content">
              <input v-model="localSection.title" maxlength="80" type="text">
            </div>
          </div>

          <div v-if="expReport" class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Fotos</label>
            </div>
            <div class="fx-form-content">
              <select v-model="localSection.photos_col_count">
                <option :value="0">
                  Keine Fotos abdrucken
                </option>
                <option :value="2">
                  2 Fotos pro Zeile, max. 6 Fotos
                </option>
                <option :value="3">
                  3 Fotos pro Zeile, max. 6 Fotos (Standard)
                </option>
              </select>
              <div class="form-field-hint">
                Es werden maximal die 6 aktuellsten Fotos pro Störung abgedruckt
              </div>
            </div>
          </div>

          <div v-if="expReport" class="fx-form-row">
            <div class="fx-form-content">
              <input
                id="shows_history"
                v-model="localSection.shows_history"
                type="checkbox"
                @change="updateHistoryTitleDisabled()"
              >
              <label class="inline" for="shows_history">Störungsverlauf abdrucken</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline" for="history-title">Überschrift Verlauf</label>
            </div>
            <div class="fx-form-content">
              <input
                id="history-title"
                v-model="localSection.history_title"
                :disabled="historyTitleDisabled"
                maxlength="80"
                type="text"
              >
            </div>
          </div>

          <button class="fx-btn-primary" @click="showForm = false">
            Zurück zur Vorschau
          </button>
        </div>
      </template>

      <!-- PREVIEW foxtag_report -->
      <template v-if="!showForm && !expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 36%" :style="thStyle">
                Störungstyp
              </th>
              <th style="width: 40%" :style="thStyle">
                Komponente
              </th>
              <th style="width: 12%" :style="thStyle">
                Status
              </th>
            </tr>

            <tr>
              <td rowspan="2" :style="tdStyle">
                #10000
              </td>
              <td :style="tdStyle">
                <strong>Flansch abgerissen</strong>
              </td>
              <td :style="tdStyle">
                1/12<br>Typ-Name Hersteller Modell<br>Gebäude B, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td :style="tdStyle">
                <strong>geschlossen</strong><br>behoben
              </td>
            </tr>

            <tr>
              <td colspan="3" :style="tdStyle">
                <div>
                  Störungsbeschreibung. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur natus
                  laboriosam animi? Voluptatibus eos blanditiis quaerat expedita. Est saepe itaque velit magnam quos
                  expedita dolore, sunt, nemo perferendis voluptates dicta.
                </div>

                <table v-if="!expReport || localSection.shows_history" class="no-lines" style="margin-top: 1rem;">
                  <tr>
                    <td colspan="4" class="repco_cl_title">
                      {{ localSection.history_title }}
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;">
                      01.03.2020<br>12:00 Uhr
                    </td>
                    <td style=" width: 65%;">
                      <strong>Heinrich Hertz</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      geschlossen
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;">
                      01.02.2020<br>11:00 Uhr
                    </td>
                    <td style="width: 65%;">
                      <strong>Anne Mautanz</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      offen
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;">
                      01.01.2020<br>10:00 Uhr
                    </td>
                    <td style="width: 65%;">
                      <strong>Karl Unterberger</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      gemeldet
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </template>

      <!-- PREVIEW exp_report -->
      <template v-if="!showForm && expReport">
        <div :class="previewEngineClass">
          <div class="repco-h2" :style="headlineStyle">
            {{ localSection.title }}
          </div>
          <table class="preview">
            <tr>
              <th style="width: 12%" :style="thStyle">
                Nr.
              </th>
              <th style="width: 36%" :style="thStyle">
                Störungstyp
              </th>
              <th style="width: 40%" :style="thStyle">
                Komponente
              </th>
              <th style="width: 12%" :style="thStyle">
                Status
              </th>
            </tr>

            <tr>
              <td rowspan="3" :style="tdStyle">
                #10000
              </td>
              <td :style="tdStyle">
                <strong>Flansch abgerissen</strong>
              </td>
              <td :style="tdStyle">
                1/12<br>Typ-Name Hersteller Modell<br>Gebäude B, Raum 3<br>
                Baujahr: 2012<br>
                <span v-if="showsTags">Foxtag-ID: b4c14d<br></span>
                <span class="secondary italic mt-4 text-sm">weitere Stammdaten ...</span>
              </td>
              <td :style="tdStyle">
                <strong>geschlossen</strong><br>behoben
              </td>
            </tr>
            <tr>
              <template v-if="localSection.photos_col_count > 0">
                <td colspan="3" :style="tdStyle">
                  <table class="photos">
                    <tr>
                      <td>
                        <img src="/demo_photo.jpg" width="100%">
                        <strong>03.01.2023</strong> Bildunterschrift
                      </td>
                      <td>
                        <img src="/demo_photo.jpg" width="100%">
                        <strong>02.01.2023</strong> Bildunterschrift
                      </td>
                      <td v-if="localSection.photos_col_count > 2">
                        <img src="/demo_photo.jpg" width="100%">
                        <strong>01.01.2023</strong> Bildunterschrift
                      </td>
                    </tr>
                  </table>
                </td>
              </template>
            </tr>
            <tr>
              <td colspan="3" :style="tdStyle">
                <div>
                  Störungsbeschreibung. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Tenetur natus
                  laboriosam animi? Voluptatibus eos blanditiis quaerat expedita. Est saepe itaque velit magnam quos
                  expedita dolore, sunt, nemo perferendis voluptates dicta.
                </div>

                <div class="nested-headline mt-4" :style="nestedHeadlineStyle">
                  {{ localSection.history_title }}
                </div>
                <table v-if="localSection.shows_history" class="no-lines" style="margin-top: 1rem;">
                  <!--<tr>
                    <td colspan="4" class="repco_cl_title">{{ localSection.history_title }}</td>
                  </tr>-->
                  <tr>
                    <td style="width: 20%;">
                      01.03.2020<br>12:00 Uhr
                    </td>
                    <td style=" width: 65%;">
                      <strong>Heinrich Hertz</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      geschlossen
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;">
                      01.02.2020<br>11:00 Uhr
                    </td>
                    <td style="width: 65%;">
                      <strong>Anne Mautanz</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      offen
                    </td>
                  </tr>
                  <tr>
                    <td style="width: 20%;">
                      01.01.2020<br>10:00 Uhr
                    </td>
                    <td style="width: 65%;">
                      <strong>Karl Unterberger</strong>
                      <div>Optionaler Hinweis am Status</div>
                    </td>
                    <td style="width: 12%;">
                      gemeldet
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </template>
    </div>

    <Controls
      :index="index"
      :list-length="listLength"
      :name="name"
      :readonly="readonly"
      @settings="showForm = !showForm"
      @add="$emit('add')"
      @delete="$emit('delete')"
      @move-up="$emit('moveUp')"
      @move-down="$emit('moveDown')"
    />
  </div>
</template>

<script>
import FeatureFlipper from 'mixins/feature_flipper';
import Controls from './section_controls.vue';

export default {
  name: 'SectionIncidents',
  components: {
    Controls,
  },
  mixins: [FeatureFlipper],
  props: {
    section: {
      type: Object,
      required: true,
    },
    colors: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      default: 'Störungen',
    },
    index: {
      type: Number,
      required: true,
    },
    listLength: {
      type: Number,
      required: true,
    },
    showsTags: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    layoutEngine: {
      type: String,
      default: 'foxtag_report',
    },
  },
  data() {
    return {
      showForm: false,
      localSection: {},
      historyTitleDisabled: false,
    };
  },
  computed: {
    previewEngineClass() {
      return `preview-${this.layoutEngine}`;
    },
    expReport() {
      return (this.layoutEngine === 'exp_report');
    },
    thStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `background-color: transparent; border-color: #${this.colors.color_lines}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
      }

      return `color: #${this.colors.color_col_headline_text}; background-color: #${this.colors.color_lines}; border: 2px solid #${this.colors.color_lines}`;
    },
    tdStyle() {
      if (this.layoutEngine === 'exp_report') {
        return `color: #${this.colors.color_text}; border: 0;`;
      }

      return `color: #${this.colors.color_text}; border: 2px solid #${this.colors.color_lines}`;
    },
    headlineStyle() {
      return `color: #${this.colors.color_headlines};`;
    },
    groupStyle() {
      return `color: #${this.colors.color_text}; background-color: #${this.colors.color_bg_groups}; border-color: #${this.colors.color_lines}`;
    },
    nestedHeadlineStyle() {
      return `color: #${this.colors.color_text}; border: 0; border-bottom: 2px solid #${this.colors.color_lines}`;
    },
  },
  mounted() {
    console.log('start mounting');
    this.localSection = this.section;
    if (this.localSection.photos_col_count == undefined) {
      this.localSection.photos_col_count = 0;
    }

    if (this.localSection.shows_history == undefined) {
      this.localSection.shows_history = true;
    }
    this.updateHistoryTitleDisabled();
    console.log('end mounting');
  },
  methods: {
    updateHistoryTitleDisabled() {
      this.historyTitleDisabled = !this.localSection.shows_history;
    },
  },
};
</script>

<style>
.divider {
  display: flex;
  align-items: center;
  text-align: center;
}

/* To show the lines on right
  and left sides of the text */
.divider::after,
.divider::before {
  content: "";
  border-top: 1px dashed #888;
  flex: 1;
}

/* Space on left and right sides of text */
.divider:not(:empty)::before {
  margin-right: 0.6rem;
}

.divider:not(:empty)::after {
  margin-left: 0.6rem;
}
</style>
