<template>
  <div>
    <div class="fx-card">
      <div class="fx-card-content">
        <div class="float-right">
          <YesNoSwitch v-model="localAutoPublishJobs" :disabled="!canEdit" @click="toggleautomaticallyPublishJobs()" />
        </div>
        Berichte werden automatisch <span v-if="featurePortal">im Portal veröffentlicht und</span> per E-Mail verschickt
        <div class="clearfix" />
      </div>
    </div>
    <div v-if="featurePortal" class="fx-card">
      <div class="fx-card-content">
        <h2 class="fx">
          Auftraggeberportal
        </h2>
        <p>
          Portalnutzer erhalten Berichte per E-Mail und haben Zugriff auf alle Berichte,
          Anlagendaten und die Möglichkeit Störungen zu melden.
        </p>
      </div>
      <table class="material">
        <colgroup>
          <col style="width: 50px;">
          <col>
          <col style="width: 200px;">
          <col style="width: 200px;">
        </colgroup>

        <tr v-if="localSharedInstallations.length">
          <th />
          <th>Nutzer</th>
          <th colspan="2">
            Berechtigungen
          </th>
        </tr>

        <tr
          v-for="sharedInstallation in localSharedInstallations"
          :id="'row-' + sharedInstallation.id"
          :key="sharedInstallation.id"
        >
          <td style="padding-right: 0; vertical-align: top;">
            <img :src="sharedInstallation.user.avatar_path">
          </td>
          <td>
            <div>{{ sharedInstallation.user.organization_name }}</div>
            <div>{{ sharedInstallation.user.full_name }}</div>
            <div class="secondary">
              {{ sharedInstallation.user.email }}
            </div>
            <div v-if="sharedInstallation.user.needs_confirmation" style="color: red;">
              Einladung noch nicht angenommen
            </div>
          </td>
          <td style="font-size: 1rem;">
            <template v-if="hasRole(sharedInstallation, 'jobs')">
              <i
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                :title="$t('activerecord.attributes.shared_installation.roles_values.jobs')"
                class="role_icon far fa-calendar-check"
              />
            </template>
            <template v-if="hasRole(sharedInstallation, 'inventory')">
              <i
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                :title="$t('activerecord.attributes.shared_installation.roles_values.inventory')"
                class="role_icon far fa-cube"
              />
            </template>
            <template v-if="hasRole(sharedInstallation, 'due_dates')">
              <i
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                :title="$t('activerecord.attributes.shared_installation.roles_values.due_dates')"
                class="role_icon fas fa-circle"
              />
            </template>
            <template v-if="hasRole(sharedInstallation, 'incidents')">
              <i
                v-tippy="{ placement: 'top', duration: 100, arrow: true }"
                :title="$t('activerecord.attributes.shared_installation.roles_values.incidents')"
                class="role_icon fas fa-exclamation-triangle"
              />
            </template>
          </td>
          <td style="text-align: right;">
            <div class="fx-btn-group">
              <button
                :disabled="!canEdit"
                class="fx-btn-skeleton"
                @click.prevent="openEditSharingModal(sharedInstallation)"
              >
                bearbeiten
              </button>
              <drop-down :disabled="!canEdit">
                <drop-down-item>
                  <a
                    :id="'del-si-' + sharedInstallation.id"
                    @click.prevent.stop="confirmDeleteSharedInstallationModal(sharedInstallation)"
                  >
                    Freigabe löschen</a>
                </drop-down-item>
                <drop-down-item>
                  <a @click.prevent.stop="confirmDeletePortalUserModal(sharedInstallation)">
                    Portal-Nutzer löschen</a>
                </drop-down-item>
              </drop-down>
            </div>
          </td>
        </tr>
        <tr v-if="canEdit">
          <td colspan="3" style="font-weight: bold;">
            <a
              id="add-shared-installation"
              @click.prevent="openCreateSharingModal"
            ><i class="fas fa-plus" /> Freigabe hinzufügen</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import axios from 'axios';
import Flash from 'flash/index';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';
import confirmViaModal from 'helpers/confirm_modal';
import { ref } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';
import EntityList from 'helpers/entity_list';

import YesNoSwitch from 'components/partials/yes_no_switch.vue';
import EditSharingModal from './modals/edit_sharing_modal.vue';
import CreateSharingModal from './modals/create_sharing_modal.vue';

export default {
  name: 'SharingList',
  components: {
    DropDown,
    DropDownItem,
    YesNoSwitch,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    customerName: {
      type: String,
      required: true,
    },
    autoPublishJobs: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    featurePortal: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const localSharedInstallations = new EntityList();

    const loadSharedInstallations = () => {
      axios.get('/shared_installations/', {
        params: {
          installation_id: props.installationId,
        },
      }).then((response) => {
        localSharedInstallations.value = response.data.shared_installations;
      });
    };

    const deleteSharedInstallationViaAPI = (sharedInstallation) => {
      axios.delete(`/shared_installations/${sharedInstallation.id}`).then(() => {
        Flash.info('Freigabe gelöscht');
        localSharedInstallations.remove(sharedInstallation);
      }).catch(() => {
        Flash.error('Freigabe konnte nicht gelöscht werden');
      });
    };

    const deletePortalUserViaAPI = (sharedInstallation) => {
      axios.delete(`/shared_installations/${sharedInstallation.id}/destroy_user`).then(() => {
        Flash.info('Nutzer gelöscht');
        localSharedInstallations.remove(sharedInstallation);
      }).catch(() => {
        Flash.error('Nutzer konnte nicht gelöscht werden');
      });
    };

    const openCreateSharingModal = () => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: CreateSharingModal,
            attrs: {
              installationId: props.installationId,
              customerName: props.customerName,
              onClose() {
                modalInstance.close();
              },
              onCreated(createdSharedInstallation) {
                modalInstance.close();
                localSharedInstallations.append(createdSharedInstallation);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const openEditSharingModal = (sharedInstallation) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: EditSharingModal,
            attrs: {
              installationId: props.installationId,
              sharedInstallation,
              onClose() {
                modalInstance.close();
              },
              onUpdated(updatedSharedInstallation) {
                modalInstance.close();
                localSharedInstallations.update(updatedSharedInstallation);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    const confirmDeleteSharedInstallationModal = (sharedInstallation) => {
      confirmViaModal({
        title: 'Freigabe löschen?',
        message: 'Der Portal-Nutzer bleibt bestehen, verliert aber den Zugriff auf diese Anlage',
        action: 'Freigabe löschen',
      }).then(() => {
        deleteSharedInstallationViaAPI(sharedInstallation);
      });
    };

    const confirmDeletePortalUserModal = (sharedInstallation) => {
      confirmViaModal({
        title: 'Portal-Nutzer löschen?',
        message: 'Der Portal-Nutzer wird gelöscht und verliert den Zugriff auf alle freigebenen Anlagen',
        action: 'Nutzer löschen',
        requireInputConfirmation: true,
      }).then(() => {
        deletePortalUserViaAPI(sharedInstallation);
      });
    };

    return {
      localSharedInstallations: localSharedInstallations.reactiveList,
      openCreateSharingModal,
      openEditSharingModal,
      confirmDeleteSharedInstallationModal,
      confirmDeletePortalUserModal,
      loadSharedInstallations,
    };
  },
  data() {
    return {
      name: '',
      localAutoPublishJobs: false,
    };
  },
  mounted() {
    this.loadSharedInstallations();
    this.localAutoPublishJobs = this.autoPublishJobs;
  },
  methods: {
    hasRole(sharedInstallation, role) {
      return sharedInstallation.roles.includes(role);
    },
    toggleautomaticallyPublishJobs() {
      this.localAutoPublishJobs = !this.localAutoPublishJobs;
      axios.put(
        `/installations/${this.installationId}/update_autopublish_jobs`,
        {
          autopublish_jobs: this.localAutoPublishJobs,
        },
      ).then(() => {
        Flash.info('Einstellung gespeichert');
      }).catch(() => {
        Flash.error('Fehler beim Speichern der Einstellung');
      });
    },
  },
};
</script>

<style scoped>
i.role_icon {
  color: #888;
  padding-right: .2rem;
}
</style>
