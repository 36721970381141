<template>
  <div :class="blockClass">
    <div v-if="address.line1" :class="lineClass">
      {{ address.line1 }}
    </div>
    <div v-if="address.line2" :class="lineClass">
      {{ address.line2 }}
    </div>
    <div v-if="address.line3" :class="lineClass">
      {{ address.line3 }}
    </div>
    <div v-if="zipAndCity" :class="lineClass">
      {{ zipAndCity }}
    </div>
    <div v-if="showCoords" :class="lineClass">
      {{ degMinSec(address.lat) }} {{ latDir(address.lat) }}<br>
      {{ degMinSec(address.lng) }} {{ lngDir(address.lng) }}
    </div>
    <div v-if="empty" class="secondary" :class="lineClass">
      Keine Angabe
    </div>
    <div v-if="locateMeAvailable">
      <a @click="$emit('locate-me',[address.lng, address.lat])"><i class="far fa-crosshairs" />  auf Karte zeigen</a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true,
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    showLocateMe: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    locateMeAvailable() {
      return (this.hasCoords && this.showLocateMe);
    },
    blockClass() {
      return (this.withIcon) ? 'v-address-block' : '';
    },
    lineClass() {
      return (this.withIcon) ? 'v-address-line' : '';
    },
    zipAndCity() {
      let line = '';
      if (this.address.zip) {
        line += this.address.zip;
      }

      if (this.address.city) {
        line = `${line} ${this.address.city}`;
      }

      return line.trim();
    },
    showCoords() {
      return !this.addressPresent && this.hasCoords;
    },
    empty() {
      return !this.addressPresent && !this.hasCoords;
    },
    addressPresent() {
      const adr = this.address;
      return ((adr.line1 && adr.line1.length)
              || (adr.line2 && adr.line2.length)
              || (adr.line3 && adr.line3.length)
              || (adr.zip && adr.zip.length))
              || (adr.city && adr.city.length);
    },
    hasCoords() {
      return (this.address.lat != null && this.address.lng != null);
    },
  },
  methods: {

    latDir(decimalCoord) {
      if (decimalCoord > 0) { return 'N'; }
      return 'S';
    },
    lngDir(decimalCoord) {
      if (decimalCoord > 0) { return 'E'; }
      return 'W';
    },
    degMinSec(decimalCoord) {
      if (decimalCoord == null) { return null; }

      const vars = decimalCoord.split('.');
      let deg = vars[0];
      if (deg < 0) { deg *= -1; }
      let tempma = `0.${vars[1]}`;
      tempma *= 3600;
      const min = Math.floor(tempma / 60);
      const sec = (tempma - (min * 60)).toFixed(4);
      return `${deg}° ${min}' ${sec}''`;
    },
  },
};
</script>

<style scoped>
  div.v-address-block {
    position: relative;
  }
  div.v-address-block:before {
    font-family: "Font Awesome 5 Pro" !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f3c5";
    position: absolute;
    top: 3px;
    left: 0;
    color: black;
  }
   div.v-address-line {
     padding-left: 14px;
   }
</style>
