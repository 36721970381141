<template>
  <div>
    <span
      v-for="assignedLabel in localAssignedLabels"
      :key="assignedLabel.id"
      style="line-height: 32px;"
    >
      <span
        class="fx-label-large"
        :style="styleForLabel(assignedLabel)"
      >{{ assignedLabel.symbol }}
        <a v-if="canEdit" class="delete" @click="removeFeature(assignedLabel)">
          <i v-tippy="{ placement: 'top', duration: 100, arrow: true }" class="fas fa-times" title="Label entfernen" />
        </a>
      </span>&shy;
    </span>

    <drop-down
      v-if="canEdit"
      text="hinzufügen"
      classes="fx-label-btn-legacy"
      wrapper-classes="left-align-wrapper"
    >
      <drop-down-item v-for="label in availableLabels" :key="label.id">
        <a @click="addFeature(label)"><div :style="styleForColorBlock(label)" /> {{ label.symbol }}</a>
      </drop-down-item>

      <drop-down-item v-if="availableLabels.length === 0">
        <div class="secondary" style="margin: .6rem 1rem; white-space: nowrap;">
          Keine Labels verfügbar
        </div>
      </drop-down-item>
      <hr style="margin: 0;">
      <drop-down-item>
        <a href="/organizations/features#thing"><i class="fas fa-cog" /> Labels bearbeiten</a>
      </drop-down-item>
    </drop-down>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash';

import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'AssignFeatures',
  components: {
    DropDown,
    DropDownItem,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localLabels: [],
      localAssignedLabels: [],
      serverErrors: [],
    };
  },
  computed: {
    availableLabels() {
      const labels = this.localLabels.slice();
      this.localAssignedLabels.forEach((assignedLabel) => {
        const index = _.findIndex(labels, { id: assignedLabel.id });
        if (index > -1) {
          labels.splice(index, 1);
        }
      });
      return labels;
    },
  },
  mounted() {
    this.localAssignedLabels = this.modelValue;
    if (this.canEdit) {
      this.loadLabels();
    }
  },
  methods: {
    styleForLabel() {
      return 'color: #fff; background-color: #2C556E;';
    },
    styleForColorBlock() {
      // eslint-disable-next-line max-len
      return 'display:inline-block; width: 16px; height: 16px; background-color: #2C556E; vertical-align: bottom; border-radius: 5px; margin-bottom: 4px; margin-right: 5px;';
    },
    findAssignedLabelIndexInList(assignedLabelId) {
      return _.findIndex(this.localAssignedLabels, { id: assignedLabelId });
    },
    loadLabels() {
      const that = this;
      axios.get('/features.json').then((response) => {
        that.localLabels = response.data.features;
        // this.addFeatureByIds(this.presetIds);
      }).catch(() => {
        Flash.error('Label-Informationen konnten nicht geladen werden');
      });
    },
    addFeature(feature) {
      this.localAssignedLabels.push(feature);
      this.$emit('input', this.localAssignedLabels);
    },
    removeFeature(feature) {
      this.localAssignedLabels.splice(this.findAssignedLabelIndexInList(feature.id), 1);
      this.$emit('input', this.localAssignedLabels);
    },
    addFeatureByIds(arrIds) {
      arrIds.forEach((assignedLabel) => {
        const index = _.findIndex(this.labels, { id: assignedLabel.id });
        this.addFeature([index]);
      });
    },
  },
};
</script>

<style>
.left-align-wrapper {
  right: unset;
  left: 10px;
  width: 300px;
}

.fx-label-btn-legacy {
  color: #256FC5;
  font-size: .825rem;
  margin-left: .1rem;
  background-color: #f0f0f0;
  padding: 3px 8px;
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #f0f0f0;
}

.fx-label-btn:hover {
  background-color: #dddddd;
}

.delete {
  color: inherit !important;
  padding-left: .7rem;
}
</style>
