<template>
  <span>
    <div class="multi-select">
      <button type="button" class="btn-select" @click="toggleCheckboxes">
        <div class="buttonLabel" :class="classForNothingSelected">
          <i class="fas fx-select-icon" :class="icon" />
          {{ selectBoxLabel }}
          <span class="caret" />
        </div>
      </button>

      <OnClickOutside @trigger="externalClick">
        <div class="checkboxLayer" :class="{ 'show': isOpen }">
          <div class="helperContainer" style="margin: 0 .4rem 1rem .4rem;">
            <button :class="tabClasses(0)" style="padding-top: .5rem; padding-bottom: .5rem" @click="setActiveTab(0)">{{
              $t('comp.filter.date-range.presets') }}</button>
            <button :class="tabClasses(1)" style="padding-top: .5rem; padding-bottom: .5rem" @click="setActiveTab(1)">{{
              $t('comp.filter.date-range.custom-date-range') }}</button>
            <hr style="margin-top:0; margin-bottom: .4rem;">
            <div v-if="isActiveTab(0)">
              <div style="display: inline-block; width: 360px; vertical-align: top;">

                <h5>{{ $t(`comp.filter.${i18nBaseKey}.current-status`) }}</h5>
                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a :class="classForOption(defaultOption)" @click="selectOption(defaultOption)">Alle Fälligkeiten</a>
                  <a :class="classForOption(uncheckedOption)" @click="selectOption(uncheckedOption)"><i
                    class="fas color-unchecked"
                    :class="icon"
                  /> {{ uncheckedOption.label }}</a>
                </div>

                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a :class="classForOption(overdueOption)" @click="selectOption(overdueOption)"><i
                    class="fas color-overdue"
                    :class="icon"
                  /> {{ overdueOption.label }}</a>
                  <a :class="classForOption(nearOption)" @click="selectOption(nearOption)"><i
                    class="fas color-near"
                    :class="icon"
                  /> {{ nearOption.label }}</a>
                  <a :class="classForOption(farOption)" @click="selectOption(farOption)"><i
                    class="fas color-far"
                    :class="icon"
                  /> {{ farOption.label }}</a>
                </div>

                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a :class="classForOption(overdueNearOption)" @click="selectOption(overdueNearOption)"><i
                    class="fas color-overdue"
                    :class="icon"
                  /><i class="fas color-near" :class="icon" /> {{
                    overdueNearOption.label }}</a>
                  <a :class="classForOption(unknownOption)" @click="selectOption(unknownOption)"><i
                    class="fas color-unknown"
                    :class="icon"
                  /> {{ unknownOption.label }}</a>
                </div>

                <h5>{{ $t(`comp.filter.${i18nBaseKey}.due-in-month`) }}</h5>
                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a
                    v-for="option in nextMonthsOptionsA"
                    :key="option.id"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ option.label }}</a>
                </div>
                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a
                    v-for="option in nextMonthsOptionsB"
                    :key="option.id"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ option.label }}</a>
                </div>
              </div>
            </div>
            <div v-if="isActiveTab(1)">
              <div style="display: inline-block; width: 610px; vertical-align: top;">
                <table style="margin-bottom: .5rem;">
                  <tr>
                    <td style="width: 300px; vertical-align: top; padding-right: 10px;">
                      <span style="font-size: .8rem;">{{ $t('comp.filter.date-range.startdate') }}</span>

                      <VueDatePicker
                        v-model="customStartDate"
                        :inline="true"
                        :locale="$i18n.locale"
                        :auto-apply="true"
                        model-type="yyyy-MM-dd"
                        :enable-time-picker="false"
                      />
                    </td>
                    <td style="width: 300px; vertical-align: top;">
                      <span style="font-size: .8rem;">{{ $t('comp.filter.date-range.enddate') }}</span>
                      <VueDatePicker
                        v-model="customEndDate"
                        :inline="true"
                        :locale="$i18n.locale"
                        model-type="yyyy-MM-dd"
                        :auto-apply="true"
                        :enable-time-picker="false"
                      />
                    </td>
                  </tr>
                </table>
                <a :class="classCustomRangeButton" @click="selectCustomTimerange">{{
                  $t('comp.filter.date-range.apply-custom-date-range') }}</a>
                <span v-if="!customStartDate" class="secondary" style="font-size: .8rem; margin-left: .5rem;">{{
                  $t('comp.filter.date-range.please-select-startdate') }}</span>
                <span
                  v-if="customStartDate && !customEndDate"
                  class="secondary"
                  style="font-size: .8rem; margin-left: .5rem;"
                >{{
                  $t('comp.filter.date-range.please-select-enddate') }}</span>

                <span
                  v-if="customStartDate && customEndDate"
                  style="font-size: .8rem; margin-left: .5rem; font-weight: bold;"
                >
                  {{ formattedCustomRange }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
  </span>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { OnClickOutside } from '@vueuse/components';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'FilterDueDatesTimerange',
  components: {
    OnClickOutside,
    VueDatePicker,
  },
  mixins: [DateFormatter],
  props: {
    i18nBaseKey: {
      type: String,
      required: true,
    },
    modelValue: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: 'fa-circle',
    },
  },
  emits: ['changed'],
  data() {
    return {
      dateSupport: {
        months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      },
      activeTab: 0,
      customStartDate: null,
      customEndDate: null,
      customRelativeRange: null,
      farOption: { label: 'nicht fällig', id: 'far' },
      overdueOption: { label: 'überfällig', id: 'overdue' },
      nearOption: { label: 'bald überfällig', id: 'near' },
      overdueNearOption: { label: 'überfällig | bald überfällig', id: 'overdue_near' },
      unknownOption: { label: 'unbekannt', id: 'unknown' },
      uncheckedOption: { label: 'fällig', id: 'unchecked' },
      nextMonthsOptionsA: [],
      nextMonthsOptionsB: [],
      valueSelected: { label: '', id: null },
      multiSelect: null,
      isOpen: false,
    };
  },
  computed: {
    defaultOption() {
      return { label: this.nothingSelectedLabel, id: 'none' };
    },
    nothingSelectedLabel() {
      return this.$t(`comp.filter.${this.i18nBaseKey}.title`);
    },
    formattedCustomRange() {
      let from;
      let to;
      if (this.customEndDate < this.customStartDate) {
        to = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        from = this.formatDate(this.customEndDate, 'DD.MM.YYYY');
      } else {
        from = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        to = this.formatDate(this.customEndDate, 'DD.MM.YYYY');
      }
      return `${from} - ${to}`;
    },
    classCustomRangeButton() {
      if (this.customStartDate && this.customEndDate) {
        return 'fx-btn-primary';
      }
      return 'fx-btn-secondary disabled';
    },
    classForNothingSelected() {
      if ((this.valueSelected.id == null) || (this.valueSelected.id === 'none')) {
        return 'dimmed';
      }
      return '';
    },
    selectBoxLabel() {
      if (this.valueSelected) {
        return this.valueSelected.label;
      }
      return this.nothingSelectedLabel;
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        this.initFromModelValue(newVal);
      },
      deep: true,
    },
  },
  created() {
    this.prepareMonthOptions();
  },
  mounted() {
    this.valueSelected = this.defaultOption;
    this.initFromModelValue(this.modelValue);
  },
  methods: {
    initFromModelValue(newVal) {
      if (newVal == null) {
        this.valueSelected = this.defaultOption;
      }

      const found = this.setSelectedValueById(newVal);

      if (!found) {
        this.setCustomRangeById(newVal);
        this.setActiveTab(1);
      } else {
        this.setActiveTab(0);
        this.customStartDate = null;
        this.customEndDate = null;
      }
    },
    tabClasses(tab) {
      if (tab === this.activeTab) {
        return 'fx-tab fx-tab__active';
      }
      return 'fx-tab';
    },
    isActiveTab(tab) {
      return (this.activeTab === tab);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    classForOption(option) {
      if (option.id === this.valueSelected.id) {
        return 'fx-btn-skeleton option-button-selected';
      }
      return 'fx-btn-skeleton option-button';
    },
    toggleCheckboxes(event) {
      this.multiSelect = event.target;
      if (this.multiSelect.className === 'buttonLabel') {
        this.multiSelect = this.multiSelect.parentNode;
      }
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.valueSelected = option;
      this.isOpen = false;
      this.customStartDate = null;
      this.customEndDate = null;
      this.$emit('changed', this.valueSelected.id);
    },
    selectCustomTimerange() {
      if (this.customStartDate && this.customEndDate) {
        if (this.customEndDate < this.customStartDate) {
          const holdMyBeer = this.customStartDate;
          this.customStartDate = this.customEndDate;
          this.customEndDate = holdMyBeer;
        }

        const from = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        const to = this.formatDate(this.customEndDate, 'DD.MM.YYYY');

        this.valueSelected = { label: `${from} bis ${to}`, id: this.customTimerangeId() };
        this.isOpen = false;

        this.$emit('changed', this.valueSelected.id);
      }
    },
    customTimerangeId() {
      return `${this.formatDate(this.customStartDate, 'YYYY-MM-DD')}_${this.formatDate(this.customEndDate, 'YYYY-MM-DD')}`;
    },
    externalClick(event) {
      if (this.isOpen) {
        let elem = event.target;
        if (!!elem && elem.className === 'buttonLabel') {
          elem = elem.parentNode;
        }
        if (!!elem && elem.isSameNode(this.multiSelect)) {
          return;
        }
        this.isOpen = false;
      }
    },
    setSelectedValueById(newVal) {
      const arrayOfOptionArrays = [
        [
          this.defaultOption,
          this.uncheckedOption,
          this.farOption,
          this.nearOption,
          this.overdueOption,
          this.overdueNearOption,
          this.unknownOption,
        ],
        this.nextMonthsOptionsA,
        this.nextMonthsOptionsB];

      for (let ii = 0; ii < arrayOfOptionArrays.length; ii += 1) {
        const options = arrayOfOptionArrays[ii];
        for (let i = 0; i < options.length; i += 1) {
          if (options[i].id === newVal) {
            this.valueSelected = options[i];
            return true;
          }
        }
      }
      return false;
    },
    setCustomRangeById(newVal) {
      if (newVal && (typeof newVal === 'string')) {
        const str1 = newVal.split('_')[0];
        const str2 = newVal.split('_')[1];
        if (str1 && str2) {
          this.customStartDate = str1;
          this.customEndDate = str2;
          this.selectCustomTimerange();
        }
      }
    },
    prepareMonthOptions() {
      const monthIndex = new Date().getMonth();
      const year = new Date().getFullYear();
      for (let i = monthIndex; i < monthIndex + 3; i += 1) {
        const optionsIndex = (i < 12) ? i : i - 12;
        const optionsYear = (i < 12) ? year : year + 1;
        this.nextMonthsOptionsA.push({ label: `${this.dateSupport.months[optionsIndex]} ${optionsYear}`, id: `${optionsIndex + 1}.${optionsYear}` });
      }

      for (let i = monthIndex + 3; i < monthIndex + 6; i += 1) {
        const optionsIndex = (i < 12) ? i : i - 12;
        const optionsYear = (i < 12) ? year : year + 1;
        this.nextMonthsOptionsB.push({ label: `${this.dateSupport.months[optionsIndex]} ${optionsYear}`, id: `${optionsIndex + 1}.${optionsYear}` });
      }
    },
  },
};
</script>

<style scoped>
h4.timerange {
  color: #2C556E;
  margin-top: 0.3rem;
  font-weight: 300;
  font-size: 1.2rem;
}

h5 {
  color: #2C556E;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: normal;
  font-size: .8rem;
}

a.option-button {
  margin-right: .2rem;
}

a.option-button-selected {
  margin-right: .2rem;
  background-color: #E4E9EC;
  color: #2C556E;
}
</style>
