<template>
  <div id="incident-quickview-modal" class="fx-modal-content-large">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>
    <div v-if="incident && features.incidentsPro" style="float: right; margin-right: 1.5rem;">
      <a
        :href="incidentUrl"
        class="fx-btn-secondary"
      >Details</a>
    </div>
    <span v-if="loading">loading ...</span>

    <template v-if="incident">
      <h2 class="fx">
        <strong>Störung #{{ incident.number }}</strong> {{ truncatedTypeName }}
      </h2>

      <div v-if="incident" class="fx-grid">
        <div class="fx-col-notop" style="font-size: .9rem; padding-left: 0;">
          <LabelsAssign
            v-if="features.incidentsPro"
            :preset-labels="incident.labels"
            label-type="incident"
            :lablable-id="incident.id"
            :can-edit="currentUser.policies.canEditProLabels"
            :link="incident.link"
            @changed="$emit('changed', incident)"
          />

          <PhotosPreview :photos="incident.photos" @click="goDetails()" />
          <div class="bold mt-4" style="font-size: .9rem;">
            {{ incident.incident_type.name }}
          </div>

          <div
            v-if="incident.description"
            style="mmin-height: 100px; max-height: 400px; overflow: auto; white-space: pre-line;"
          >
            {{
              incident.description }}
          </div>
          <div v-if="!incident.description" class="secondary">
            Keine Beschreibung
          </div>

          <div v-if="!incidentStatusId" class="bold mt-4">
            Hinweis am aktuellen Status ({{
              formatDate(currentIncidentStatus.statusdate, 'DD.MM.YYYY') }})
          </div>
          <div v-if="incidentStatusId" class="bold mt-4">
            Hinweis im Auftrag {{ incidentStatusForDetails.job.number }} {{ incidentStatusForDetails.job.label }}
          </div>
          <div v-if="incidentStatusForDetails.note" style="max-height: 200px; overflow: auto; white-space: pre-line;">
            {{
              incidentStatusForDetails.note }}
          </div>
          <div v-if="!incidentStatusForDetails.note" class="secondary">
            Kein Hinweis
          </div>
        </div>

        <div class="fx-col-notop p-0 m-0" style="max-width: 450px;">
          <table class="material top-align">
            <tbody>
              <tr class="middle-align">
                <th class="pl-0" style="width: 150px;">
                  Aktueller Status
                </th>
                <td colspan="2">
                  <div v-if="currentIncidentStatus">
                    <span v-if="isStatus('reported')" class="fx-status incident-reported">{{ statusText('reported')
                    }}</span>
                    <span v-if="isStatus('opened')" class="fx-status incident-opened">{{ statusText('opened') }}</span>
                    <span v-if="isStatus('closed')" class="fx-status incident-closed">{{ statusText('closed') }}</span>
                  </div>
                </td>
              </tr>

              <tr>
                <th class="pl-0">
                  Schweregrad
                </th>
                <td colspan="2">
                  <span :class="'severity-' + incident.severity">
                    {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
                  </span>
                </td>
              </tr>
              <tr v-if="currentIncidentStatus">
                <th class="pl-0">
                  Lösung
                </th>
                <td colspan="2">
                  <span :class="'resolution-' + currentIncidentStatus.resolution">
                    {{ $t('activerecord.attributes.incident_status.resolutions.' + currentIncidentStatus.resolution) }}
                  </span>
                </td>
              </tr>
              <tr v-if="features.incidentsPro">
                <th class="pl-0">
                  Gemeldet von
                </th>
                <td colspan="2">
                  <div v-if="incident.reported_by">
                    {{ incident.reported_by }}
                  </div>
                  <div v-else class="secondary">
                    keine Angabe
                  </div>
                </td>
              </tr>
              <tr v-if="features.incidentsPro">
                <th class="pl-0">
                  Zielauftrag
                </th>
                <td colspan="2">
                  <div v-if="assignedJob">
                    <a :href="assignedJobUrl" class="no-color">{{ assignedJob.label }}</a><br>
                    <span class="fx-status" :class="assignedJob.status">{{ jobStatusText(assignedJob.status) }}</span>
                    <span v-if="assignedJob.days_away > 0" style="margin-left: .6rem;" class="secondary">(in {{
                      assignedJob.days_away }} Tagen)</span>
                    <span v-if="assignedJob.days_away == 0" style="margin-left: .6rem;" class="secondary">heute</span>
                  </div>
                  <div v-else class="secondary">
                    keine Angabe
                  </div>
                </td>
              </tr>
              <tr v-if="incident.customer">
                <th class="pl-0">
                  Kunde
                </th>
                <td colspan="2">
                  <Labels v-if="incident.customer" :labels="incident.customer.labels" />
                  <template v-if="showLinks">
                    <a :href="customerUrl" class="no-color">
                      <div class="secondary">{{ incident.customer.number }}</div>
                      {{ incident.customer.name }}
                    </a>
                  </template>
                  <template v-else>
                    <span class="secondary">{{ incident.customer.number }}</span>
                    <br>
                    {{ incident.customer.name }}
                  </template>
                </td>
              </tr>
              <tr>
                <th class="pl-0">
                  Objekt
                </th>
                <td colspan="2">
                  <Location :location="incident.installation.location" :link="showLinks" />
                </td>
              </tr>
              <tr>
                <th class="pl-0">
                  Anlage
                </th>
                <td colspan="2">
                  <Labels :labels="incident.installation.labels" />
                  <Installation :installation="incident.installation" :link="showLinks" />
                </td>
              </tr>
              <tr>
                <th class="pl-0">
                  Gruppe
                </th>
                <td colspan="2">
                  <div v-if="incident.thing">
                    <template v-if="showLinks">
                      <a :href="groupUrl" class="no-color">{{ groupLabel }}</a>
                    </template>
                    <template v-else>
                      {{ groupLabel }}
                    </template>
                  </div>
                  <div v-else class="secondary">
                    keine Angabe
                  </div>
                </td>
              </tr>
              <tr>
                <th class="pl-0">
                  Komponente
                </th>
                <td colspan="2">
                  <div v-if="incident.thing">
                    <template v-if="showLinks">
                      <i class="far fa-cube fx-inline-ico" />
                      <a :href="thingUrl" class="no-color">{{ incident.thing.label }}</a>
                      <div>{{ incident.thing.position }}</div>
                    </template>
                    <template v-else>
                      {{ incident.thing.label }}
                    </template>
                  </div>

                  <div v-else class="secondary">
                    keine Angabe
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import _ from 'lodash';
import { useCurrentUserStore } from 'stores/current_user';

import FoxtagPaths from 'mixins/foxtag_paths';
import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter } from 'mixins/formatters';

import LabelsAssign from 'apps/labels/assign/index.vue';
import Location from 'components/partials/location.vue';
import Installation from 'components/partials/installation.vue';
import Labels from 'components/partials/labels.vue';
import PhotosPreview from 'apps/photos/photos_preview.vue';
import IncidentModel from './incident_model';
import Api from './api';

function initialData() {
  return {
    incident: null,
    assignedJob: null,
    showLinks: true,
    loading: false,
    jobStatusId: null,
  };
}

export default {
  name: 'IncidentQuickViewModal',
  components: {
    Location,
    Installation,
    Labels,
    LabelsAssign,
    PhotosPreview,
  },
  mixins: [FoxtagPaths, FeatureFlipper, DateFormatter],
  props: {
    incidentId: {
      type: String,
      required: true,
    },
    incidentStatusId: {
      type: String,
      default: null,
    },
  },
  emits: ['close', 'changed'],
  setup() {
    return { currentUser: useCurrentUserStore().currentUser };
  },
  data() {
    return initialData();
  },
  computed: {
    truncatedTypeName() {
      if (this.incident != null) {
        return _.truncate(this.incident.incident_type.name, { length: 80, omission: '...' });
      }
      return '';
    },
    customerUrl() {
      return `/customers/${this.incident.customer.id}`;
    },
    installationUrl() {
      return `/installations/${this.incident.installation.id}`;
    },
    thingUrl() {
      return `/things/${this.incident.thing.id}`;
    },
    incidentUrl() {
      return `/incidents/${this.incident.id}`;
    },
    currentIncidentStatus() {
      if (this.incident == null) { return null; }
      return IncidentModel.getCurrentStatus(this.incident);
    },
    incidentStatusForDetails() {
      if (this.incidentStatusId && this.incident) {
        return IncidentModel.getStatusById(this.incident, this.incidentStatusId);
      }
      return this.currentIncidentStatus;
    },
    groupLabel() {
      if (this.incident.thing == null) {
        return '';
      }
      const group = this.incident.thing.group;
      return `${group.number >= 0 ? group.number : ''} ${group.name}`;
    },
    groupUrl() {
      return `${this.installationUrl}#tab-things`;
    },
    assignedJobUrl() {
      return `/jobs/${this.assignedJob.id}`;
    },
  },
  mounted() {
    if (this.incidentId == null) { return; }

    const that = this;
    axios.get(`/incidents/${this.incidentId}.json`).then((response) => {
      that.incident = response.data.incident;
      that.loading = false;
    }).catch(() => {
      that.loading = false;
      that.error = true;
    });

    if (this.features.incidentsPro) {
      Api.getAssignedJob({ incidentId: this.incidentId })
        .then((response) => {
          this.assignedJob = response.data.job;
        });
    }
  },
  methods: {
    goDetails() {
      window.location.href = this.incidentUrl;
    },
    isStatus(status) {
      return this.currentIncidentStatus != null && this.currentIncidentStatus.status === status;
    },
    statusText(status) {
      return this.$t(`activerecord.attributes.incident_status.statuses.${status}`);
    },
    jobStatusText(status) {
      return this.$t(`job.status.${status}`);
    },
  },
};
</script>
<style>
table.material th.pl-0 {
  padding-left: 0;
}
</style>
