<template>
  <div class="fx-modal-content-medium">
    <a class="fx-modal__close" @click="$emit('close')">&times;</a>

    <h2 class="fx">
      {{ title }}
    </h2>
    <div v-if="intro && intro.length" class="mb-4 secondary">
      {{ intro }}
    </div>
    <button id="export-settings-btn" :class="$tabClasses('form')" @click="$setActiveTab('form', false)">
        Einstellungen
      </button>
      <button id="export-history-btn" :class="$tabClasses('history')" @click="$setActiveTab('history', false); createdExport = null;">
        Zuletzt erzeugt
        <span class="fx-tab-count">
          {{ recentExports.length }}
        </span>
      </button>
      <hr style="margin-top:0;">

    <div v-if="$isTabInitialized('form')" v-show="$isTabActive('form')" class="tab-panel">
      <div v-if="!loading && !createdExport" id="generate-export">
        <enqueue-filtered-installations
          v-if="isType('installations')"
          :exporttype="exporttype"
          :config="config"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-filtered-jobs
          v-if="isType('jobs')"
          :exporttype="exporttype"
          :config="config"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-filtered-incidents
          v-if="isType('incidents')"
          :exporttype="exporttype"
          :config="config"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-due-replacements
          v-if="isType('due_replacements')"
          :exporttype="exporttype"
          :config="config"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-installation-year-progress
          v-if="isType('installation_year_progress')"
          :installation-id="exportableId"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-installation-type-year-progress
          v-if="isType('installation_type_year_progress') || isType('installation_type_year_progress_csv')"
          :exporttype="exporttype"
          :intro="intro"
          @enqueued="onEnqueued"
        />

        <enqueue-job-article-sums
          v-if="isType('job_article_sums')"
          :exporttype="exporttype"
          :intro="intro"
          :config="config"
          @enqueued="onEnqueued"
        />

        <enqueue-export
          v-if="isStandardExport()"
          :exporttype="exporttype"
          :exportable-id="exportableId"
          :intro="intro"
          :config="config"
          @enqueued="onEnqueued"
        />
      </div>

      <div v-if="loading" id="export-generating">
        <div
          class="empty-state-box"
          style="background-color: white; border: 1px solid #ddd;
          padding: 3rem; margin: 1rem 0; color: #929292; font-size: 1rem;"
        >
          <div class="text-center">
            <i class="fas fa-circle-notch fa-spin fa-2x" />
          </div>
          <div class="text-center">
            Datei wird erzeugt
          </div>
        </div>
      </div>

      <div v-if="!loading && createdExport" id="export-generated">
        <div
          class="empty-state-box"
          style="background-color: white; border: 1px solid #ddd; padding: 3rem;
              margin: 1rem 0; color: #929292; font-size: 1rem;"
        >
          <div class="text-center" style="padding-bottom: .5rem;">
            Export steht nun bereit
          </div>
          <div class="text-center">
            <a class="fx-btn-ico-primary" :href="exportDownloadUrl"><i class="fas fa-download" />
              Datei herunterladen</a>
          </div>
        </div>
      </div>
    </div>

    <div v-if="$isTabInitialized('history')" v-show="$isTabActive('history')" class="tab-panel">
      <table id="recent-files-table" style="border-collapse: collapse;width: 100%;">
        <tr v-for="recentExport in recentExports" :key="recentExport.id" style="border-bottom: 1px solid #eee;">
          <td class="col-date">
            {{ requestedDateTime(recentExport) }}
          </td>
          <td class="col-name">
            {{ truncate(recentExport.name, 40) }} ({{ recentExport.file_type }})
          </td>
          <td class="col-download">
            <a class="fx-btn-skeleton" :href="downloadUrl(recentExport)"><i
              class="fas fa-download"
            /> herunterladen</a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';
import Flash from 'flash/index';

import { TruncateFormatter } from 'mixins/formatters';

import EnqueueInstallationYearProgress from './enqueue_installation_year_progress.vue';
import EnqueueInstallationTypeYearProgress from './enqueue_installation_type_year_progress.vue';
import EnqueueFilteredInstallations from './enqueue_filtered_installations.vue';
import EnqueueFilteredJobs from './enqueue_filtered_jobs.vue';
import EnqueueFilteredIncidents from './enqueue_filtered_incidents.vue';
import EnqueueDueReplacements from './enqueue_due_replacements.vue';
import EnqueueJobArticleSums from './enqueue_job_article_sums.vue';
import EnqueueExport from './enqueue_export.vue';

export default {
  name: 'ExportsModal',
  components: {
    EnqueueInstallationYearProgress,
    EnqueueInstallationTypeYearProgress,
    EnqueueFilteredInstallations,
    EnqueueFilteredJobs,
    EnqueueFilteredIncidents,
    EnqueueDueReplacements,
    EnqueueJobArticleSums,
    EnqueueExport,
  },
  mixins: [TruncateFormatter],
  props: {
    exportableId: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    intro: {
      type: String,
      default: '',
    },
    exporttype: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      year: 2019,
      loading: false,
      pollingTimeout: null,
      exportId: null,
      createdExport: null,
      recentExports: [],
      activeTab: null,
      initializedTabs: new Set(),
    };
  },
  computed: {
    exportDownloadUrl() {
      return `/exports/${this.exportId}/download`;
    },
    exportName() {
      if (this.createdExport) {
        return this.createdExport.name;
      }
      return '';
    },
  },
  mounted() {
    this.$setActiveTab('form', false);
    this.loadRecentExports();
  },
  methods: {
    isType(exporttype) {
      return this.exporttype === exporttype;
    },
    isStandardExport() {
      return ['customers_installations', 'customers', 'locations', 'articles', 'contacts', 'things', 'thing_types'].includes(this.exporttype);
    },
    downloadUrl(exprt) {
      return `/exports/${exprt.id}/download`;
    },
    requestedDateTime(exprt) {
      if (!exprt.requested_at) { return ''; }
      return `${moment(exprt.requested_at).format('DD.MM.YYYY HH:mm')} Uhr`;
    },
    years() {
      let year = new Date().getFullYear();
      const years = [];
      while (year > 2015) {
        years.push(year);
        year -= 1;
      }
      return years;
    },
    beforeClose() {
      if (this.pollingTimeout) {
        clearTimeout(this.pollingTimeout);
      }
    },
    pollForExport() {
      this.pollingTimeout = setTimeout(() => {
        const that = this;
        axios.get(`/exports/${this.exportId}`, {
        }).then((response) => {
          that.createdExport = response.data.export;
          if (that.createdExport && that.createdExport.status === 'provided') {
            that.loading = false;
            that.loadRecentExports();
          } else {
            that.pollForExport();
          }
        }).catch(() => {
          that.loading = false;
          Flash.error('Fehler beim Laden des Status');
        });
      }, 3000);
    },
    onEnqueued(exportId) {
      this.createdExport = null;
      this.exportId = exportId;
      this.loading = true;
      this.pollForExport();
    },
    loadRecentExports() {
      axios.get('/exports', {
        params: {
          exporttype: this.exporttype,
          exportable_id: this.exportableId,
        },
      }).then((response) => {
        this.recentExports = response.data.exports;
      }).catch(() => {
        Flash.error('Fehler beim Laden');
      });
    },
  },
};
</script>

<style scoped>
td.col-date {
  width: 9rem;
  font-size: .825rem;
  padding: .5rem 0;
}

td.col-name {
  font-size: .825rem;
  padding: .5rem 0;
}

td.col-download {
  width: 9rem;
  font-size: .825rem;
  padding: .5rem 0;
  text-align: right;
}
</style>
