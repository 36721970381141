<template>
  <div class="fx-list-row">
    <div class="fx-10px-col">
      <button
        v-tippy="{ placement: 'right', duration: 100, arrow: true }"
        class="btn-arrow"
        title="Details zeigen"
        @click.prevent.stop="showDetails= !showDetails;"
      >
        <i class="fa" :class="detailsClass" />
      </button>
    </div>
    <div class="fx-100px-col">
      <strong>{{ eventDate }}</strong> {{ eventTime }}
    </div>
    <div class="fx-150px-col">
      {{ groupNumberAndName(checkEvent.thing) }}
    </div>
    <div class="fx-100px-col">
      <a :href="thingPath(checkEvent.thing.id)">{{ thingNumber(checkEvent.thing, groupSeparator) }}</a>
    </div>
    <div class="fx-220px-col">
      {{ checkEvent.thing.thing_type.name }}
      <div class="secondary">
        {{ thingMake(checkEvent.thing ) }}
      </div>
    </div>
    <div class="fx-300px-col">
      <div>
        <span v-for="(feature, index) in checkEvent.thing.assigned_features" :key="index">
          <span class="fx-label mb-2" style="background-color: #2C556E; color: #fff;">{{ feature.symbol }}</span>&shy;
        </span>
      </div>

      <ThingDetails :thing="checkEvent.thing" />
    </div>
    <div class="fx-10px-col">
      <span v-if="checkEvent.data.length" class="secondary">
        <a
          v-tippy="{ placement: 'top', duration: 100, arrow: true }"
          class="no-color"
          title="Checkliste ansehen"
          @click="showDetails= !showDetails;"
        ><i class="fas fa-list" /></a>
      </span>
    </div>
    <div class="fx-100px-col">
      <span :class="resultClass"><span v-if="isConfirmed" :title="confirmationText"><i class="fas fa-shield-check" /></span> {{ result }}</span>
    </div>
    <div v-if="showDetails" style="padding: 1rem 0 2rem 100px; background: #f6f6f6">
      <table class="material">
        <tr>
          <td class="title" style="width: unset; border-top: 1px solid #eee; ">
            Geprüft von:
          </td>
          <td>{{ checkEvent.user.name }}</td>
        </tr>
      </table>
      <DataTable :data="checkEvent.data" />
      <IncidentsForEventTable :thing-id="checkEvent.thing.id" :event-id="checkEvent.id" />
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import FoxtagPaths from 'mixins/foxtag_paths';
import ThingPresenter from 'mixins/thing_presenter';

import ThingDetails from 'components/partials/thing_details.vue';
import DataTable from 'components/partials/data_table.vue';
import IncidentsForEventTable from 'components/partials/incidents_for_event_table.vue';

export default {
  name: 'CheckEvent',
  components: {
    ThingDetails,
    DataTable,
    IncidentsForEventTable,
  },
  mixins: [FoxtagPaths, ThingPresenter],
  props: {
    checkEvent: {
      type: Object,
      required: true,
    },
    positiveResultLabel: {
      type: String,
      default: 'OK',
    },
    negativeResultLabel: {
      type: String,
      default: 'Mangel',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      showDetails: false,
    };
  },
  computed: {
    eventDate() {
      return moment(this.checkEvent.eventdate).format('DD.MM.YYYY');
    },
    eventTime() {
      return `${moment(this.checkEvent.eventdate).format('HH:mm')} Uhr`;
    },
    result() {
      if (this.checkEvent.result === 'success') {
        return this.positiveResultLabel;
      }
      return this.negativeResultLabel;
    },
    resultClass() {
      return `result-label-${this.checkEvent.result}`;
    },
    detailsClass() {
      if (this.showDetails) {
        return 'fa-chevron-down';
      }
      return 'fa-chevron-right';
    },
    isConfirmed() {
      return (this.checkEvent.confirmation != null && this.checkEvent.confirmation !== 'no_confirm');
    },
    confirmationText() {
      return this.$t(`activerecord.attributes.event.confirmations.${this.checkEvent.confirmation}`);
    },
  },
};
</script>

<style>
  button.btn-arrow {
    color: #256FC5;
  }
  button.btn-arrow:focus {
    outline: 0;
    border: 0;
    box-shadow: none;
  }
</style>
