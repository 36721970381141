<template>
  <button
    :id="id"
    :class="classes"
    :disabled="buttonDisabled"
    @click="clicked"
  >
    <i
      v-if="showSpinner"
      style="margin-right: .2rem;"
      class="fas fa-circle-notch fa-spin
    "
    />{{ title }}
  </button>
</template>

<script>
export default {
  name: 'TransactionButton',
  props: {
    title: {
      type: String,
      default: null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'btn-save',
    },
    milliesTilSpinner: {
      type: Number,
      default: 1500,
    },
    noSpinner: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data() {
    return {
      inProgress: false,
      spinner: false,
    };
  },
  computed: {
    buttonDisabled() {
      return this.inProgress || this.disabled;
    },
    showSpinner() {
      return this.inProgress && this.spinner;
    },
    classes() {
      if (this.inProgress) {
        return 'wait';
      }
      return '';
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (newVal) {
          this.setInProgress();
        } else {
          this.reset();
        }
      },
      deep: false,
    },
  },
  methods: {
    clicked() {
      if (this.inProgress) {
        return;
      }
      this.setInProgress();
      this.$emit('click');
    },
    setInProgress() {
      this.inProgress = true;
      if (!this.noSpinner) {
        const that = this;
        setTimeout(() => { that.spinner = true; }, this.milliesTilSpinner);
      }
    },
    reset() {
      this.spinner = false;
      this.inProgress = false;
    },
  },
};
</script>
<style scoped>
button.wait {
  cursor: wait;
}
</style>
