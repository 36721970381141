<template>
 <section class="fx-page-content">
    <div style="background-color: #DEE5E9;">
      <div style="display: inline-block; padding: .3rem;">
        <span style="padding-left: 15px;"><i class="far fa-search" /></span>

        <DBBasedFilter
          v-model="filter.organizationId"
          i18n-base-key="service-organizations"
          :default-label="$t('comp.filter.service-organizations.default-label')"
          :allow-multi-select="false"
          load-options-url="/select/service_organizations"
          icon-id="organization"
          @changed="onOrganizationSelected"
        />

        <FilterFulltext
          v-model="filter.query"
          icon="filter"
          placeholder="Suche nach Objekt, Adresse oder Ort"
          width="250px"
          @changed="onFilterQuery"
        />

       <!-- <input v-model="filter.query" placeholder="Suche nach Objekt, Adresse oder Ort" class="fulltext-search-input">-->
        <a style="font-size: .8rem;" @click.prevent="resetSearch()">zurücksetzen</a>
      </div>
    </div>
    <div class="fx-grid" style="margin-top: 2rem;">
      <div class="fx-col">
        <table style="width:100%;">
          <tr>
            <td style="vertical-align: bottom;">
              <h1 class="fx" style="float: left; margin-bottom:0;">
                Anlagen
              </h1>
            </td>
            <td style="text-align: right;">
              <div v-if="filter.organizationId" class="text-right">
                <div
                  style="background: white; border: 1px solid #eee;
                border-radius: 3px; display: inline-block; padding: .4rem;"
                >
                  <img
                    height="80px"
                    style="height: 80px;"
                    :src="'/portal/locations/organization_logo?id='+filter.organizationId"
                  >
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div class="clearfix" />

        <Location v-for="location in localLocations" :key="location.id" :location="location" />

        <EmptyState
          v-if="loaded && !localLocations.length"
          title="Keine Freigaben gefunden"
          hint="Service-Unternehmen können die von ihnen betreuten Anlagen freigeben.
        Die freigegebenen Anlagen erscheinen auf dieser Seite."
        />

        <Pagination v-model="pagination" i18n-key="activerecord.models.location" @navigate="loadPage" />
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import EmptyState from 'components/empty_state.vue';

import Flash from 'flash/index';
import { ref, watch } from 'vue';
import FilterFulltext from 'components/filter/filter_fulltext.vue';
import DBBasedFilter from 'components/filter/db_based_filter.vue';

import Pagination from 'components/pagination.vue';
import Location from './location.vue';

export default {
  name: 'PortalLocationsList',
  components: {
    DBBasedFilter,
    FilterFulltext,
    Pagination,
    Location,
    EmptyState,
  },
  props: {
    initOrganizationId: {
      type: String,
      default: null,
    },
  },
  setup(props) {
    const localLocations = ref([]);
    const filter = ref({
      query: null,
      organizationId: props.initOrganizationId,
    });
    const pagination = ref({
      page: 1,
      pageCount: 1,
      totalCount: 0,
      limit: 25,
    });

    const load = () => {
      axios.get('/portal/locations.json', {
        params: {
          query: filter.value.query,
          organization_id: filter.value.organizationId,
          page: pagination.value.page,
          limit: pagination.value.limit,
        },
      }).then((response) => {
        localLocations.value = response.data.locations;
        pagination.value.page = response.data.meta.current_page;
        pagination.value.pageCount = response.data.meta.total_pages;
        pagination.value.totalCount = response.data.meta.total_count;
      }).catch((err) => {
        if (err.message !== 'Request aborted') {
          Flash.error(err.message);
        }
      });
    };

    const loadPage = (changedPagination) => {
      pagination.value = { ...changedPagination };
      load();
    };

    const search = () => {
      localLocations.value = [];
      pagination.value.page = 1;
      load();
    };

    const resetSearch = () => {
      filter.value = {
        query: null,
        organizationId: null,
      };
    };

    return {
      localLocations,
      filter,
      pagination,
      search,
      resetSearch,
      loadPage,
    };
  },
  data() {
    return {
      loaded: false,
      initialLoadCompleted: false,
    };
  },
  computed: {
  },
  methods: {
    onOrganizationSelected(organizationId) {
      this.filter.organizationId = organizationId;
      this.search();
    },
    onFilterQuery(query) {
      this.filter.query = query;
      this.search();
    },
  },
};
</script>
<style>
input.fulltext-search-input {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  min-height: 31px !important;
  padding: 1px 8px 1px 8px;
  position: relative;
  background-color: #fff;
  width: 300px;
}

input.fulltext-search-input::placeholder {
  color: #bbb;
  opacity: 1;
}
</style>
