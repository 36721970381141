<template>
  <div class="fx-flex-row">
    <div class="fx-flex-row-ico">
      <i class="fas fa-exclamation-triangle" />
    </div>
    <div class="fx-flex-row-main">
      <div class="fx-flex-row-title">
        <a v-if="linkToDetailsPage" class="no-color mr-2" :href="incidentUrl">
          <span class="fx-flex-row-title-number">#{{ incident.number }}</span>
          {{ truncate(incident.incident_type_name, 120) }}
        </a>
        <span v-else class="mr-2">
          <span class="fx-flex-row-title-number">#{{ incident.number }}</span>
          {{ truncate(incident.incident_type_name, 120) }}
        </span>

        <Labels :labels="incident.labels" :link="incident.link" />
      </div>

      <div class="fx-flex-row-subline">
        <span>
          <span v-if="incident.status" :class="'fx-status incident-' + incident.status">
            {{ $t('activerecord.attributes.incident_status.statuses.' + incident.status) }}
          </span>
          <span v-if="incident.statusdate" style="margin-left: .2rem;"><b>{{ statusDate }}</b> {{ statusDateTime }}</span>
        </span>

        <span v-if="incident.severity != 'normal'">
          <span :class="'severity-' + incident.severity">
            {{ $t('activerecord.attributes.incident.severities.' + incident.severity) }}
          </span>
        </span>

        <template v-if="showCustomerLocationInstallation">
          <span class="fx-ico-text fx-ico-text-installation">
            <a :href="installationUrl" class="no-color">
              <strong>{{ truncate(incident.installation.number, maxNumberLength) }}</strong> {{
                truncate(incident.installation.full_name, maxNameLength) }}
            </a>
          </span>

          <span class="fx-ico-text fx-ico-text-location">
            <a :href="locationUrl" class="no-color">
              <strong>{{ truncate(incident.installation.location_number, maxNumberLength) }}</strong> {{ truncate(locationName, maxNameLength) }}
            </a>
          </span>

          <span v-if="customerPresent" class="fx-ico-text fx-ico-text-customer">
            <a :href="customerUrl" class="no-color" :title="incident.customer.name">
              <strong>{{ truncate(incident.customer.number, maxNumberLength) }}</strong>
              {{ truncate(incident.customer.name, maxNameLength) }}
            </a>
          </span>
        </template>

        <template v-if="showThing">
          <span v-if="incident.thing_id" class="fx-ico-text fx-ico-text-thing">
            <a v-if="showContextLinks" class="no-color" :href="thingUrl">{{ thingLabel }}</a>
            <span v-else>{{ thingLabel }}</span>
          </span>
        </template>
      </div>
    </div>
    <div class="fx-flex-row-nav">
      <span
        v-if="incident.assigned_job_id"
        v-tippy="assignedJobTooltip"
        class="fx-ico-text fx-ico-text-job"
        style="color: #666; padding: 0 1rem 0 .4rem; white-space: nowrap;"
      >
        <a :href="assignedJobUrl" class="no-color">
          {{ incident.assigned_job_date }}
        </a>
      </span>
      <a v-if="showQuickview" class="fx-btn-skeleton" @click="$emit('quickview', incident.id)"><i
        class="fas fa-eye"
      /></a>
      <drop-down v-if="linkToDetailsPage">
        <drop-down-item>
          <a :href="incidentUrl"><i class="far fa-exclamation-triangle fa-fw" /> Störung ansehen</a>
        </drop-down-item>
      </drop-down>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import { TruncateFormatter } from 'mixins/formatters';

import Labels from 'components/partials/labels.vue';
import DropDown from 'components/dropdown_menu/menu.vue';
import DropDownItem from 'components/dropdown_menu/menu_item.vue';

export default {
  name: 'IncidentListItem',
  components: {
    Labels,
    DropDown,
    DropDownItem,
  },
  mixins: [TruncateFormatter],
  props: {
    linkToDetailsPage: {
      type: Boolean,
      default: true,
    },
    incident: {
      type: Object,
      required: true,
    },
    // show related thing
    showThing: {
      type: Boolean,
      default: false,
    },
    // show links to details of context elements like related thing
    showContextLinks: {
      type: Boolean,
      default: false,
    },
    // show "eye" button to open quickview
    showQuickview: {
      type: Boolean,
      default: false,
    },
    // whether to show (and link to) customer, location and installation
    showCustomerLocationInstallation: {
      type: Boolean,
      default: true,
    },
    // base url to build links to incident details page, differs between portal and web
    incidentDetailsBaseUrl: {
      type: String,
      default: '/incidents',
    },
    // base url to build links to related jobs details page, differs between portal and web
    jobDetailsBaseUrl: {
      type: String,
      default: '/jobs',
    },
    maxNumberLength: {
      type: Number,
      default: 12,
    },
    maxNameLength: {
      type: Number,
      default: 30,
    },
  },
  computed: {
    assignedJobTooltip() {
      return {
        content: `In Zielauftrag eingeplant ${this.incident.assigned_job_date}`,
        placement: 'top',
        duration: 100,
        delay: [500, 0],
        arrow: true,
      };
    },
    assignedJobUrl() {
      return `${this.jobDetailsBaseUrl}/${this.incident.assigned_job_id}`;
    },
    incidentUrl() {
      return `${this.incidentDetailsBaseUrl}/${this.incident.id}`;
    },
    installationUrl() {
      return `/installations/${this.incident.installation.id}`;
    },
    customerPresent() {
      return (this.incident.customer && this.incident.customer.number);
    },
    customerUrl() {
      return `/customers/${this.incident.customer.id}`;
    },
    locationUrl() {
      return `/locations/${this.incident.installation.location_id}`;
    },
    statusDate() {
      return moment(this.incident.statusdate).format('DD.MM.YYYY');
    },
    statusDateTime() {
      // if (!this.statusdate.statusdate_time) { return }
      return `${moment(this.incident.statusdate).format('HH:mm')} Uhr`;
    },
    locationName() {
      const name = this.incident.installation.location_name;
      return name ? name.replace(/c\/o/g, '<br>c/o') : null;
    },
    thingLabel() {
      let name = ` ${this.incident.thing_type_name}`;
      if (this.incident.thing_sub_number > 0) {
        name = `.${this.incident.thing_sub_number}${name}`;
      }
      name = this.incident.thing_number + name;
      if (this.incident.thing_group_number != null && this.incident.thing_group_number >= 0) {
        name = `${this.incident.thing_group_number}.${name}`;
      }
      return name;
    },
    thingUrl() {
      return `/things/${this.incident.thing_id}`;
    },
  },
};
</script>

<style>
div.wip {
  margin-top: .2rem;
  display: inline-bock;
}
</style>
