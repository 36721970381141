<template>
  <div class="fx-modal-content-medium">
    <h2 v-if="labelPreset == null" class="fx">
      Neues Label hinzufügen
    </h2>
    <h2 v-else class="fx">
      Label bearbeiten
    </h2>

    <ServerErrors v-model="serverErrors" title="Label konnte nicht gespeichert werden" class="mb-4" />

    <div class="mb-2">
      <span class="fx-label-large" :style="styleForLabel(label)">{{ nameOrDefault }}</span>
    </div>

    <div class="mb-2">
      <label>Name</label>
      <br>
      <input
        id="label-name"
        v-model="label.name"
        type="text"
        placeholder="Name"
        style="margin-bottom: 0;"
      >
    </div>

    <div class="mb-2">
      <label>Beschreibung</label><br>
      <input
        id="label-description"
        v-model="label.description"
        type="text"
        placeholder="Beschreibung (optional)"
        style="margin-bottom: 0;"
      >
    </div>

    <div v-if="canChangeColor" class="mb-2">
      <label>Farbe</label><br>
      <ColorDropDown v-model="colorOption" text="Farbe" @selected="setColorFromOption" />
    </div>

    <div class="mb-2">
      <template v-if="showVisibilityControls">
        <input id="show-in-app-checkbox" v-model="label.show_in_app" type="checkbox"> <label
          class="inline"
          for="show-in-app-checkbox"
        ><i class="fas fa-mobile-alt" /> wird in der App angezeigt</label><br>
        <input id="show-in-portal-checkbox" v-model="label.show_in_portal" type="checkbox"> <label
          class="inline"
          for="show-in-portal-checkbox"
        ><i class="far fa-globe" /> wird im Auftraggeberportal angezeigt</label>
      </template>
      <template v-else>
          &nbsp;
      </template>
    </div>

  <hr class="fx">
    <div style="text-align:right;">
      <div class="fx-btn-group">
        <button
          class="fx-btn-primary float-right"
          style="margin-left: 0.5rem;"
          @click.prevent="save()"
        >
          Speichern
        </button>

        <button class="fx-btn-secondary float-right" @click.prevent="$emit('close')">
          Abbrechen
        </button>
      </div>
      <div class="clearfix" />
    </div>
</div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import Flash from 'flash';
import ColorDropDown from 'components/dropdown_menu/color_dropdown.vue';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'EditLabelModal',
  components: {
    ColorDropDown,
    ServerErrors,
  },
  props: {
    labelPreset: {
      type: Object,
      default: null,
    },
    labelType: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      default: 25,
    },
  },
  emits: ['created', 'updated', 'close'],
  data() {
    return {
      label: {
        id: null,
        name: null,
        description: null,
        text_color: '#ffffff',
        bg_color: '#0366d6',
        show_in_app: false,
        show_in_portal: false,
      },
      serverErrors: [],
    };
  },
  computed: {
    showVisibilityControls() {
      return (this.labelType !== 'thing' && this.labelType !== 'customer');
    },
    colorOption() {
      return { text_color: this.label.text_color, bg_color: this.label.bg_color };
    },
    nameOrDefault() {
      return (this.label.name == null) ? 'Label Vorschau' : this.label.name;
    },
    canChangeColor() {
      return (this.labelType !== 'thing');
    },
  },
  mounted() {
    if (this.labelPreset != null) {
      this.label = _.clone(this.labelPreset);
    }

    // --- thing labels have fixed color for now
    if (this.labelType === 'thing') {
      this.label.text_color = '#ffffff';
      this.label.bg_color = '#2c556e';
    }
  },
  methods: {
    styleForLabel(label) {
      return `color: ${label.text_color}; background-color: ${label.bg_color};`;
    },
    setColorFromOption(option) {
      this.label.bg_color = option.bg_color;
      this.label.text_color = option.text_color;
    },
    save() {
      if (this.label.id == null) {
        this.createViaApi(this.label);
      } else {
        this.updateViaApi(this.label);
      }
    },
    createViaApi(label) {
      this.serverErrors = [];
      const that = this;
      axios.post('/labels.json', {
        label: {
          label_type: this.labelType,
          name: label.name,
          description: label.description,
          text_color: label.text_color,
          bg_color: label.bg_color,
          show_in_app: label.show_in_app,
          show_in_portal: label.show_in_portal,
        },
      }).then((response) => {
        Flash.info('Label angelegt');
        that.$emit('created', response.data.label);
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.labels;
        } else {
          Flash.error('Label konnte nicht gespeichert werden');
        }
      });
    },
    updateViaApi(label) {
      this.serverErrors = [];
      const that = this;
      axios.put(`/labels/${label.id}.json`, {
        label: {
          name: label.name,
          description: label.description,
          text_color: label.text_color,
          bg_color: label.bg_color,
          show_in_app: label.show_in_app,
          show_in_portal: label.show_in_portal,
        },
      }).then((response) => {
        Flash.info('Label aktualisiert');
        that.$emit('updated', response.data.label);
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.labels;
        } else {
          Flash.error('Label konnte nicht gespeichert werden');
        }
      });
    },
  },
};
</script>

  <style>
  .dim_on_hover {
    transition: opacity .2s linear;
  }

  .dim_on_hover:hover {
    cursor: pointer;
    opacity: .75;
  }
</style>
