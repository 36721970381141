<template>
  <span class="input-with-icon" :class="iconClass" :style="style">
    <input
      v-model="query"
      :placeholder="placeholder"
      class="fulltext-search-input"
      @keyup="debouncedEmit"
    >
  </span>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'FilterFulltext',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'filter',
    },
    width: {
      type: String,
      default: '250px',
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  data() {
    return {
      query: '',
    };
  },
  computed: {
    iconClass() {
      let g = 'empty';
      if (this.query && this.query.length) {
        g = 'filled';
      }

      return `input-icon-${this.icon} input-with-icon-${g}`;
    },
    style() {
      return `width: ${this.width}; margin: 2px;`;
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (this.query !== newVal) {
          this.query = newVal;
        }
      },
    },
  },
  mounted() {
    this.query = this.modelValue;
  },
  methods: {
    // beware: do not use ES6 style "() => {}" here, it messes the this reference!
    // eslint-disable-next-line func-names
    debouncedEmit: _.debounce(function () {
      this.$emit('changed', this.query);
    }, 300),
  },
};
</script>
<style>
input.fulltext-search-input {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #555;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  min-height: 31px !important;
  padding: 1px 8px 1px 8px;
  position: relative;
  background-color: #fff;
}

input.fulltext-search-input::placeholder {
  color: #bbb;
  opacity: 1;
}

span.input-with-icon {
  border: 1px solid #c6c6c6;
  border-radius: 4px;
  color: #555;
  display: inline-block;
  font-size: 14px;
  min-height: 31px !important;
  padding: 0 8px 0 32px;
  position: relative;
  background-color: #fff;
}

span.input-with-icon:before {
  font-family: "Font Awesome 5 Pro" !important;
  font-style: normal !important;
  font-weight: 900 !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  position: absolute;
  top: 8px;
  left: 9px;
  color: #4C6E83;
}

span.input-with-icon-empty:before {
  color: #cccccc;
}

span.input-with-icon-filled:before {
  color: #4C6E83;
}

span.input-icon-filter:before {
  content: "\f0b0";
}

span.input-icon-filter {
  padding: 0 8px 0 30px;
}

span.input-icon-mapmarker:before {
  content: "\f041";
}

span.input-icon-mapmarker {
  padding: 0 8px 0 26px;
}

span.input-with-icon input {
  border: 0;
  width: 100%;
  min-height: 29px !important;
  padding-left: 0;
}

span.input-with-icon input::placeholder {
  color: #a0a0a0;
  opacity: 1;
}

span.input-with-icon input:focus {
  outline: none;
}
</style>
