<template>
  <div v-if="initialDataLoaded">
    <section class="header">
      <div class="fx-grid">
        <div class="fx-col" style="margin-bottom:0; padding-bottom:0;">
          <div v-if="portalRoles(localSharedInstallation).incidents" style="float: right;">
            <a :href="addIncidentUrl" class="fx-btn-primary">Störung melden</a>
          </div>
          <div v-if="portalRoles(localSharedInstallation).jobs" style="float: right; margin-right: 2rem;">
            <strong>Nächster Termin</strong>
            <template v-if="localSharedInstallation.installation.next_job_planned_at">
              <a class="no-color" :href="sharedInstallationJobPath(localSharedInstallation.installation.next_job_id)">
                <NextJob
                  :with-link="false"
                  :with-icon="false"
                  :installation-id="localSharedInstallation.installation.id"
                  :job-planned-at="localSharedInstallation.installation.next_job_planned_at"
                  :job-type-name="localSharedInstallation.installation.next_job_type_name"
                />
              </a>
            </template>
          </div>
          <div v-if="portalRoles(localSharedInstallation).dueDates" style="float: right; margin-right: 2rem;">
            <strong>Fälligkeiten</strong>
            <NextCheckInfo
              v-if="localSharedInstallation.installation.next_check_at"
              :next-date="localSharedInstallation.installation.next_check_at"
              :next-status="localSharedInstallation.installation.next_check_status"
            />
            <NextCheckInfo
              v-if="localSharedInstallation.installation.next_replacement_at"
              :next-date="localSharedInstallation.installation.next_replacement_at"
              :next-status="localSharedInstallation.installation.next_replacement_status"
              :is-replacement="true"
            />
          </div>

          <ul class="fx-breadcrumps">
            <li><a href="/portal/locations">Anlagen</a></li>
            <li>{{ locationBreadcrumpLabel }}</li>
            <li>{{ localSharedInstallation.installation.installation_type_name }}</li>
          </ul>

          <div class="header-title">
            <h1 class="header-title">
              <span class="header-title_number">{{ localSharedInstallation.installation.installation_type_name }}</span>
              <span class="header-title_name">{{ localSharedInstallation.installation.location_active_name }}</span>
            </h1>
          </div>

          <button :class="tabClasses('overview')" @click="setActiveTab('overview')">
            <i class="fas fa-sitemap" /> Anlage
          </button>
          <button v-if="portalRoles(localSharedInstallation).inventory" :class="tabClasses('inventory')" @click="setActiveTab('inventory')">
            <i class="far fa-cube" /> Komponenten <span id="things-count" class="tab-count">{{ localSharedInstallation.installation.things_count }}</span>
          </button>
          <button v-if="portalRoles(localSharedInstallation).jobs" :class="tabClasses('jobs')" @click="setActiveTab('jobs')">
            <i class="far fa-calendar-check" /> Aufträge <span id="jobs-count" class="tab-count">{{ localSharedInstallation.installation.jobs_count }}</span>
          </button>
          <button v-if="portalRoles(localSharedInstallation).incidents" :class="tabClasses('incidents')" @click="setActiveTab('incidents')">
            <i class="fas fa-exclamation-triangle" />
            Störungen <span id="notes-count" class="tab-count">{{ localSharedInstallation.installation.open_incidents_count }}</span>
          </button>
        </div>
      </div>
    </section>

    <section class="list-content">
      <div class="fx-grid">
        <div class="fx-col-notop" style="margin-bottom:0; padding-bottom:0;">
          <div v-if="isTabInitialized('overview')" v-show="isTabActive('overview')" class="tab-panel">
            <div class="fx-card">
              <div class="fx-card-content">
                <Labels :labels="localSharedInstallation.labels" />
              </div>
              <table class="material top-align">
                <tr>
                  <th style="width: 200px;">
                    Service-Unternehmen
                  </th>
                  <td colspan="2">
                    {{ localSharedInstallation.organization_name }}
                  </td>
                </tr>
                <tr>
                  <th>Kundennummer</th>
                  <td colspan="2">
                    {{ localSharedInstallation.customer_number }}
                  </td>
                </tr>
                <tr>
                  <th>Objektnummer</th>
                  <td colspan="2">
                    {{ localSharedInstallation.location_number }}
                  </td>
                </tr>
                <tr>
                  <th>Anlagennummer</th>
                  <td colspan="2">
                    {{ localSharedInstallation.installation.number }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div v-if="isTabInitialized('inventory')" v-show="isTabActive('inventory')" class="tab-panel">
            <Inventory
              :installation-id="localSharedInstallation.installation.id"
              :show-due-dates="portalRoles(localSharedInstallation).dueDates"
              :installation-type="localSharedInstallation.installation_type"
              :start-empty-state="localSharedInstallation.installation.things_count == 0"
              :show-add-incident-buttons="portalRoles(localSharedInstallation).incidents"
            />
          </div>

          <div v-if="isTabInitialized('jobs')" v-show="isTabActive('jobs')" class="tab-panel">
            <JobList :shared-installation-id="localSharedInstallation.id" :job-id="jobId" />
          </div>

          <div v-if="isTabInitialized('incidents')" v-show="isTabActive('incidents')" class="tab-panel">
            <IncidentEmbeddedList
              :installation-id="localSharedInstallation.installation.id"
              :show-links="false"
              :show-quickview="false"
              incidents-url="/portal/incidents/filtered"
              job-details-url="/portal/jobs"
              incident-details-url="/portal/incidents"
              labels-select-url="/portal/labels/for_select?label_type=incident"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios';

import FeatureFlipper from 'mixins/feature_flipper';
import PortalRoles from 'mixins/portal_roles';

import { useCurrentUserStore } from 'stores/current_user';

import NextCheckInfo from 'components/partials/next_check_info.vue';
import NextJob from 'components/partials/next_job.vue';
import Labels from 'components/partials/labels.vue';
import IncidentEmbeddedList from '../../incidents/embedded/index.vue';
import JobList from './job_list.vue';
import Inventory from './inventory.vue';

export default {
  name: 'PortalInstallation',
  components: {
    IncidentEmbeddedList,
    JobList,
    Inventory,
    NextCheckInfo,
    NextJob,
    Labels,
  },
  mixins: [FeatureFlipper, PortalRoles],
  props: {
    sharedInstallationId: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      initialDataLoaded: false,
      localSharedInstallation: null,
      activeTab: null,
      initializedTabs: new Set(),
    };
  },
  computed: {
    locationBreadcrumpLabel() {
      if (this.localSharedInstallation.location_number) {
        return this.localSharedInstallation.location_number;
      }
      return this.localSharedInstallation.installation.location_active_name;
    },
    /* roleJobs() {
      return this.localSharedInstallation && this.localSharedInstallation.roles.includes('jobs');
    },
    roleIncidents() {
      return this.localSharedInstallation && this.localSharedInstallation.roles.includes('incidents');
    },
    roleDueDates() {
      return this.localSharedInstallation && this.localSharedInstallation.roles.includes('due_dates');
    },
    roleInventory() {
      return this.localSharedInstallation && this.localSharedInstallation.roles.includes('inventory');
    }, */
    addIncidentUrl() {
      return `/portal/incidents/new/?installation_id=${this.localSharedInstallation.installation.id}`;
    },
  },
  mounted() {
    this.loadSharedInstallation();
    const hash = window.location.hash.substr(1);
    if (hash) {
      this.setActiveTab(hash);
    } else if (this.jobId) {
      this.setActiveTab('jobs');
    } else {
      this.setActiveTab('overview');
    }
  },
  methods: {
    sharedInstallationJobPath(jobId) {
      return `/portal/shared_installations/${this.localSharedInstallation.id}?job=${jobId}`;
    },
    tabClasses(tab) {
      if (tab == this.activeTab) {
        return 'fx-tab fx-tab__active';
      }
      return 'fx-tab';
    },
    isTabInitialized(tab) {
      return this.initializedTabs.has(tab);
    },
    isTabActive(tab) {
      return (this.activeTab == tab);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
      this.initializedTabs.add(tab);
    },
    loadSharedInstallation() {
      const that = this;
      axios.get(`/portal/shared_installations/${this.sharedInstallationId}.json`, {
      }).then((response) => {
        that.localSharedInstallation = response.data;
        that.initialDataLoaded = true;
      }).catch((err) => {
        console.log(err);
      });
    },
  },
};
</script>

<style scoped>
div.tab-panel {
  margin-top: 1rem;
}
</style>
