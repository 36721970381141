<template>
  <div v-if="showGroup" class="fx-card group-header">
    <div class="fx-card-content material-card__content--header">
      <div v-if="jobThingsScope === 'custom_things'" class="fx-btn-group" style="float: right;">
        <a class="fx-btn-skeleton" @click.prevent="planGroup()">Alle einplanen</a>
        <a class="fx-btn-skeleton" @click.prevent="unplanGroup()">Alle ausplanen</a>
      </div>
      <span style="cursor: pointer;" @click="toggleShowThings">
        <i
          v-if="showThings"
          class="fas fa-fw fa-chevron-down group-header-status-icon no-print"
          style="display: inline-block;"
        />
        <i
          v-else
          class="fas fa-fw fa-chevron-right group-header-status-icon no-print"
          style="display: inline-block;"
        />
        <h2 class="fx group-header-header" style="font-size: 1rem; margin: 0">
          <span v-if="!isDefaultGroup()">
            <span style="font-weight: 400;">{{ groupLabel }}</span>&nbsp;
            <span style="font-weight: 400;">{{ group.number }}</span>
            <span style="margin-left: 0.5rem">{{ group.name }}</span>
          </span>
          <span v-else>
            <span>{{ defaultGroupLabel }}</span>
          </span>
          <span class="things-count" style="margin-left: 0.3rem;">{{ count }}</span>

        </h2>
      </span>
    </div>
    <table v-if="showThings" class="material" style="table-layout:fixed;width:100%;">
      <colgroup>
        <col style="width: 8%">
        <col style="width: 17%">
        <col style="width: 18%">
        <col>
        <col>
        <col style="width: 110px;">
      </colgroup>
      <tr v-if="!loading && thingsCount > 0" class="material__title">
        <th>{{ $t('activerecord.attributes.thing.number') }}</th>
        <th>{{ $t('activerecord.attributes.thing.thing_type') }}</th>
        <th>Details</th>
        <th>{{ $t('activerecord.attributes.thing.assigned_features') }}</th>
        <th v-if="showDueDates">
          Fälligkeiten
        </th>
        <th class="no-print" style="ttext-align: right;">
          Eingeplant?
        </th>
      </tr>
      <SingleThingPlanning
        v-for="thing in sortedLocalThings"
        :key="thing.id"
        :thing="thing"
        :installation-id="installationId"
        :show-due-dates="showDueDates"
        :job-things-scope="jobThingsScope"
        :planned="thingIsPlanned(thing)"
        :group-separator="groupSeparator"
        :tag-label="tagLabel"
        :job-id="jobId"
        @changed-planned-count="onChangedPlannedCount"
      />
      <tr v-if="loading">
        <td colspan="6" style="text-align: left;">
          loading ...
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
/* eslint-disable no-undef */
import _ from 'lodash';
import axios from 'axios';

import SingleThingPlanning from './single_thing_planning.vue';

export default {
  name: 'SingleGroupPlanning',
  components: {
    SingleThingPlanning,
  },
  props: {
    installationId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
    groupLabel: {
      type: String,
      default: 'Gruppe',
    },
    thingLabel: {
      type: String,
      default: 'Komponente',
    },
    groupSeparator: {
      type: String,
      default: '/',
    },
    tagLabel: {
      type: String,
      default: 'Foxtag-ID',
    },
    hasCentralUnit: {
      type: Boolean,
      default: false,
    },
    startCollapsed: {
      type: Boolean,
      default: false,
    },
    showDueDates: {
      type: Boolean,
      default: true,
    },
    showPortalAddIncidentButtons: {
      type: Boolean,
      default: false,
    },
    jobThingsScope: {
      type: String,
      default: 'all_things',
    },
    jobId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      page: 0,
      limit: 1500,
      localThings: [],
      localPlannedThingIds: [],
      count: 0,
      loaded: false,
      showThings: false,
      loading: false,
    };
  },
  computed: {
    isCentralUnit() {
      return (this.isDefaultGroup() && this.hasCentralUnit);
    },
    defaultGroupLabel() {
      return (this.hasCentralUnit) ? 'Zentralen' : 'Komponenten';
    },
    showGroup() {
      return true; // !(this.isDefaultGroup() && this.thingsCount === 0);
    },
    thingsCount() {
      if (this.localThings.length > 0) {
        return this.localThings.length;
      }
      return this.group.things_count;
    },
    sortedLocalThings() {
      return _.sortBy(this.localThings, (thing) => thing.deployment.number * 10000 + thing.deployment.sub_number);
    },
    showNoResults() {
      return (this.loaded && (this.localThings.length === 0));
    },
  },
  mounted() {
    this.updateCount();

    this.showThings = !this.startCollapsed;
    if (this.showThings) {
      if (this.jobThingsScope === 'all_things') {
        this.loadThings();
      } else {
        this.loadPlannedThingIdsAndThings();
      }
    }
  },
  methods: {
    thingIsPlanned(thing) {
      return this.localPlannedThingIds.includes(thing.id);
    },
    isDefaultGroup() {
      return (this.group.number < 0);
    },
    toggleShowThings() {
      this.showThings = !this.showThings;
      if (this.showThings) {
        if (this.jobThingsScope === 'all_things') {
          this.loadThings();
        } else {
          this.loadPlannedThingIdsAndThings();
        }
      }
    },
    loadThings() {
      this.localThings = [];
      this.loading = true;
      axios.get(`/installations/${this.installationId}/deployments.json`, {
        params: {
          group_id: this.group.id,
          offset: this.limit * this.page,
          limit: this.limit,
        },
      }).then((response) => {
        this.loading = false;
        this.localThings = this.localThings.concat(response.data.installation_deployments);
      }).catch(() => {
        this.loading = false;
      });
    },
    loadPlannedThingIdsAndThings() {
      const that = this;
      this.localPlannedThingIds = [];
      axios.get(`/jobs/${this.jobId}/job_thing_ids`, {
        params: {
          group_id: this.group.id,
        },
      }).then((response) => {
        that.localPlannedThingIds = response.data;
        that.loadThings();
      }).catch(() => {
        Flash.error('Fehler beim Laden der Planungsdaten');
      });
    },
    findThingIndexInList(thingId) {
      return _.findIndex(this.localThings, { id: thingId });
    },
    updateCount() {
      if (this.showThings) {
        this.count = this.localThings.length;
      } else {
        this.count = this.group.things_count; // init count with value from group
      }
    },
    planGroup() {
      const that = this;

      axios.put(`/jobs/${this.jobId}/plan_group`, {
        group_id: this.group.id,
      }).then((result) => {
        that.localPlannedThingIds = result.data;
        this.$emit('changedPlannedCount', null);
        that.loadThings();
      }).catch(() => {
        Flash.error('Fehler beim Speichern');
      });
    },
    unplanGroup() {
      const that = this;

      axios.put(`/jobs/${this.jobId}/unplan_group`, {
        group_id: this.group.id,
      }).then(() => {
        that.localPlannedThingIds = [];
        this.$emit('changedPlannedCount', null); // passing null triggers loading of counts
        that.loadThings();
      }).catch(() => {
        Flash.error('Fehler beim Speichern');
      });
    },
    onChangedPlannedCount(count) {
      this.$emit('changedPlannedCount', count);
    },
  },
};
</script>

<style>
.borderless {
  font-weight: normal;
  padding: 0.5rem 0.5rem;
  border: 1px solid transparent;
  background-color: transparent;
  color: #256FC5;
}

span.things-count {
  border-radius: 0.8rem;
  background-color: #f6f6f6;
  color: #222;
  padding: 0.2rem 0.4rem;
  font-weight: 600;
  font-size: 0.825rem;
}
</style>
