<template>
  <span>
    <OnClickOutside style="display: inline-block;" @trigger="onClickOutside">
      <div style="display: inline-block; position: relative;">
        <div
          class="fx-btn-primary"
          :style="selectedStyle"
          @click.prevent="show = !show"
        >
          {{ text }}
          <i class="fas fa-caret-down" style="margin-left: .3rem;" />
        </div>
        <div v-if="show" class="dropdown-menu-wrapper my">
          <div v-for="option in colorOptions" :key="option.background" style="width: 168px;">
            <button
              class="dim_on_hover"
              style="margin: 2px; width: 50px; padding: 7px; text-align: left;"
              :style="styleForLabel(option[0])"
              @click="setColorFromOption(option[0])"
            >Label</button><button
              class="dim_on_hover"
              style="margin: 2px; width: 50px; padding: 7px; text-align: left;"
              :style="styleForLabel(option[1])"
              @click="setColorFromOption(option[1])"
            >Label</button><button
              class="dim_on_hover"
              style="margin: 2px; width: 50px; padding: 7px; text-align: left;"
              :style="styleForLabel(option[2])"
              @click="setColorFromOption(option[2])"
            >Label</button>
          </div>
        </div>
      </div>
    </OnClickOutside>
  </span>
</template>

<script>
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'ColorDropdown',
  components: {
    OnClickOutside,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      show: false,
      selected: { text_color: '#ffffff', bg_color: '#0366d6' },
      colorOptions: [
        [
          { text_color: '#ffffff', bg_color: '#2E566D' },
          { text_color: '#ffffff', bg_color: '#0366d6' },
          { text_color: '#000000', bg_color: '#cccccc' },
        ],
        [
          { text_color: '#ffffff', bg_color: '#5319e7' },
          { text_color: '#ffffff', bg_color: '#666666' },
          { text_color: '#000000', bg_color: '#A7D2FF' },
        ],
        [
          { text_color: '#ffffff', bg_color: '#0e8a16' },
          { text_color: '#ffffff', bg_color: '#ce2d2d' },
          { text_color: '#000000', bg_color: '#D4C5F9' },
        ],
        [
          { text_color: '#000000', bg_color: '#e5abf2' },
          { text_color: '#000000', bg_color: '#fffa82' },
          { text_color: '#000000', bg_color: '#FFD59C' },
        ],
        [
          { text_color: '#000000', bg_color: '#fbca04' },
          { text_color: '#000000', bg_color: '#c5e064' },
          { text_color: '#000000', bg_color: '#FF6666' },
        ],
        [
          { text_color: '#000000', bg_color: '#ffaabb' },
          { text_color: '#000000', bg_color: '#56f7d7' },
          { text_color: '#ffffff', bg_color: '#CC317C' },
        ],
      ],
    };
  },
  computed: {
    selectedStyle() {
      return `color: ${this.selected.text_color}; background-color: ${this.selected.bg_color}; box-shadow: none;`;
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (newVal != null) {
          this.selected = newVal;
        }
      },
      deep: true,
    },
  },
  methods: {
    styleForLabel(label) {
      return `color: ${label.text_color}; background-color: ${label.bg_color};`;
    },
    onClickOutside() {
      if (this.show) {
        this.show = false;
      }
    },
    setColorFromOption(option) {
      this.selected = option;
      this.$emit('selected', option);
      this.show = false;
    },
  },
};
</script>

<style>
.my {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  padding: 0;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #FAFAFA;
}
</style>
