<template>
  <div style="margin-top: 80px;">
    <div style="margin: 1rem;">
      <h1 class="fx">
        {{ importTypeName }} importieren
      </h1>
      <p>
        Bitte wählen Sie die Art des gewünschten Imports. <help-article article-id="import_modes">
          Hilfe bei der
          Auswahl
        </help-article>
      </p>
      <div style="display: flex; gap: 20px;">
        <div :class="insertModeClass" @click="setImportMode('insert')">
          <h3 class="fx">
            <i :class="insertModeIconClass" /> Neue {{ importTypeName }} hinzufügen
          </h3>
          Zum Hinzufügen von neuen Datensätzen, die es noch nicht in Ihrem Foxtag-Account gibt. Bestehende
          Datensätze werden nicht aktualisiert.
        </div>
        <div :class="updateModeClass" @click="setImportMode('update')">
          <h3 class="fx">
            <i :class="updateModeIconClass" /> Vorhandene {{ importTypeName }} bearbeiten
          </h3>
          Zum gezielten Aktualisieren von Daten, die bereits in Ihrem Foxtag-Account vorhanden sind.
        </div>
        <div :class="upsertModeClass" @click="setImportMode('upsert')">
          <h3 class="fx">
            <i :class="upsertModeIconClass" /> {{ importTypeName }} abgleichen
          </h3>
          Zum Abgleich einer Liste von Daten, bei dem neue Datensätze hinzugefügt und bestehende Datensätze
          aktualisiert werden.
        </div>
      </div>
    </div>

    <div v-if="colsCount() == 0" style="padding: 1rem; color: red;">
      Keine Daten in der Datei gefunden. Bitte
      überprüfen
      Sie die Datei
    </div>

    <div style="margin: 1rem;">
      <h2 class="fx">
        Feldzuordnung
      </h2>
      <p>
        Bitte ordnen Sie die Spalten Ihrer Datei den Feldern der Foxtag-Datenbank zu.
        Spalten, die nicht importiert werden sollen, lassen Sie einfach ohne Zuordnung.
        <help-article article-id="import_mapping">
          Hilfe bei der Feldzuordnung
        </help-article>
      </p>

      <div style="background: white; border: 1px solid #eee; padding: 1rem;">
        <span style="margin-right: 2rem;">Die erste Zeile der Datei enthält:</span>

        <input
          id="import_first_row_contains_header_true"
          v-model="firstRowIsHeader"
          type="radio"
          :value="true"
          style="margin: 0;"
        >
        <label for="import_first_row_contains_header_true">Überschriften</label>
        <input
          id="import_first_row_contains_header_false"
          v-model="firstRowIsHeader"
          type="radio"
          :value="false"
          style="margin: 0;"
        >
        <label for="import_first_row_contains_header_false">Daten</label>
      </div>
    </div>
    <div style="width: 100%; overflow: auto; padding: 1rem; border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;">
      <span class="small secondary">Beispielzeilen aus <i>{{ filename }}</i></span>
      <table class="import-datatable" :style="styleColumns">
        <thead>
          <tr v-if="firstRowIsHeader">
            <th v-for="(colContent,index) in rows[0]" :key="`header-${index}`">
              {{ colHeader(colContent,
                           index) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <!-- data rows -->

          <tr v-for="(row, rowIndex) in rows" :key="`row-${rowIndex}`" class="import-datatable">
            <template v-if="rowIndex > 0 || !firstRowIsHeader">
              <td
                v-for="(col, colIndex) in row"
                :key="`col-${colIndex}`"
                class="import-datatable"
                :title="col"
              >
                {{ col }}
              </td>
            </template>
          </tr>
          <!-- field mapping controls -->
          <tr style="border:0;">
            <td
              v-for="(colContent, colIndex) in rows[0]"
              :key="`colcontent-${colIndex}`"
              style="background: transparent; text-align: center; padding:0; border: 0;"
            >
              <i v-if="colIndex > 0" class="fas fa-caret-down fa-4x" :class="classForArrow(colIndex)" />
            </td>

            <td
              v-for="(colContent, rowIndex) in rows[0]"
              :key="`rowcontent-${rowIndex}`"
              style="background: transparent; border: 0;"
            >
              <div v-if="rowIndex === 0" style="width: 100%; text-align: right; font-weight: bold; padding-top: 7px;">
                Feldzuordnung:
              </div>
              <select
                v-if="rowIndex > 0"
                v-model="selectedMappings[rowIndex -1]"
                style="padding-left: 7px;font-size: .875rem; margin: 0;"
              >
                <option value="" />
                <option
                  v-for="(optionValue, index) in availableMappingOptions[rowIndex -1]"
                  :key="`opt-${index}`"
                  :disabled="optionDisabled(rowIndex -1, optionValue)"
                  :value="optionValue"
                >
                  {{
                    optionLabel(optionValue)
                  }}
                </option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="font-style: italic; margin-left: .2rem;" class="secondary small">
        Mit * markierte Felder in der Feldzuordnung müssen zugeordnet werden.
      </div>
    </div>
    <div style="margin: 1rem;">
      <ServerErrors v-model="serverErrors" title="Import konnte nicht gestartet werden" margin-bottom="1rem" />
      <div style="background: white; border: 1px solid #eee; padding: 1rem;">
        <div class="fx-btn-group" style="margin-top: 1rem;">
          <button class="fx-btn-primary" @click="updateMappingViaApi()">
            Import starten
          </button>
          <a :href="importPageUrl" class="fx-btn-secondary">Abbrechen</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { indexOf } from 'lodash';
import axios from 'axios';
import ServerErrors from 'components/partials/server_errors.vue';

export default {
  name: 'ImportMapping',
  components: {
    ServerErrors,
  },
  props: {
    sampleDataset: {
      type: Array,
      required: true,
    },
    mappableFields: {
      type: Object,
      required: true,
    },
    customFieldLabels: {
      type: Object,
      required: true,
    },
    importType: {
      type: String,
      required: true,
    },
    importTypeName: {
      type: String,
      required: true,
    },
    importName: {
      type: String,
      required: true,
    },
    importModes: {
      type: Array,
      required: true,
    },
    importId: {
      type: String,
      required: true,
    },
    installationTypeId: {
      type: String,
      required: true,
    },
    installationId: {
      type: String,
      required: true,
    },
    jobId: {
      type: String,
      required: true,
    },
    filename: {
      type: String,
      required: true,
    },
    canUpdateRecords: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      importMode: 'insert',
      selectedMappings: [],
      firstRowIsHeader: true,
      rows: [[]],
      availableMappingOptions: [],
      serverErrors: [],
    };
  },
  computed: {
    styleColumns() {
      return `grid-template-columns: minmax(100px, 1fr) repeat(${this.colsCount() - 1}, minmax(190px, 3fr));`;
    },
    importPageUrl() {
      let urlParams = '';
      if (this.installationTypeId) {
        urlParams = `?installation_type=${this.installationTypeId}`;
      }
      if (this.installationId) {
        urlParams = `?installation=${this.installationId}`;
      }
      if (this.jobId) {
        urlParams = `?job=${this.jobId}`;
      }
      return `/imports/${this.importType}/new/${urlParams}`;
    },
    insertModeClass() {
      if (this.importMode === 'insert') {
        return 'fx-big-button fx-big-button-active';
      }

      if (this.importModes.indexOf('insert') === -1) {
        return 'fx-big-button fx-big-button-disabled';
      }
      return 'fx-big-button';
    },
    updateModeClass() {
      if (this.importMode === 'update') {
        return 'fx-big-button fx-big-button-active';
      }

      if (this.importModes.indexOf('update') === -1) {
        return 'fx-big-button fx-big-button-disabled';
      }
      return 'fx-big-button';
    },
    upsertModeClass() {
      if (this.importMode === 'upsert') {
        return 'fx-big-button fx-big-button-active';
      }

      if (this.importModes.indexOf('upsert') === -1) {
        return 'fx-big-button fx-big-button-disabled';
      }
      return 'fx-big-button';
    },
    insertModeIconClass() {
      if (this.importMode === 'insert') {
        return 'fas fa-dot-circle';
      }
      return 'far fa-circle';
    },
    updateModeIconClass() {
      if (this.importMode === 'update') {
        return 'fas fa-dot-circle';
      }
      return 'far fa-circle';
    },
    upsertModeIconClass() {
      if (this.importMode === 'upsert') {
        return 'fas fa-dot-circle';
      }
      return 'far fa-circle';
    },
  },
  mounted() {
    this.rows = this.sampleDataset;
    this.availableMappingOptions = Array(this.colsCount());

    // --- this is important: the array must not contain undefined values, since undefined is removed from the array
    // --- when sending the array to the server, leading to position changes and mapping errors (this is why it gets filled with blanks)
    this.selectedMappings = Array(this.colsCount()).fill('');

    for (let i = 0; i < this.availableMappingOptions.length; i += 1) {
      this.availableMappingOptions[i] = Object.keys(this.mappableFields);
    }
    this.automapFields();
  },
  methods: {
    setImportMode(mode) {
      if (this.importModes.indexOf(mode) !== -1) {
        this.importMode = mode;
      }
    },
    colHeader(colContent, index) {
      if (index === 0) {
        return 'Zeile';
      }
      if (this.firstRowIsHeader) {
        return colContent;
      }
      return '';
    },
    classForArrow(index) {
      if (this.selectedMappings[index - 1]) {
        return 'highlight';
      }
      return 'passive';
    },
    colsCount() {
      if ((this.rows === undefined) || (this.rows[0] == null)) {
        return 0;
      }
      return this.rows[0].length || 0;
    },
    optionDisabled(index, option) {
      for (let i = 0; i < this.selectedMappings.length; i += 1) {
        if ((i !== index) && (this.selectedMappings[i] === option)) {
          return true;
        }
      }
      return false;
    },
    optionLabel(optionValue) {
      const cu = this.customFieldLabels[optionValue];
      if (cu != null) { return cu; }

      const label = this.$t(`import.mappings.${this.importType}.${optionValue}`);
      let mandatory = '';

      if (this.importMode === 'update') {
        if (optionValue === 'number') {
          mandatory = '*';
        }
      } else if (this.mappableFields[optionValue].required === true) {
        mandatory = '*';
      }

      return `${label}${mandatory}`;
    },
    automapFields() {
      const keys = Object.keys(this.mappableFields);
      const taken = [];

      for (let colIndex = 0; colIndex < this.rows[0].length; colIndex += 1) {
        const colTitle = String(this.rows[0][colIndex]).replace(/\s/g, '').replace(/\./g, '').replace(/\*/g, '')
          .replace(/_/g, '');

        for (let i = 0; i < keys.length; i += 1) {
          if (indexOf(taken, keys[i]) === -1) {
            const aliases = this.mappableFields[keys[i]].aliases;
            for (let ii = 0; ii < aliases.length; ii += 1) {
              if (colTitle.toUpperCase() === aliases[ii].toUpperCase()) {
                this.selectedMappings[colIndex - 1] = keys[i];
                taken.push(keys[i]);
              }
            }
          }
        }
      }
    },
    updateMappingViaApi() {
      const that = this;
      // this.saving = true;
      this.serverErrors = [];
      axios.patch(`/imports/${this.importType}/${this.importId}/mapping`, {
        import: {
          first_row_contains_header: this.firstRowIsHeader,
          import_mode: this.importMode,
          mapping: this.selectedMappings,
        },
        installation: this.installationId,
        job: this.jobId,
      }).then(() => {
        window.location.href = this.importPageUrl;
      }).catch((error) => {
        if (error.response.data.imports) {
          that.serverErrors = error.response.data.imports;
        } else {
          that.serverErrors = ['Unbekannter Fehler'];
        }
        // that.saving = false;
      });
    },
  },
};
</script>
<style scoped>
table.import-datatable {
  display: grid;
  border-collapse: collapse;
  min-width: 100%;
  border-top: 1px solid #ddd;
}

.highlight {
  color: #2C556E;
}

.passive {
  color: #ddd;
}

table.import-datatable thead,
tbody,
tr {
  display: contents;
  background: white;
}

table.import-datatable th,
td {
  padding: 7px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: .8rem;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  background: white;
}

table.import-datatable th {
  position: sticky;
  top: 0;
  background: rgb(100, 136, 158);
  text-align: left;
  font-weight: normal;
  color: white;
  position: relative;
}

.resize-handle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: black;
  opacity: 0;
  width: 3px;
  cursor: col-resize;
}

.resize-handle:hover,
/* The following selector is needed so the handle is visible during resize even if the mouse
  isn't over the handle anymore */
.header--being-resized .resize-handle {
  opacity: 0.5;
}

th:hover .resize-handle {
  opacity: 0.3;
}

table.import-datatable td {
  color: black;
}

table.import-datatable td:first-child {
  background-color: #eee;
  border-left: 1px solid #ddd;
  color: #666;
}

table.import-datatable tr:nth-child(even) td {
  /*background: #eee;*/

}

input[type="radio"]:disabled+label {
  color: #aaa;
}

div.fx-big-button {
  border: 1px solid #cfcfcf;
  height: 200px;
  flex: 1;
  max-width: 350px;
  padding: 15px;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

div.fx-big-button-active {
  border: 1px solid #256FC5;
  background: #f0f0f0;
}

div.fx-big-button-disabled {
  color: #888;
  cursor: not-allowed;
  box-shadow: none;
}

div.fx-big-button-disabled:hover {
  border: 1px solid #cfcfcf !important;
}

div.fx-big-button-disabled h3 {
  color: #888;
}

div.fx-big-button:hover {
  border: 1px solid #256FC5;
}
</style>
