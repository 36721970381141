<template>
  <div>
    <NavBreadcrumps path="administration/integrations/api-keys" />
    <h1 class="fx">
      {{ $t('organizations.api.index.title') }}
    </h1>
    <p>
      API-Keys sind Zugangsdaten für die Foxtag-API, die benötigt werden, wenn eine anderes System Daten mit Foxtag austauschen soll.
      Nutzen Sie jeweils eigene API-Keys für unterschiedliche Anwendungen und schränken Sie die Rechte so weit
      wie möglich ein.
      <help-article article-id="getting_started_api">
        Erste Schritte mit der API
      </help-article>
    </p>

  <ProFeatureCallout v-if="!currentUser.pro">
    <strong>Die API ist in Ihrem Leistungsumfang nicht enthalten.</strong>
    <br>
    Wechseln Sie jetzt auf Foxtag Pro, um die API zu nutzen!
  </ProFeatureCallout>

  <div class="fx-card">
    <div class="fx-card-content">
      <h2 class="fx">
        API-Keys
      </h2>
    </div>
    <table v-if="localPublicApiKeys.length" class="material">
      <tr class="material__title">
        <th style="width: 90px;">
          Prefix
        </th>
        <th style="width: 150px;">
          Name
        </th>
        <th>Berechtigungen</th>
        <th style="width: 150px;">
          Letzter Zugriff
        </th>
        <th style="width: 150px;">
&nbsp;
        </th>
      </tr>
      <tr v-for="(key, index) in localPublicApiKeys" :key="index">
        <td><code>{{ key.prefix }}</code></td>
        <td>{{ key.name }}</td>
        <td>{{ key.human_roles }}</td>
        <td>{{ humanDateTime(key.last_access_at) }}</td>
        <td class="text-right">
          <ButtonDeleteIcon @click="openConfirmDeleteKeyModal(key)" />
        </td>
      </tr>
    </table>
    <div class="fx-card-content">
      <button
        v-if="!showingAddKey && !showingResultingKey && localPublicApiKeys.length < 30"
        class="fx-btn-primary"
        :disabled="!currentUser.pro"
        @click="showAddKey()"
      >
        Neuen API-Key anlegen
      </button>

      <template v-if="showingAddKey">
        <div style="border-radius: 5px; padding: 1rem; margin-top: 1rem; background-color: #f6f6f6;">
          <h3 class="fx" style="margin-bottom: 1rem;">
            Neuen API-Key anlegen
          </h3>

          <ServerErrors v-model="serverErrors" margin-bottom="1rem;" />

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline mandatory">Name</label>
            </div>
            <div class="fx-form-content">
              <input v-model="localNewKey.name" type="text">
              <div class="form-field-hint">
                Geben Sie ein wofür der API-Key verwendet wird. Der Name hilft Ihnen beim
                Unterscheiden Ihrer API-Keys
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-content local-role">
              <label class="inline mandatory">Zugriffsrechte</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Artikel</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="article-read2" v-model="cbArticlesRead" type="checkbox">
              <label for="article-read2">lesen</label>

              <input id="article-write2" v-model="cbArticlesWrite" type="checkbox">
              <label for="article-write2">schreiben</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Labels</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="labels-read" v-model="cbLabelsRead" type="checkbox">
              <label for="labels-read">lesen</label>

              <input id="labels-write" v-model="cbLabelsWrite" type="checkbox">
              <label for="labels-write">schreiben</label>

              <input id="labels-delete" v-model="cbLabelsDelete" type="checkbox">
              <label for="labels-delete">löschen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Kunden</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="customers-read" v-model="cbCustomersRead" type="checkbox">
              <label for="customers-read">lesen</label>

              <input id="customers-write" v-model="cbCustomersWrite" type="checkbox">
              <label for="customers-write">schreiben</label>

              <input id="customers-delete" v-model="cbCustomersDelete" type="checkbox">
              <label for="customers-delete">löschen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Objekte</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="location-read2" v-model="cbLocationsRead" type="checkbox">
              <label for="location-read2">lesen</label>

              <input id="location-write2" v-model="cbLocationsWrite" type="checkbox">
              <label for="location-write2">schreiben</label>

              <input id="locations-delete" v-model="cbLocationsDelete" type="checkbox">
              <label for="locations-delete">löschen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Anlagen</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="installation-read2" v-model="cbInstallationsRead" type="checkbox">
              <label for="installation-read2">lesen</label>

              <input id="installation-write2" v-model="cbInstallationsWrite" type="checkbox">
              <label for="installation-write2">schreiben</label>

              <input id="installation-delete" v-model="cbInstallationsDelete" type="checkbox">
              <label for="installation-delete">löschen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Kontakte</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="contact-read2" v-model="cbContactsRead" type="checkbox">
              <label for="contact-read2">lesen</label>

              <input id="contact-write2" v-model="cbContactsWrite" type="checkbox">
              <label for="contact-write2">schreiben</label>

              <input id="contact-delete" v-model="cbContactsDelete" type="checkbox">
              <label for="contact-delete">löschen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Aufträge</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="jobs-read2" v-model="cbJobsRead" type="checkbox">
              <label for="jobs-read2">lesen</label>

              <input id="jobs-write2" v-model="cbJobsWrite" type="checkbox">
              <label for="jobs-write2">schreiben</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Nutzer</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="users-read" v-model="cbUsersRead" type="checkbox">
              <label for="users-read">lesen</label>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Wartungsanwendungen</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="installation-type-read" v-model="cbInstallationTypesRead" type="checkbox">
              <label for="installation-type-read">lesen</label>
              <div class="secondary">
                inkl. verbundene Daten, wie bspw. Komponenten-Typen
              </div>
            </div>
          </div>

          <div class="fx-form-row">
            <div class="fx-form-label">
              <label class="inline">Anlagen-Ereignisse</label>
            </div>
            <div class="fx-form-content local-role">
              <input id="triggers-events" v-model="cbSystemEvents" type="checkbox">
              <label for="triggers-events">Ereignisse aus techn. Anlagen übermitteln </label>
              <div class="secondary">
                Zum Übermitteln von Ereignissen aus techn. Anlagen, Hersteller-Clouds oder
                Anlagen-Peripherie
              </div>
            </div>
          </div>

          <button
            class="fx-btn-primary float-right"
            style="margin-bottom: 0.5rem; margin-left: 0.5rem;"
            @click.prevent="createKeyViaAPI();"
            >
            API-Key erzeugen
          </button>
          <button class="fx-btn-secondary float-right" @click.prevent="serverErrors = []; showingAddKey = false">
            Abbrechen
          </button>
          <div class="clearfix" />
        </div>
      </template>

<template v-if="showingResultingKey">
        <div style="border-radius: 5px; padding: 1rem; margin-top: 1rem; background-color: #f6f6f6;">
          <h3 class="fx" style="margin-bottom: 1rem;">
            Bitte kopieren Sie jetzt Ihren neuen API-Key
          </h3>
          <code
            id="newtoken"
            ref="newtoken"
            style="padding: .2rem .6rem; display: inline-block;"
          >{{ localNewKey.token }}</code>
          <button class="fx-btn-secondary" @click="copyToken()">
            <i class="fas fa-clipboard" />
            kopieren
          </button>
          <div class="callout callout--warning">
            <strong>Dieser Key kann nicht wieder angezeigt werden!</strong><br>
            Aus Sicherheitsgründen speichern wir API-Keys nicht im Klartext sondern in verschlüsselter Form (Hash).
            Kopieren Sie den Key und bewahren Sie Ihn sicher auf, bevor Sie diese Seite verlassen.
          </div>
          <button class="fx-btn-secondary float-right" @click.prevent="showingResultingKey = false">
            Ausblenden
          </button>
          <div class="clearfix" />
        </div>
      </template>
</div>
</div>
</div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Flash from 'flash';

import { ref } from 'vue';
import confirmViaModal from 'helpers/confirm_modal';
import ServerErrors from 'components/partials/server_errors.vue';
import ProFeatureCallout from 'components/pro_feature_callout.vue';
import NavBreadcrumps from 'components/nav_breadcrumps.vue';
import ButtonDeleteIcon from 'components/button_delete_icon.vue';
import { useCurrentUserStore } from 'stores/current_user';
import Button_delete_icon from '../../../components/button_delete_icon.vue';

export default {
  name: 'APIKeys',
  components: {
    ProFeatureCallout,
    ServerErrors,
    ButtonDeleteIcon,
    NavBreadcrumps,
  },
  props: {
    organizationConfigId: {
      type: String,
      required: true,
    },
    initAppReceiveTriggers: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const localPublicApiKeys = ref([]);

    const loadKeys = () => {
      axios.get('/public_api_keys.json').then((response) => {
        localPublicApiKeys.value = response.data.public_api_keys;
      }).catch(() => {
        Flash.error('API-Keys konnten nicht geladen werden');
      });
    };

    const deleteViaAPI = (apiKey) => {
      axios.delete(`/public_api_keys/${apiKey.id}.json`).then(() => {
        Flash.info('API-Key wurde gelöscht');
        loadKeys();
      }).catch(() => {
        Flash.error('Key konnte nicht gelöscht werden');
      });
    };

    const openConfirmDeleteKeyModal = (apiKey) => {
      confirmViaModal({
        title: 'API-Key löschen?',
        message: 'Anwendungen, die diesen API-Key nutzen, verlieren sofort den Zugriff auf Foxtag.',
        requireInputConfirmation: true,
      }).then(() => {
        deleteViaAPI(apiKey);
      });
    };

    return {
      localPublicApiKeys,
      loadKeys,
      openConfirmDeleteKeyModal,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  data() {
    return {
      appReceiveTriggers: true,
      localNewKey: { name: '' },
      showingAddKey: false,
      showingResultingKey: false,
      serverErrors: [],
      cbArticlesRead: false,
      cbArticlesWrite: false,
      cbJobsRead: false,
      cbJobsWrite: false,
      cbCustomersRead: false,
      cbCustomersWrite: false,
      cbCustomersDelete: false,
      cbContactsRead: false,
      cbContactsWrite: false,
      cbContactsDelete: false,
      cbLabelsRead: false,
      cbLabelsWrite: false,
      cbLabelsDelete: false,
      cbLocationsRead: false,
      cbLocationsWrite: false,
      cbLocationsDelete: false,
      cbInstallationsRead: false,
      cbInstallationsWrite: false,
      cbInstallationsDelete: false,
      cbUsersRead: false,
      cbInstallationTypesRead: false,
      cbSystemEvents: false,
    };
  },
  computed: {
  },
  mounted() {
    this.appReceiveTriggers = this.initAppReceiveTriggers;
    this.loadKeys();
  },
  methods: {
    humanDateTime(datetime) {
      if (datetime == null) {
        return 'bisher kein Zugriff';
      }
      return moment(datetime).format('DD.MM.YYYY HH:mm:ss');
    },
    copyToken() {
      const token = document.getElementById('newtoken').innerHTML;

      navigator.clipboard.writeText(token).then(() => {
        Flash.info('API-Key in Zwischenablage kopiert');
      }).catch((err) => Flash.error(err));
    },
    showAddKey() {
      this.localNewKey = { name: '', token: null };
      this.showingAddKey = true;
      this.cbArticlesRead = false;
      this.cbArticlesWrite = false;
      this.cbJobsRead = false;
      this.cbJobsWrite = false;
      this.cbCustomersRead = false;
      this.cbCustomersWrite = false;
      this.cbCustomersDelete = false;
      this.cbContactsRead = false;
      this.cbContactsWrite = false;
      this.cbContactsDelete = false;
      this.cbLabelsRead = false;
      this.cbLabelsWrite = false;
      this.cbLabelsDelete = false;
      this.cbLocationsRead = false;
      this.cbLocationsWrite = false;
      this.cbLocationsDelete = false;
      this.cbInstallationsRead = false;
      this.cbInstallationsWrite = false;
      this.cbInstallationsDelete = false;
      this.cbUsersRead = false;
      this.cbInstallationTypesRead = false;
      this.cbSystemEvents = false;
    },
    rolesFromCheckboxes() {
      const roles = [];
      if (this.cbFull) { roles.push('full'); }
      if (this.cbArticlesRead) { roles.push('articles.read'); }
      if (this.cbArticlesWrite) { roles.push('articles.write'); }
      if (this.cbJobsRead) { roles.push('jobs.read'); }
      if (this.cbJobsWrite) { roles.push('jobs.write'); }
      if (this.cbCustomersRead) { roles.push('customers.read'); }
      if (this.cbCustomersWrite) { roles.push('customers.write'); }
      if (this.cbCustomersDelete) { roles.push('customers.delete'); }
      if (this.cbContactsRead) { roles.push('contacts.read'); }
      if (this.cbContactsWrite) { roles.push('contacts.write'); }
      if (this.cbContactsDelete) { roles.push('contacts.delete'); }
      if (this.cbLabelsRead) { roles.push('labels.read'); }
      if (this.cbLabelsWrite) { roles.push('labels.write'); }
      if (this.cbLabelsDelete) { roles.push('labels.delete'); }
      if (this.cbLocationsRead) { roles.push('locations.read'); }
      if (this.cbLocationsWrite) { roles.push('locations.write'); }
      if (this.cbLocationsDelete) { roles.push('locations.delete'); }
      if (this.cbInstallationsRead) { roles.push('installations.read'); }
      if (this.cbInstallationsWrite) { roles.push('installations.write'); }
      if (this.cbInstallationsDelete) { roles.push('installations.delete'); }
      if (this.cbInstallationTypesRead) { roles.push('installation_types.read'); }
      if (this.cbUsersRead) { roles.push('users.read'); }
      if (this.cbSystemEvents) { roles.push('system_events'); }
      return roles;
    },

    createKeyViaAPI() {
      const that = this;
      that.serverErrors = [];
      axios.post('/public_api_keys.json', {
        public_api_key: {
          name: this.localNewKey.name,
          roles: this.rolesFromCheckboxes(),
        },
      }).then((result) => {
        that.localNewKey = result.data.public_api_key;
        that.localPublicApiKeys.push(that.localNewKey);
        that.showingAddKey = false;
        that.showingResultingKey = true;
      }).catch((error) => {
        if (error.response.status === 400) {
          that.serverErrors = error.response.data.public_api_keys;
        } else {
          Flash.error('API-Key konnte nicht gespeichert werden');
        }
      });
    },
    updateReceiveTriggers() {
      const that = this;
      axios.put(`/organization_configs/${this.organizationConfigId}.json`, {
        organization_config: {
          app_receive_triggers: this.appReceiveTriggers,
        },
      }).then(() => {
        Flash.info('Einstellung gespeichert');
      }).catch(() => {
        Flash.error('Fehler beim Speichern');
        that.appReceiveTriggers = !that.appReceiveTriggers; // reset setting after error
      });
    },
  },
};
</script>

<style>
td.local-cb-role {
  vertical-align: top;
  padding: .5rem;
  width: 20px;
}

td.local-info-role {
  padding: .5rem;
  font-size: .875rem;
}

div.local-role {
  padding-top: .5rem;
  font-size: .875rem;
}

td.local-infoexpand-role {
  padding: .5r em;
  font-size: .875rem;
}
</style>
