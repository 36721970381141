<template>
  <div class="empty-state-box" :style="noBorderStyle">
    <div v-if="icon" class="fx-empty-state-icon" style="margin: 0 auto 1rem auto;">
      <i :class="icon" />
    </div>

    <img
      v-if="successIcon"
      :src="logo"
      class="empty-state-icon"
      style="height: 90px; margin-bottom: 1rem;"
    >
    <h3 v-if="title" class="fx empty-state-title">
      {{ title }}
    </h3>
    <p class="empty-state-hint">
      {{ hint }}
    </p>
    <slot />
  </div>
</template>

<script>
import iconSuccess from 'images/success_circle.svg';

export default {
  name: 'EmptyState',
  props: {
    title: {
      type: String,
      default: null,
    },
    hint: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    successIcon: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    noBorderStyle() {
      return this.noBorder ? { borderWidth: '0' } : {};
    },
  },
  data() {
    return {
      logo: iconSuccess,
    };
  },
  mounted() {
  },
};
</script>

<style scoped>
  div.empty-state-box {
    text-align: center;
    background-color: white; border: 1px solid #ddd; padding: 3rem;margin-top: 1rem;
  }
  h2.empty-state-title {
    margin-top: 1rem;
  }
  p.empty-state-hint {
    color: #929292;
  }
  img.empty-state-icon {
    margin-bottom: 2rem;
    height: 40px;
  }
</style>
