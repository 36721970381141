<template>
  <OnClickOutside @trigger="hideOptions">
    <input
      :id="id"
      v-model="selectedTime"
      type="text"
      :class="classes"
      @focus="showOptionsNow"
    >
    <ul v-if="showOptions" id="time-input-presets" class="time-input">
      <template v-for="hour in hours">
        <li
          v-for="minute in minutes"
          :id="`ti-${hour}${minute}`"
          :key="`${hour}${minute}`"
          @click="selectedTime=`${hour}:${minute}`;showOptions=false;"
        >
          {{ hour }}:{{ minute }}
        </li>
      </template>
    </ul>
  </OnClickOutside>
</template>

<script>
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'TimeInput',
  components: {
    OnClickOutside,
  },
  props: {
    id: {
      type: String,
      default: 'time-input',
    },
    modelValue: {
      type: String,
      default: '08:00',
    },
  },
  emits: ['input'],
  data() {
    return {
      valid: true,
      selectedTime: '08:00',
      showOptions: false,
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14',
        '15', '16', '17', '18', '19', '20', '21', '22', '23'],
      minutes: ['00', '15', '30', '45'],
    };
  },
  computed: {
    classes() {
      if (!this.valid) {
        return 'invalid';
      }
      return null;
    },
  },
  watch: {
    selectedTime: {
      handler(newVal) {
        if (/^((?:[01]\d|2[0-3]):[0-5]\d$)/.test(newVal)) {
          this.valid = true;
          this.$emit('input', newVal);
        } else if (/^((?:\d|2[0-3]):[0-5]\d$)/.test(newVal)) {
          this.valid = true;
          this.$emit('input', `0${newVal}`);
        } else {
          this.valid = false;
        }
      },
      deep: true,
    },
  },

  mounted() {
    this.selectedTime = this.modelValue;
  },
  methods: {
    hideOptions() {
      this.showOptions = false;
    },
    showOptionsNow() {
      this.showOptions = true;
      this.$nextTick(() => {
        this.scrollToSelectedValues();
      });
    },
    scrollToSelectedValues() {
      const selector = this.selectedTime.replace(':', '');
      const targetList = this.$el.querySelectorAll('ul#time-input-presets');
      const targetValue = this.$el.querySelectorAll(`ul#time-input-presets li#ti-${selector}`);
      if (targetValue && targetValue.length > 0) {
        targetValue[0].className += ' active';
        targetList[0].scrollTop = targetValue[0].offsetTop - 50; // || 0;
      }
    },
  },
};
</script>
<style>
input[type="text"].invalid {
  border: 1px solid red !important;
}

ul.time-input {
  position: absolute;
  background: #eeeeee;
  border: 1px solid red;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 100;
  margin-top: 0;
  margin-left: 0;
  min-width: 100px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  font-size: 14px;
  font-size: .875rem;
  padding: 0;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fafafa;
  list-style: none;
}

ul.time-input li {
  padding: 0 .5rem;
  cursor: pointer;
}

ul.time-input li:hover {
  background-color: #eee;
}

ul.time-input li.active {
  color: #256FC5;
  font-weight: bold;
}
</style>
