<template>
  <OnClickOutside style="display: block;" @trigger="hideOptions">
    <input
      :id="id"
      v-model="selectedArticleGroup"
      type="text"
      :placeholder="noSelectionLabel"
      class="article-group-input"
      autocomplete="off"
      @focus="showOptionsNow"
    >
    <ul v-if="showOptionsIfAny" id="article-group-input-presets" class="article-group-input">
      <li v-if="noSelectionLabel !== ''" @click="selectOption(null)">
        {{ noSelectionLabel }}
      </li>
      <li
        v-for="articleGroup in filteredArticleGroups"
        :id="`ti-${articleGroup}`"
        :key="`${articleGroup}`"
        @click="selectOption(articleGroup)"
      >
        {{ articleGroup }}
      </li>
    </ul>
  </OnClickOutside>
</template>

<script>
import _ from 'lodash';
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'SelectArticleGroups',
  components: {
    OnClickOutside,
  },
  props: {
    articleGroups: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      default: 'article-group-input',
    },
    modelValue: {
      type: String,
      default: null,
    },
    noSelectionLabel: {
      type: String,
      default: 'Alle Artikelgruppen',
    },
  },
  emits: ['input'],
  data() {
    return {
      showOptions: false,
      filteredArticleGroups: [],
      selectedArticleGroup: null,
    };
  },
  computed: {
    showOptionsIfAny() {
      return this.showOptions && this.filteredArticleGroups.length > 0;
    },
  },
  watch: {
    selectedArticleGroup: {
      handler(newVal) {
        this.$emit('input', newVal);
        this.filteredArticleGroups = _.filter(this.articleGroups, (o) => o.toLowerCase().startsWith(newVal.toLowerCase()));
      },
    },
    articleGroups: {
      handler() {
        this.filteredArticleGroups = _.filter(this.articleGroups, (o) => o.toLowerCase().startsWith(this.modelValue.toLowerCase()));
      },
    },
    modelValue: {
      handler(newVal) {
        this.selectedArticleGroup = newVal;
      },
    },
  },
  mounted() {
    this.selectedArticleGroup = this.modelValue;
  },
  methods: {
    selectOption(manufacturer) {
      this.selectedArticleGroup = manufacturer;
      this.showOptions = false;
    },
    hideOptions() {
      this.showOptions = false;
    },
    showOptionsNow() {
      this.refreshFilteredList();
      this.showOptions = true;
    },
    refreshFilteredList() {
      if (this.modelValue == null) {
        this.filteredArticleGroups = this.articleGroups;
      } else {
        this.filteredArticleGroups = _.filter(this.articleGroups, (o) => o.toLowerCase().startsWith(this.modelValue.toLowerCase()));
      }
    },
  },
};
</script>
<style>
input[type="text"].invalid {
  border: 1px solid red !important;
}

input.article-group-input::placeholder {
  color: #888;
}

ul.article-group-input {
  position: absolute;
  background-color: #eeeee;
  max-height: 300px;
  overflow-y: scroll;

  margin-top: 0;
  margin-left: 0;
  min-width: 200px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  font-size: .825rem;
  padding: 0;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fafafa;
  list-style: none;
  z-index: 1000;
}

ul.article-group-input li {
  padding: .4rem 1.2rem .4rem .8rem;
  cursor: pointer;
}

ul.article-group-input li:hover {
  background-color: #eee;
}

ul.article-group-input li.active {
  color: #256FC5;
  font-weight: bold;
}
</style>
