<template>
  <div style="min-width: 600px; max-width: 90vw; width: 90vw; padding: 10px;">
    <div>
      <div
        v-if="photo"
        style="display: grid; grid-template-columns: auto 320px; grid-template-rows: auto 200px; min-width: 100%; min-height: 100%;"
      >
        <ZoomableImage v-model="photo.url" />

        <div>
          <a class="fx-modal__close" @click="$emit('close')">&times;</a>
          <div style="padding: 0 10px;">
            <div style="display: inline-block" class="mb-4">
              <button class="fx-btn-secondary" @click="prevPhoto()">
                <strong>&larr;</strong>
              </button>
              <span style="font-weight: bold; font-size: 1rem; margin: 0 1rem;">{{ currentIndex + 1
              }}/{{ photos.length }}</span>
              <button class="fx-btn-secondary" @click="nextPhoto()">
                &rarr;
              </button>
            </div>

            <div v-if="showInfoAndControls && photo">
              <div class="mt-4">
                <div v-if="photo.photoable_id">
                  <i
                    :class="photoIconClasses(photo.photoable_type)"
                    style="color: rgba(0, 0, 0, 0.45);"
                  /> <strong><a :href="photo.photoable_path">{{
                    photo.photoable_label }}</a></strong>
                </div>
                <a class="no-color no-hover" @click="showEditForm = true"><span v-if="photo.caption">{{ photo.caption
                }}</span><span v-else class="secondary">Keine Bildunterschrift</span></a>
              </div>

              <div class="mt-4" style="font-size: .8rem; border-top: 1px solid #ccc; padding-top: 1rem;">
                <div v-if="!photo.on_report" class="mb-2">
                  <i class="fas fa-ban" /> Wird nicht auf Berichte gedruckt
                </div>
                <div v-if="photo.taken_at">
                  Foto vom
                  <strong>{{ formatDate(photo.taken_at, 'DD.MM.YYYY') }}</strong>
                  {{ formatDate(photo.taken_at, 'HH:mm') }} Uhr
                </div>

                <div v-if="photo.taken_by">
                  Hochgeladen von
                  <img
                    :src="photo.taken_by.avatar_path"
                    width="20px"
                    height="20px"
                    style="vertical-align: middle;"
                  >
                  <span style="vertical-align: middle;">{{ photo.taken_by.name }}</span>
                </div>

                <div v-if="photo.job && photo.job.id">
                  Im Auftrag <a :href="`/jobs/${photo.job.id}`">{{ photo.job.label
                  }}</a>
                </div>
                <div v-if="photo.metadata && photo.metadata.width">
                  {{ photo.metadata.width }} x
                  {{ photo.metadata.height }}<span class="ml-4">{{ filesizeMB }} MB</span>
                </div>
              </div>

              <div v-if="photo.metadata" class="mt-4 secondary" style="font-size: .8rem;">
                <div v-if="photo.metadata.make">
                  <i class="fas fa-camera fa-fw" /> {{ photo.metadata.make }}
                  {{ photo.metadata.model }}
                </div>
                <div v-if="photo.metadata.create_date" class="secondary">
                  <i class="far fa-calendar-alt fa-fw" />
                  {{ photo.metadata.create_date }} {{ photo.metadata.offset_time }}
                </div>
                <div v-if="photo.metadata.gps_position" class="secondary">
                  <i class="far fa-map-marker-alt fa-fw" />
                  {{ photo.metadata.gps_position }}
                </div>
              </div>

              <div class="mt-4">
                <br><br>

                <button class="fx-btn-secondary mb-2" style="width:100%" @click="showEditForm = true">
                  Daten
                  bearbeiten
                </button>
                <button
                  v-if="canEditJob"
                  class="fx-btn-secondary mb-2"
                  style="width:100%"
                  @click="showEditJobForm = true"
                >
                  Zuordnung Auftrag
                  bearbeiten
                </button>
                <a
                  :href="`/photos/${photo.id}/download`"
                  class="fx-btn-secondary mb-2"
                  style="width:100%"
                  tttarget="_blank"
                  download="photo"
                >Download</a>
                <button
                  class="fx-btn-secondary"
                  style="width:100%"
                  @click="$emit('delete', photo)"
                >
                  Löschen
                </button>
              </div>
            </div>

            <div v-if="showEditForm">
              <ServerErrorsCommand v-model="commandServerErrors" margin-bottom="1rem" />

              <div v-if="photo.taken_at">
                Foto vom
                <strong>{{ formatDate(photo.taken_at, 'DD.MM.YYYY') }}</strong>
              </div>

              <a class="mb-2" @click="showEditTakenAtForm = true">Datum korrigieren</a>

              <div v-if="showEditTakenAtForm" class="mt-4">
                <label>Foto-Datum</label>
                <div class="mb-2">
                  <VueDatePicker
                    v-model="photo.taken_at"
                    :inline="true"
                    :locale="$i18n.locale"
                    model-type="yyyy-MM-dd"
                    :auto-apply="true"
                    :enable-time-picker="false"
                  />
                </div>
              </div>
              <div class="mt-4">
                <label>Bildunterschrift</label>
                <textarea v-model="photo.caption" rows="6" class="mb-2" />
              </div>

              <div class="mt-4">
                <label>Wird auf Berichte gedruckt</label><br>
                <input
                  id="on_report-true"
                  v-model="photo.on_report"
                  :value="true"
                  type="radio"
                  name="on_report-true"
                ><label for="on_report-true" class="inline">Ja</label>
                <input
                  id="on_report-false"
                  v-model="photo.on_report"
                  :value="false"
                  type="radio"
                  name="on_report-false"
                ><label for="on_report-false" class="inline">Nein</label>
              </div>

              <div class="mt-4">
                <div class="fx-btn-group">
                  <button class="fx-btn-primary" @click="updateViaApi()">
                    Speichern
                  </button>
                  <button class="fx-btn-secondary" @click="hideEditForm()">
                    Abbrechen
                  </button>
                </div>
              </div>
</div>
              <div v-if="showEditJobForm">
                <ServerErrorsCommand v-model="commandServerErrors" margin-bottom="1rem" />

                <div class="mt-4">
                  <label>Aufgenommen im Auftrag</label><br>
                  <DBBasedSelect
                    v-model="photo.job.id"
                    icon-id="job"
                    :default-label="$t('comp.select.no-selection')"
                    i18n-base-key="jobs"
                    :load-options-url="`/select/jobs?installation_id=${installationId}&only_open_jobs=false`"
                    @changed="onJobSelected"
                  />
                </div>

                <div class="mt-4">
                  <div class="fx-btn-group">
                    <button class="fx-btn-primary" @click="updateJobViaApi()">
                      Speichern
                    </button>
                    <button class="fx-btn-secondary" @click="hideEditForm()">
                      Abbrechen
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="protip mt-4" style="font-size: .8rem; color: #666; align-self: end; padding: 1rem;">
            <i class="far fa-lightbulb" /> <strong>Tipp:</strong> Mit den Pfeiltasten <span class="kbd">&#9664;</span>
            <span class="kbd">&#9654;</span> auf der Tastatur
            blättern Sie durch die Fotos, mit <span class="kbd">Esc</span> schließen Sie die Ansicht
          </div>
        </div>
</div>
  </div>
</template>

<script>
import axios from 'axios';
import Flash from 'flash/index';

import { DateFormatter } from 'mixins/formatters';

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import ServerErrorsCommand from 'components/partials/server_errors_command.vue';
import ZoomableImage from 'components/zoomable_image.vue';
import DBBasedSelect from 'components/select/db_based_select.vue';

const keyCodes = {
  enter: 13,
  esc: 27,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
  shift: 16,
};

export default {
  name: 'PhotoModal',
  components: {
    VueDatePicker,
    ServerErrorsCommand,
    DBBasedSelect,
    ZoomableImage,
  },
  mixins: [DateFormatter],
  props: {
    photos: {
      type: Array,
      required: true,
    },
    installationId: {
      type: String,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  emits: ['changed', 'close', 'delete'],
  data() {
    return {
      photo: null,
      currentIndex: 0,
      showEditForm: false,
      showEditJobForm: false,
      // dpd: datePickDefaults,
      commandServerErrors: {},
      showEditTakenAtForm: false,
      localPhotos: [],
      changed: false,
      zoom: false,
    };
  },
  computed: {
    showInfoAndControls() {
      return !this.showEditForm && !this.showEditJobForm;
    },
    filesizeMB() {
      if (this.photo && this.photo.metadata && this.photo.metadata.size) {
        return parseFloat(this.photo.metadata.size / 1000 / 1000).toFixed(2);
      }
      return '';
    },
    canEditJob() {
      return (this.installationId != null && this.photo.photoable_type !== 'Job');
    },
  },
  mounted() {
    this.localPhotos = Array.from(this.photos, (a) => a); // --- copy array instead of ref to original

    this.currentIndex = this.index;
    this.photo = this.photos[this.currentIndex];
    this.photo = this.localPhotos[this.currentIndex];
    this.loadDetails(this.currentIndex);

    this.opened();
  },
  unmounted() {
    this.closed();
  },
  methods: {
    onJobSelected(jobId) {
      if (jobId == null) {
        this.photo.job.id = '';
      } else {
        this.photo.job.id = jobId;
      }
    },
    photoIconClasses(photoableType) {
      if (photoableType === 'Incident') {
        return 'fas fa-exclamation-triangle';
      }
      if (photoableType === 'Thing') {
        return 'fas fa-cube';
      }
      return 'fas fa-calendar-check';
    },
    // --- used by date pick
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    hideEditForm() {
      this.showEditForm = false;
      this.showEditJobForm = false;
    },
    nextPhoto() {
      if (this.currentIndex + 1 === this.photos.length) {
        this.currentIndex = 0;
      } else {
        this.currentIndex += 1;
      }
      this.hideEditForm();
      this.loadDetails(this.currentIndex);
      this.photo = this.localPhotos[this.currentIndex];
    },
    prevPhoto() {
      if (this.currentIndex === 0) {
        this.currentIndex = this.photos.length - 1;
      } else {
        this.currentIndex -= 1;
      }
      this.hideEditForm();
      this.loadDetails(this.currentIndex);
      this.photo = this.localPhotos[this.currentIndex];
    },
    opened() {
      document.addEventListener('keyup', this.onKeyUp);
      if (this.jobId == null) { return; }

      const that = this;
      axios.get(`/jobs/${this.jobId}/details.json`).then((response) => {
        that.job = response.data.photo;
        that.loading = false;
      }).catch(() => {
        that.loading = false;
        that.error = true;
      });
    },
    closed() {
      document.removeEventListener('keyup', this.onKeyUp);
      this.$emit('close', this.job);
      if (this.changed) {
        this.$emit('changed');
      }
    },
    updateViaApi() {
      const that = this;
      that.commandServerErrors = {};
      axios.put(`/photos/${this.photo.id}.json`, {
        photo: {
          caption: this.photo.caption,
          taken_at: this.photo.taken_at,
          on_report: this.photo.on_report,
        },
      }).then((response) => {
        that.photo = response.data.photo;
        that.hideEditForm();
        that.changed = true;
        Flash.info('Foto gespeichert');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.commandServerErrors = error.response.data.photos;
        } else {
          Flash.error('Foto konnte nicht gespeichert werden');
        }
      });
    },
    updateJobViaApi() {
      const that = this;
      that.commandServerErrors = {};
      axios.put(`/photos/${this.photo.id}/update_job.json`, {
        photo: {
          job_id: this.photo.job.id,
        },
      }).then((response) => {
        that.photo.job = response.data.photo.job;
        that.hideEditForm();
        that.changed = true;
        Flash.info('Foto gespeichert');
      }).catch((error) => {
        if (error.response.status === 400) {
          that.commandServerErrors = error.response.data.jobs;
        } else {
          Flash.error('Foto konnte nicht gespeichert werden');
        }
      });
    },
    hasDetails(photoIndex) {
      return (this.localPhotos[photoIndex].taken_by != null);
    },
    refreshCurrentPhoto() {
      this.photo = this.localPhotos[this.currentIndex];
    },
    loadDetails(photoIndex) {
      if (this.hasDetails(photoIndex)) {
        return;
      }

      const that = this;
      axios.get(`/photos/${this.localPhotos[photoIndex].id}.json`).then((response) => {
        that.localPhotos[photoIndex] = response.data.photo;
        that.refreshCurrentPhoto();
      });
    },
    onKeyUp(event) {
      if (event.keyCode === keyCodes.left || event.keyCode === keyCodes.up) {
        this.prevPhoto();
      } else if (event.keyCode === keyCodes.right || event.keyCode === keyCodes.down) {
        this.nextPhoto();
      }
    },
  },
};
</script>

<style>
span.kbd {
  border: 2px solid #888;
  box-shadow: 2px 2px #888;
  font-size: .85em;
  line-height: .85em;
  display: inline-block;
  font-weight: 600;
  letter-spacing: .05em;
  padding: 3px 5px;
  white-space: nowrap;
}
</style>
