<template>
  <OnClickOutside style="display: block;" @trigger="hideOptions">
    <input
      :id="id"
      v-model="selectedOption"
      type="text"
      :placeholder="placeholder"
      class="autocomplete-input"
      autocomplete="off"
      @focus="maybeShowOptions(selectedOption)"
      @blur="delayedHideOptions"
    >
    <ul v-if="showOptions" :id="`${id}-presets`" class="autocomplete-input">
      <li
        v-for="option in filteredAutoCompleteOptions"
        :id="`ti-${option}`"
        :key="`${option}`"
        @click="selectOption(option)"
      >
        {{ option }}
      </li>
    </ul>
  </OnClickOutside>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';
import { OnClickOutside } from '@vueuse/components';
import { ref } from 'vue';

import Flash from 'flash/index';

export default {
  name: 'DbBasedAutocomplete',
  components: {
    OnClickOutside,
  },
  props: {
    id: {
      type: String,
      default: 'autocomplete-input',
    },
    modelValue: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
  },
  emits: ['input'],
  setup(props) {
    const showOptions = ref(false);
    const autoCompleteOptions = ref(props.options);
    const filteredAutoCompleteOptions = ref(props.options);
    const selectedOption = ref('');

    const delayedHideOptions = () => {
      // Delay the hiding of the options to allow the click event to be processed when selecting an option from the list
      setTimeout(() => {
        showOptions.value = false;
      }, 400);
    };

    const maybeShowOptions = (selected) => {
      showOptions.value = (filteredAutoCompleteOptions.value.length > 0 && selected !== filteredAutoCompleteOptions.value[0]);
    };

    const hideOptions = () => {
      showOptions.value = false;
    };

    const selectOption = (option) => {
      selectedOption.value = option;
      showOptions.value = false;
    };

    return {
      maybeShowOptions,
      hideOptions,
      showOptions,
      selectedOption,
      selectOption,
      delayedHideOptions,
      autoCompleteOptions,
      filteredAutoCompleteOptions,
    };
  },
  watch: {
    selectedOption: {
      handler(newVal) {
        if (newVal == null) { return; }

        this.$emit('input', newVal);
        this.filteredAutoCompleteOptions = _.filter(this.autoCompleteOptions, (o) => o.toLowerCase().startsWith(newVal.toLowerCase()));

        this.maybeShowOptions(newVal);
      },
    },
  },

  mounted() {
    this.selectedOption = this.modelValue;
  },
};
</script>
<style>
input[type="text"].invalid {
  border: 1px solid red !important;
}

input.autocomplete-input::placeholder {
  color: #888;
}

ul.autocomplete-input {
  position: absolute;
  background: #eeeeee;
  max-height: 300px;
  overflow-y: scroll;

  margin-top: 0;
  margin-left: 0;
  min-width: 200px;
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  font-size: .825rem;
  padding: 0;
  border: 1px solid #cacaca;
  border-radius: 5px;
  background-color: #fafafa;
  list-style: none;
  z-index: 1000;
}

ul.autocomplete-input li {
  padding: .4rem 1.2rem .4rem .8rem;
  cursor: pointer;
}

ul.autocomplete-input li:hover {
  background-color: #eee;
}

ul.autocomplete-input li.active {
  color: #256FC5;
  font-weight: bold;
}
</style>
