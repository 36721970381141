<template>
  <pre class="pretty-json" v-html="prettyifiedJSON" />
</template>

<script>

export default {
  name: 'PrettyJSON',
  props: {
    json: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    prettyifiedJSON() {
      let json = JSON.stringify(this.json, undefined, 2);
      json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
      return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, (match) => {
        let cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return `<span class="${cls}">${match}</span>`;
      });
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
pre.pretty-json {
  outline: 1px solid #ccc;
  padding: 5px;
  margin: 5px;
}

.pretty-json .string {
  color: green;
}

.pretty-json .number {
  color: rgb(172, 94, 0);
}

.pretty-json .boolean {
  color: blue;
}

.pretty-json .null {
  color: magenta;
}

.pretty-json .key {
  color: #555;
}
</style>
