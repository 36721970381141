<template>
  <span>
    <div class="multi-select">
      <button type="button" class="btn-select" @click="toggleCheckboxes">
        <div class="buttonLabel" :class="classForNothingSelected">
          <i class="far fa-calendar-alt fx-select-icon" />&nbsp;
          <template v-if="customStartDate && customEndDate">
            {{ formattedCustomRange }}
          </template>
          <template v-else-if="valueSelected.id == null">
            {{ $t('comp.filter.date-range.default-label') }}
          </template>
          <template v-else>
            {{ $t(`comp.filter.date-range.options.${valueSelected.id}`) }}
          </template>
          <span class="caret" />
        </div>
      </button>

      <OnClickOutside @trigger="externalClick">
        <div class="checkboxLayer" :class="{ 'show': isOpen }">
          <div class="helperContainer" style="margin: 0 .4rem 1rem .4rem;">
            <button :class="tabClasses(0)" style="padding-top: .5rem; padding-bottom: .5rem" @click="setActiveTab(0)">{{
              $t('comp.filter.date-range.presets') }}</button>
            <button :class="tabClasses(1)" style="padding-top: .5rem; padding-bottom: .5rem" @click="setActiveTab(1)">{{
              $t('comp.filter.date-range.custom-date-range') }}</button>
            <hr style="margin-top:0; margin-bottom: .4rem;">
            <div v-if="isActiveTab(0)">
              <div style="display: inline-block; width: 360px; vertical-align: top;">

                <div style="margin-bottom: .4rem; margin-top: .2rem;">
                  <a :class="classForOption(todayOption)" @click="selectOption(todayOption)">{{
                    $t(`comp.filter.date-range.options.${todayOption.id}`) }}</a>
                </div>

                <h5>{{ $t('comp.filter.date-range.weeks') }}</h5>
                <div style="margin-bottom: .4rem">
                  <a
                    v-for="(option, index) in weekOptions"
                    :key="index"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ $t(`comp.filter.date-range.options.${option.id}`) }}</a>
                </div>

                <h5>{{ $t('comp.filter.date-range.months') }}</h5>
                <div style="margin-bottom: .4rem">
                  <a
                    v-for="(option, index) in monthOptions"
                    :key="index"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ $t(`comp.filter.date-range.options.${option.id}`)
                  }}</a>
                </div>

                <h5>{{ $t('comp.filter.date-range.quarters') }}</h5>
                <div style="margin-bottom: .4rem">
                  <a
                    v-for="(option, index) in quartalOptions"
                    :key="index"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ $t(`comp.filter.date-range.options.${option.id}`) }}</a>
                </div>

                <h5>{{ $t('comp.filter.date-range.years') }}</h5>
                <div style="margin-bottom: .4rem">
                  <a :class="classForOption(yearOptionCurrentYear)" @click="selectOption(yearOptionCurrentYear)">{{
                    $t(`comp.filter.date-range.options.${yearOptionCurrentYear.id}`) }}</a><a
                    :class="classForOption(yearOptionNextYear)"
                    @click="selectOption(yearOptionNextYear)"
                  >{{
                    $t(`comp.filter.date-range.options.${yearOptionNextYear.id}`) }}</a>
                </div>
                <div style="margin-bottom: .4rem">
                  <a
                    v-for="(option, index) in yearOptions"
                    :key="index"
                    :class="classForOption(option)"
                    @click="selectOption(option)"
                  >{{ option.label }}</a>
                </div>
              </div>
            </div>
            <div v-if="isActiveTab(1)">
              <div style="display: inline-block; width: 610px; vertical-align: top;">
                <table style="margin-bottom: .5rem;">
                  <tr>
                    <td style="width: 300px; vertical-align: top; padding-right: 10px;">
                      <span style="font-size: .8rem;">{{ $t('comp.filter.date-range.startdate') }}</span>

                      <VueDatePicker
                        v-model="customStartDate"
                        :inline="true"
                        :locale="$i18n.locale"
                        :auto-apply="true"
                        model-type="yyyy-MM-dd"
                        :enable-time-picker="false"
                      />
                    </td>
                    <td style="width: 300px; vertical-align: top;">
                      <span style="font-size: .8rem;">{{ $t('comp.filter.date-range.enddate') }}</span>
                      <VueDatePicker
                        v-model="customEndDate"
                        :inline="true"
                        :locale="$i18n.locale"
                        model-type="yyyy-MM-dd"
                        :auto-apply="true"
                        :enable-time-picker="false"
                      />
                    </td>
                  </tr>
                </table>
                <a :class="classCustomRangeButton" @click="selectCustomTimerange">{{
                  $t('comp.filter.date-range.apply-custom-date-range') }}</a>
                <span v-if="!customStartDate" class="secondary" style="font-size: .8rem; margin-left: .5rem;">{{
                  $t('comp.filter.date-range.please-select-startdate') }}</span>
                <span
                  v-if="customStartDate && !customEndDate"
                  class="secondary"
                  style="font-size: .8rem; margin-left: .5rem;"
                >{{
                  $t('comp.filter.date-range.please-select-enddate') }}</span>

                <span
                  v-if="customStartDate && customEndDate"
                  style="font-size: .8rem; margin-left: .5rem; font-weight: bold;"
                >
                  {{ formattedCustomRange }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </OnClickOutside>
    </div>
  </span>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import { OnClickOutside } from '@vueuse/components';
import { DateFormatter } from 'mixins/formatters';

export default {
  name: 'SelectMegaTimerange',
  components: {
    VueDatePicker,
    OnClickOutside,
  },
  mixins: [DateFormatter],
  props: {
    nothingSelectedLabel: {
      type: String,
      default: 'Ohne Zeitangabe',
    },
    modelValue: {
      type: String,
      default: null,
    },
  },
  emits: ['input', 'changed'],
  data() {
    return {
      activeTab: 0,
      customStartDate: null,
      customEndDate: null,
      customRelativeRange: null,
      todayOption: { id: 'today' },
      weekOptions: [
        { id: 'w' },
        { id: '-1w' },
        { id: '1w' },
      ],
      monthOptions: [
        { id: 'm' },
        { id: '-1m' },
        { id: '1m' },
      ],
      quartalOptions: [
        { id: 'q' },
        { id: 'q1' },
        { id: 'q2' },
        { id: 'q3' },
        { id: 'q4' },
      ],
      yearOptions: [
      ],
      yearOptionCurrentYear: { id: 'y' },
      yearOptionNextYear: { id: '1y' },
      valueSelected: { label: this.nothingSelectedLabel, id: null },
      multiSelect: null,
      isOpen: false,
    };
  },
  computed: {
    formattedCustomRange() {
      let from;
      let to;
      if (this.customEndDate < this.customStartDate) {
        to = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        from = this.formatDate(this.customEndDate, 'DD.MM.YYYY');
      } else {
        from = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        to = this.formatDate(this.customEndDate, 'DD.MM.YYYY');
      }
      return `${from} - ${to}`;
    },
    classCustomRangeButton() {
      if (this.customStartDate && this.customEndDate) {
        return 'fx-btn-primary';
      }
      return 'fx-btn-secondary disabled';
    },
    classForNothingSelected() {
      if (this.valueSelected.label === this.nothingSelectedLabel) {
        return 'dimmed';
      }
      return '';
    },
    selectBoxLabel() {
      if (this.valueSelected) {
        return this.valueSelected.label;
      }
      return this.nothingSelectedLabel;
    },
  },
  watch: {
    modelValue: {
      handler(newVal) {
        if (newVal && newVal !== this.valueSelected.id) {
          this.initFromModelValue(newVal);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.prepareYearOptions();
    this.initFromModelValue(this.modelValue);
  },
  methods: {
    initFromModelValue(newVal) {
      if (newVal) {
        const found = this.setSelectedValueById(newVal);
        if (!found) {
          // --- value is a customer date range (e.g. 2021-01-01_2021-12-31)
          this.setCustomRangeById(newVal);
          this.setActiveTab(1);
        } else {
          // --- value is a preset id (e.g. 'today' or '1w' or '1m' or '1y')
          this.setActiveTab(0);
          this.customStartDate = null;
          this.customEndDate = null;
        }
      }
    },
    prepareYearOptions() {
      const currentYear = new Date().getFullYear();
      for (let year = currentYear + 1; year > currentYear - 5; year -= 1) {
        this.yearOptions.push({ label: `${year}`, id: `y${year}` });
      }
    },
    tabClasses(tab) {
      if (tab === this.activeTab) {
        return 'fx-tab fx-tab__active';
      }
      return 'fx-tab';
    },
    isActiveTab(tab) {
      return (this.activeTab === tab);
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    classForOption(option) {
      if (option.id === this.valueSelected.id) {
        return 'fx-btn-primary option-button';
      }
      return 'fx-btn-skeleton option-button';
    },
    toggleCheckboxes(event) {
      this.multiSelect = event.target;
      if (this.multiSelect.className === 'buttonLabel') {
        this.multiSelect = this.multiSelect.parentNode;
      }
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.valueSelected = option;
      this.isOpen = false;
      this.customStartDate = null;
      this.customEndDate = null;
      this.$emit('input', this.valueSelected.id);
      this.$emit('changed', this.valueSelected.id);
    },
    selectCustomTimerange() {
      if (this.customStartDate && this.customEndDate) {
        if (this.customEndDate < this.customStartDate) {
          const holdMyBeer = this.customStartDate;
          this.customStartDate = this.customEndDate;
          this.customEndDate = holdMyBeer;
        }

        const from = this.formatDate(this.customStartDate, 'DD.MM.YYYY');
        const to = this.formatDate(this.customEndDate, 'DD.MM.YYYY');

        this.valueSelected = { label: `${from} bis ${to}`, id: this.customTimerangeId() };
        this.isOpen = false;

        this.$emit('input', this.valueSelected.id);
        this.$emit('changed', this.valueSelected.id);
      }
    },
    customTimerangeId() {
      return `${this.formatDate(this.customStartDate, 'YYYY-MM-DD')}_${this.formatDate(this.customEndDate, 'YYYY-MM-DD')}`;
    },
    isCustomTimerange(str) {
      return str && (str.indexOf('_') > 0) && (str.length === 21);
    },
    externalClick(event) {
      if (this.isOpen) {
        let elem = event.target;
        if (!!elem && elem.className === 'buttonLabel') {
          elem = elem.parentNode;
        }
        if (!!elem && elem.isSameNode(this.multiSelect)) {
          return;
        }
        this.isOpen = false;
      }
    },
    setSelectedValueById(newVal) {
      const arrayOfOptionArrays = [
        [this.todayOption], [this.yearOptionCurrentYear, this.yearOptionNextYear], this.weekOptions, this.monthOptions, this.quartalOptions, this.yearOptions];

      for (let ii = 0; ii < arrayOfOptionArrays.length; ii += 1) {
        const options = arrayOfOptionArrays[ii];
        for (let i = 0; i < options.length; i += 1) {
          if (options[i].id === newVal) {
            this.valueSelected = options[i];
            return true;
          }
        }
      }
      return false;
    },
    setCustomRangeById(newVal) {
      if (newVal && (typeof newVal === 'string')) {
        const str1 = newVal.split('_')[0];
        const str2 = newVal.split('_')[1];
        if (str1 && str2) {
          this.customStartDate = str1;
          this.customEndDate = str2;
          this.selectCustomTimerange();
        }
      }
    },
  },
};
</script>

<style scoped>
h5 {
  color: #2C556E;
  margin-top: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: normal;
  font-size: .8rem;
}

a.option-button {
  margin-right: .2rem;
}
</style>
