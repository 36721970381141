<template>
  <div>
    <div class="float-right">
      <div class="fx-btn-group">
        <span
          v-if="currentUser.pro && photoableType != 'Incident'"
          v-tippy="{ content: 'Fotos nach Typ ein- oder ausblenden', placement: 'top', duration: 100, delay: [1000, null], arrow: true }"
          class="btn_segmented"
        >
          <button
            v-if="photoableType == 'Job'"
            class="btn_segmented"
            :class="classFilterJob"
            title="Filter"
            @click="filterJob = !filterJob; loadPhotos();"
          ><i class="fas fa-calendar-check" />
            Auftrag</button><button
            class="btn_segmented"
            :class="classFilterThing"
            @click="filterThing = !filterThing; loadPhotos();"
          ><i class="fas fa-cube" /> Komponente</button><button
            class="btn_segmented"
            :class="classFilterIncident"
            @click="filterIncident = !filterIncident; loadPhotos();"
          ><i
            class="fas fa-exclamation-triangle"
          /> Störung</button>
        </span>

        <a
          v-if="addPhotosButton"
          href="#"
          class="fx-btn-secondary mb-4"
          @click.prevent="showAddPhotos = !showAddPhotos;"
        >
          <i class="fas fa-plus" /> Fotos hinzufügen
        </a>
      </div>
    </div>
    <br v-if="!title" clear="all">
    <h2 v-if="title" class="fx">
      Fotos
    </h2>
    <dashboard
      v-if="showAddPhotos"
      :uppy="uppy"
      :props="{
        inline: true,
        height: 300,
        width: '100%',
      }"
    />
    <div :class="gridClasses">
      <div v-for="(photo, index) in localPhotos" :key="photo.id" style="width: 100%; overflow: hidden;">
        <figure style="position: relative; margin: 0; border: 1px solid #aaa; border-radius: 4px; overflow: hidden;">
          <img
            :src="photo.thumb_url"
            style="cursor: pointer; width: 100%; height: 100%; ; object-fit: cover; aspect-ratio: 4 / 3;"
            @click="openPhotoModal(index)"
          >
          <figcaption @click="openPhotoModal(index)">
            <i v-if="!photo.on_report" class="fas fa-ban" /> <i
              :class="photoIconClasses(photo.photoable_type)"
            />&nbsp;
            {{ formatDate(photo.taken_at, 'DD.MM.YYYY') }}<br>{{ caption(photo) }}
          </figcaption>
        </figure>
      </div>
    </div>

    <EmptyState
      v-if="emptyStateHint && !localPhotos.length"
      icon="fas fa-camera"
      title="Keine Fotos"
      :hint="emptyStateHint"
    />
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

import Flash from 'flash/index';

import { useCurrentUserStore } from 'stores/current_user';
import confirmViaModal from 'helpers/confirm_modal';
import { ref, computed } from 'vue';
import {
  VueFinalModal, useModal, useModalSlot,
} from 'vue-final-modal';

import EmptyState from 'components/empty_state.vue';

import FeatureFlipper from 'mixins/feature_flipper';
import { DateFormatter } from 'mixins/formatters';

import '@uppy/core/dist/style.min.css';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@uppy/dashboard/dist/style.min.css';

// import Uppy from '@uppy/core';
import { Dashboard } from '@uppy/vue';
// import AwsS3 from '@uppy/aws-s3';
// import German from '@uppy/locales/lib/de_DE';
import { useUppy, onUppyUploaded } from 'helpers/uppy';

import PhotoModal from './photo_modal.vue';

export default {
  name: 'PhotoGrid',
  components: {
    Dashboard,
    EmptyState,
  },
  mixins: [FeatureFlipper, DateFormatter],
  props: {
    title: {
      type: String,
      default: null,
    },
    photos: {
      type: Array,
      default: null,
    },
    photoableId: {
      type: String,
      default: null,
    },
    photoableType: {
      type: String,
      default: null,
    },
    colCount: {
      type: Number,
      default: 3,
    },
    addPhotosButton: {
      type: Boolean,
      default: true,
    },
    emptyStateHint: {
      type: String,
      default: null,
    },
    installationId: {
      type: String,
      default: null,
    },
  },
  emits: ['changed'],
  setup(props, { emit }) {
    const localPhotos = ref([]);
    const showAddPhotos = ref(false);
    const filterJob = ref(true);
    const filterThing = ref(true);
    const filterIncident = ref(true);

    const loadUrl = () => {
      let url = `/photos.json?${props.photoableType.toLowerCase()}_id=${props.photoableId}&filter[]=Default`;

      if (filterJob.value === true) {
        url += '&filter[]=Job';
      }

      if (filterIncident.value === true) {
        url += '&filter[]=Incident';
      }

      if (filterThing.value === true) {
        url += '&filter[]=Thing';
      }

      return url;
    };

    const loadPhotos = () => {
      axios.get(loadUrl()).then((response) => {
        localPhotos.value = response.data.photos;
      }).catch(() => {
        Flash.error('Fehler beim Laden der Fotos (shrine)');
      });
    };

    const removeFromLocalList = (photo) => {
      const id = photo.id;
      const index = _.findIndex(localPhotos.value, { id });
      localPhotos.value.splice(index, 1);
      emit('changed');
    };

    const changed = () => {
      loadPhotos();
      emit('changed');
    };

    const deleteViaApi = (photo) => {
      axios.delete(`/photos/${photo.id}.json`).then(() => {
        removeFromLocalList(photo);
        Flash.info('Foto gelöscht');
      }).catch(() => {
        Flash.error('Foto konnte nicht gelöscht werden');
      });
    };

    const uppy = useUppy({
      allowedFileTypes: ['.png', '.jpg', '.jpeg'],
      maxFileSizeMB: 5,
      maxNumberOfFiles: 1,
    });

    const uploadCallback = (uploadedFileData) => {
      axios.post('/photos.json', {
        photo: {
          photoable_id: props.photoableId,
          photoable_type: props.photoableType,
          image: uploadedFileData,
        },
      }).then(() => {
        uppy.cancelAll();
        showAddPhotos.value = false;
        changed();
      }).catch(() => {
        Flash.error('Fehler beim Hochladen');
      });
    };

    onUppyUploaded(uppy, uploadCallback);

    const confirmDeletePhoto = (photo) => {
      confirmViaModal({
        title: 'Foto löschen?',
        message: 'Diese Aktion kann nicht rückgängig gemacht werden.',
      }).then(() => {
        deleteViaApi(photo);
      });
    };

    const openPhotoModal = (index) => {
      const modalInstance = useModal({
        component: VueFinalModal,
        slots: {
          default: useModalSlot({
            component: PhotoModal,
            attrs: {
              index,
              photos: localPhotos.value,
              installationId: props.installationId,
              onClose() {
                modalInstance.close();
              },
              onChanged() {
                changed();
              },
              onDelete(photo) {
                modalInstance.close();
                confirmDeletePhoto(photo);
              },
            },
          }),
        },
      });
      modalInstance.open();
    };

    return {
      showAddPhotos,
      filterJob,
      filterThing,
      filterIncident,
      localPhotos,
      openPhotoModal,
      loadPhotos,
      changed,
      uppy,
      currentUser: useCurrentUserStore().currentUser,
    };
  },
  computed: {
    gridClasses() {
      if (this.colCount === 3) {
        return 'photo-grid-3-col';
      }
      return 'photo-grid-4-col';
    },
    classFilterJob() {
      if (this.filterJob) { return 'on'; }
      return 'off';
    },
    classFilterThing() {
      if (this.filterThing) { return 'on'; }
      return 'off';
    },
    classFilterIncident() {
      if (this.filterIncident) { return 'on'; }
      return 'off';
    },
  },
  mounted() {
    if (this.photos == null) {
      // --- no initial photos
      this.loadPhotos();
    } else {
      // --- initial photos from parent
      this.localPhotos = this.photos;
    }
  },
  methods: {
    photoIconClasses(photoableType) {
      if (photoableType === 'Incident') {
        return 'fas fa-exclamation-triangle';
      }
      if (photoableType === 'Thing') {
        return 'fas fa-cube';
      }
      return 'fas fa-calendar-check';
    },
    caption(photo) {
      let caption = '(Ohne Bildunterschrift)';
      if (photo.caption && photo.caption.length) {
        caption = photo.caption;
      }
      return caption;
    },
  },
};
</script>

<style>
figcaption {
  position: absolute;
  width: 100%;
  background: #ffffffdd;
  color: #000;
  left: 0;
  bottom: 0;
  padding: 5px 8px;
  font-size: 13px;
  z-index: 2;
  height: 28px;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

figure:hover figcaption {
  height: 82px;
}
</style>
